<div id="box-wrapper">
    <div class="box">
        <div class="logo"></div>
        <div class="title">We are in maintenance</div>
        <div class="subtitle">We'll be back in a jiffy!</div>

        <button (click)="goBack()" mat-raised-button class="back-button">
            <mat-icon>arrow_back_ios</mat-icon>
            Go Back
        </button>
    </div>
</div>
