<div id="main-video-wrapper">
    <div id="logo"></div>
    <div id="main-area-wrapper" class="show-after-delay">
        <div class="title signika">SHUFFLL</div>
        <div class="sub-title signika">Bringing video back to Live<span class="live blink_me">&#9679;</span></div>

        <div id="call-to-action" class="ease-in-after-delay">Sign up to our beta, get your 15 minutes of fame!</div>

        <!--    <form [formGroup]="userEmails" id="input-wrapper" class="ease-in-after-delay">-->
        <!--      <input type="email" id="email-input" placeholder="Your email" [ngModel]="emailAdress"-->
        <!--             formControlName="primaryEmail">-->
        <!--      <button class="straight-corners" mat-raised-button color="warn" (click)="sendEmail()"-->
        <!--              [ngClass]="{'email-send-disabled':!this.userEmails.get('primaryEmail').valid}">YUP!-->
        <!--      </button>-->
        <!--    </form>-->
    </div>
    <video [vgMedia]="media" #media id="main-video" preload="auto" autoplay style="z-index: 2" loop>
        <source src="assets/videos/main-page-video.mp4" type="video/mp4" />
    </video>
</div>
