import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { AuthenticationService } from '../../guards/authentication.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';

export interface ITokenResponse {
    token: string;
}

@Injectable({
    providedIn: 'root',
})
export class ParticipationAuthApiService {
    participationAPIPath = '/auth/participation';
    path;
    requesting = false;

    public participationToken = new BehaviorSubject<ITokenResponse>(null);

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
        private auth: AuthenticationService
    ) {
        this.path = config.apiIP + this.participationAPIPath;
    }

    async generate() {
        if (!this.requesting) {
            console.log('Generating participation token');
            let token = await this.http.get<ITokenResponse>(`${this.path}/generate`).toPromise();
            console.log('Generated token', token);

            if (token) {
                this.participationToken.next(token);
            }
        }
    }

    validate(token: string): Observable<{ success: boolean; message: string }> {
        console.log('Testing participation token', token);

        return this.http.get<any>(`${this.path}/validate`, {
            params: {
                token: token,
            },
        });
    }
}
