import { Component, OnInit } from '@angular/core';
import { PlansService } from 'src/app/services/plans.service';

@Component({
    selector: 'app-plans-page',
    templateUrl: './plans-page.component.html',
    styleUrls: ['./plans-page.component.scss'],
})
export class PlansPageComponent implements OnInit {
    constructor(public plansService: PlansService) {}

    ngOnInit(): void {
        this.plansService.getPlans();
    }
}
