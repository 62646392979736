<div class="container">
    <div class="lock">
        <ng-lottie
            class="lock-animation"
            containerClass="lock-animation-wrap"
            [options]="{ path: this.baseCdnUrl + this.lockLottiePath, autoplay: true, loop: 2 }"></ng-lottie>
    </div>
    <h3>Permission Denied</h3>
    <h5>
        It seems we don't have access to your camera and mic,
        <br />please click the camera icon in the address bar of your browser<br />
        to allow permissions.
    </h5>
    <img
        class="permissions-gif"
        fxShow
        fxHide.lt-sm
        *ngIf="this.profileServce.userBrowser !== 'Safari'"
        [src]="this.baseCdnUrl + devicePermissionsGif" />

    <button mat-raised-button color="primary" (click)="triggerMediaPermissions()" class="trigger-permissions">
        I've allowed permissions
    </button>
</div>
