import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    NgZone,
    OnChanges,
    PLATFORM_ID,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AnimationLoader, AnimationOptions, BaseDirective } from 'ngx-lottie';
import { ArtDirectorService } from '../../services/art-director.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IDynamicLottieData } from 'src/app/models/lottie/lottie-defines';

@Component({
    selector: 'dynamic-lottie',
    template: `
        <div
            #container
            [style.width]="width || '100%'"
            [style.height]="height || '100%'"
            [ngStyle]="styles"
            [ngClass]="containerClass"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./dynamic-lottie.component.scss'],
})
export class DynamicLottieComponent extends BaseDirective implements OnChanges {
    @Input() width: string | null = null;
    @Input() height: string | null = null;
    @Input() styles: Partial<CSSStyleDeclaration> | null;
    @Input() containerClass: string | null = null;

    @ViewChild('container', { static: true }) container: ElementRef<HTMLElement> = null!;

    @Input('config') config: IDynamicLottieData;
    @Input('lottieOptions') lottieOptions: AnimationOptions;
    @Input('lottieIconPath') lottieIconPath: string;

    /**
     * Looping the loop marker if existed, if not freezing at the end of the animation.
     */

    constructor(
        ngZone: NgZone,
        @Inject(PLATFORM_ID) platformId: string,
        animationLoader: AnimationLoader,
        private artDirectorService: ArtDirectorService,
        private configurationService: ConfigurationService
    ) {
        super(ngZone, platformId, animationLoader);
        //If there is a case of lottie icon for card for example we use it and dont need all the configs
    }
    private async loadAnimationAsync(changes: SimpleChanges) {
        try {
            const dynamicAsset = await this.artDirectorService.getBaseJsonAsync(
                this.config.layout,
                this.config.basePath
            );
            const lottieOriginalString = dynamicAsset.content;

            const original = JSON.parse(lottieOriginalString);
            let replacement;
            try {
                replacement = JSON.parse(
                    this.artDirectorService.replaceDynamicValues(
                        this.config.dynamicLottieChanges,
                        dynamicAsset.dynamics,
                        lottieOriginalString
                    )
                );
            } catch (e) {
                console.warn('Could not replace lottie values', e);
            }
            try {
                await this.loadFontsAsync(replacement);
            } catch (error) {
                console.error(`Could not load fonts: error: ${error}`);
            }
            const options: AnimationOptions = {
                ...(this.lottieOptions ?? {}),
                animationData: replacement ?? original,
                assetsPath: `${this.configurationService.baseCdnUrl}`,
            };

            // Use arrow function to retain `this` context
            changes['options'] = new SimpleChange(undefined, options, true);
            super.loadAnimation(changes, this.container.nativeElement);
        } catch (loadError) {
            console.error(`An error occurred while trying to load lottie animation. Error:`, loadError);
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['config']) {
            if (!this.config) {
                return;
            }
            this.loadAnimationAsync(changes);
        }
    }

    private async loadFontsAsync(lottieJson: any): Promise<void> {
        const fontPromises = (lottieJson.fonts?.list || []).map((font: any) => {
            // Check if the font is already loaded
            if (document.fonts.check(`1em ${font.fFamily}`)) {
                return Promise.resolve();
            }
            const fontFace = new FontFace(font.fFamily, `url(${font.fPath})`);
            return fontFace.load().then((loadedFont) => {
                (document.fonts as any).add(loadedFont);
            });
        });
        await Promise.all(fontPromises);
    }
}
