import { Component, OnInit } from '@angular/core';
import { CreativeAuthApiService } from '../../../../services/api/auth/creative-auth-api.service';
import { GoalType } from '../../../../models/defines';

@Component({
    selector: 'app-discover',
    templateUrl: './discover.component.html',
    styleUrls: ['./discover.component.scss'],
})
export class DiscoverComponent implements OnInit {
    suggetions = [];

    constructor(private creativeApi: CreativeAuthApiService) {
        this.getSuggestions();
    }

    async getSuggestions() {
        let suggestions = this.creativeApi
            .getTopicsSubject('Get video ideas for KPMG', GoalType.Advocacy, '634715aca058edacc99e505e')
            .subscribe((suggestions) => {
                console.log(suggestions);

                this.suggetions = suggestions.result.topics.map((x) => {
                    return x.replace('"', '');
                });
            });
    }

    competitorsVideos = [
        {
            title: 'Thriving a net carbon neutral future',
            date: 'Deloitte',
            thumbnail: 'assets/images/demo/discovery/deloitte-demo1.jpg',
        },
        {
            title: 'Financial reporting in hyperinflationary...',
            date: 'Ernst & young',
            thumbnail: 'assets/images/demo/discovery/ey-demo1.jpg',
        },
        {
            title: 'Describing your view on digital transformation',
            date: 'Deloitte',
            thumbnail: 'assets/images/demo/discovery/deloitte-demo2.jpg',
        },
    ];
    public previousVideos = [
        {
            title: 'Fund Distribution value chain',
            date: 'Dec 20, 2022',
            thumbnail: 'assets/images/demo/discovery/kpmg-demo3.jpg',
        },
        {
            title: 'KPMG Lighthouse will transform your...\n',
            date: 'Dec 17, 2022',
            thumbnail: 'assets/images/demo/discovery/kpmg-demo2.jpg',
        },
        {
            title: 'Impacts of the local finance industy',
            date: 'Nov 24, 2022',
            thumbnail: 'assets/images/demo/discovery/kpmg-demo1.jpg',
        },
    ];

    ngOnInit(): void {}
}
