import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { IBranding } from 'src/app/models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BrandingAuthApiService {
    path = `${this.config.apiIP}/auth/branding`;

    private comapnyBranding = new BehaviorSubject<IBranding>(null);
    public comapnyBranding$ = this.comapnyBranding.asObservable();

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    get() {
        return this.http.get(`${this.path}`);
    }

    updateProperty(propertyName, propertyValue) {
        return this.http.put(`${this.path}/property`, {
            propertyName: propertyName,
            proertyValue: propertyValue,
        });
    }

    uploadLogo = (formData, extraParams = {}) => {
        return this.http.post(`${this.path}/logo`, formData, {
            reportProgress: true,
            observe: 'events',
            params: extraParams,
        });
    };
    uploadLogoW = (formData, extraParams = {}) => {
        return this.http.post(`${this.path}/logow`, formData, {
            reportProgress: true,
            observe: 'events',
            params: extraParams,
        });
    };
    import = (initial = true, domain = null) => {
        return this.http.put<{ branding: IBranding; isPlaceholderLogo: boolean }>(`${this.path}/import`, {
            initial: initial,
            domain: domain,
        });
    };

    changeCompanyDemoBranding(branding: IBranding) {
        this.comapnyBranding.next(branding);
    }
}
