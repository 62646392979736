import { Component, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { Output, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-play-button',
    templateUrl: './play-button.component.html',
    styleUrls: ['./play-button.component.scss'],
})
export class PlayButtonComponent<T> implements OnChanges {
    @Input()
    isPlaying: boolean = false;

    @Input()
    objectToPlay: T;

    @Output()
    playSceneEvent: EventEmitter<T> = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {
        console.log('butonn activeiveive');
    }

    playOrPauseScene() {
        this.playSceneEvent.emit(this.objectToPlay);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cdr.detectChanges();
    }
}
