<!--<div class="icon-image-wrapper">-->
<!--  <img src="/assets/images/emojies/woohoo.gif">-->
<!--</div>-->
<div id="content">
    <h1 mat-dialog-title>Replace positions with {{ data?.currentlyOccupiedBy?.peer?.displayName }}?</h1>
    <!--  <h4 mat-dialog-content>-->
    <!--    Spot #{{data.spotIndex + 1}} is occupied by !-->
    <!--  </h4>-->

    <!--  <button mat-button (click)="onNoClick()">Schedule My next Live</button>-->
    <div class="aprove-button-wrapper">
        <button mat-button [mat-dialog-close]="false" style="margin-right: 10px">Cancel</button>
        <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]="true">Replace</button>
    </div>
</div>
