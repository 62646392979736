import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IEditPlugins } from 'src/app/models/project/edit/subtitles/styles/edit-styles.model';
import { ISubtitleStyle } from 'subtitles-helper/dist/types';
import { IExportEditJob } from '../../../../../models/project/edit/edit-model';
import { IEditJob } from '../../../../../models/job/edit-job-schema';

@Injectable({
    providedIn: 'root',
})
export class EditRoomStateService {
    markerPosition$ = new BehaviorSubject<number>(0);
    currentTime$ = new BehaviorSubject<number>(0);
    private _currentEditJob$ = new BehaviorSubject<IExportEditJob>(null);
    public currentEditJob$ = this._currentEditJob$.asObservable();

    public subtitleChanged$ = new Subject<void>();
    // runMarker$ = new BehaviorSubject<boolean>(false);

    constructor() {}

    setCurrentEditJob(editJob: IExportEditJob) {
        this._currentEditJob$.next(editJob);
    }
    /**
     * Triggers subtitle changed to let anyone who uses it to re-render or do anything else
     */
    changeSubtitle(): void {
        this.subtitleChanged$.next();
    }
}
