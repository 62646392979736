import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-take',
    templateUrl: './take.component.html',
    styleUrls: ['./take.component.scss'],
})
export class TakeComponent implements OnInit {
    projectId: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.projectId = this.route.snapshot.params['takeof'];
    }

    public back() {
        this.router.navigate(['dashboard', { outlets: { panel: ['project', this.projectId] } }]);
    }
}
