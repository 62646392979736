import { Injectable } from '@angular/core';
import { ILottieLayerInDTO, ILottieLayer, ILottieOutDTO } from 'src/app/models/lottie/lottie-defines';
import { IConvertor } from 'src/app/models/project-model';

@Injectable({
    providedIn: 'root',
})
export class LottieConvertorService implements IConvertor<ILottieLayerInDTO, ILottieLayer, ILottieOutDTO> {
    constructor() {}

    inToLocal(inLottieLayer: ILottieLayerInDTO) {
        if (!inLottieLayer) {
            console.error(`Cannot convert lottie layer to out dto lottie layer because it's null.`);
            return;
        }

        const lottieLayer: ILottieLayer = {
            lottieId: inLottieLayer.lottieId,
            lottieJsonPath: inLottieLayer.lottieJsonPath,
            isRendered: inLottieLayer.isRendered,
            trimStart: inLottieLayer.trimStart,
            trimEnd: inLottieLayer.trimEnd,
            lottieSegments: inLottieLayer.lottieSegments,
            startTime: inLottieLayer.startTime,
            endTime: inLottieLayer.endTime,
            lottieThumbnailsAndFrames: inLottieLayer.lottieThumbnailsAndFrames,
        };
        return lottieLayer;
    }
    localToOut(lottieLayer: ILottieLayer) {
        if (!lottieLayer) {
            console.error(`Cannot convert lottie layer to out dto lottie layer because it's null.`);
            return;
        }
        const outDTOVideoLayer: ILottieOutDTO = {
            lottieId: lottieLayer.lottieId,
        };
        return outDTOVideoLayer;
    }
}
