import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../configuration.service';
import { IFeedbackConfigs, ILaunchFormConfig, IOnBoardingConfig, IVideoTagsConfig } from '../../../models/defines';
import { IFontsConfigs } from '../../../models/configs/fonts.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationAuthApiService {
    path = '/auth/config';

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    getOnboardingConfig() {
        return this.http.get<IOnBoardingConfig>(`${this.config.apiIP + this.path}/onboarding`).toPromise();
    }

    getVideoTagsConfig() {
        return this.http.get<IVideoTagsConfig>(`${this.config.apiIP + this.path}/video_tags`).toPromise();
    }

    getFeedbackConfig() {
        return this.http.get<IFeedbackConfigs>(`${this.config.apiIP + this.path}/feedback_forms`).toPromise();
    }

    getFonts$() {
        return this.http.get<IFontsConfigs>(`${this.config.apiIP + this.path}/fonts`);
    }
}
