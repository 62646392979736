<div
    *ngIf="authentication.isAuthenticated$ | async"
    [ngStyle]="{
        'background-image': 'url(\'' + pathHelperService.getUserProfileImage(authentication.getUser()) + '\')',
    }"
    style="
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    "></div>
