import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    getRecordingSceneAddTakePOST,
    getTakeVideoLayerToDashUrlPOST,
    getUpdateTakePropertiesPUT,
} from 'src/app/constants/private/urls/projects/take.urls';

import { IllegalArgumentException, MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { ITakeInDTO, ITakeUpdate, TakeUpdateableProperties } from 'src/app/models/project/take/take-model';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import {
    IAddTakeRequestBody,
    ITakeVideoLayersToDashBody,
} from 'src/app/services/http-models/project/take/take-http-request-model';
import { ITake } from '../../../../models/project/take/take-model';

@Injectable({
    providedIn: 'root',
})
export class TakeApiService {
    constructor(private http: HttpClient) {}

    public addTakeToScene$(
        projectId: string,
        sceneId: string,
        dynamicLottieChanges: IDynamicLottieChange[],
        switchToSelectedTake: boolean
    ) {
        if (!projectId || !sceneId || !dynamicLottieChanges) {
            console.error(`Could not push  take to scene because one of the arguments is null`);
            throw new MissingArgumentsError(`One of the arguments is null`);
        }

        const postAddTakeToSceneUrl = getRecordingSceneAddTakePOST(projectId, sceneId);
        const body: IAddTakeRequestBody = {
            dynamicLottieChanges: dynamicLottieChanges,
            switchToSelectedTake: switchToSelectedTake,
        };
        return this.http.post<ITakeInDTO>(postAddTakeToSceneUrl, body);
    }

    public updateTake$<K extends keyof TakeUpdateableProperties>(
        projectId: string,
        sceneId: string,
        takeId: string,
        updates: ITakeUpdate<K>[]
    ) {
        if (!projectId || !sceneId || !takeId || !updates) {
            console.error(`Could not update specific scene because one of the arguments is null`);
            throw new Error(`One of the arguments is null`);
        }

        if (updates.length === 0) {
            /// No properties to update, why did it get here ;)
            return;
        }

        const postAddTakeToSceneUrl = getUpdateTakePropertiesPUT(projectId, sceneId, takeId);

        return this.http.put<ITakeInDTO>(postAddTakeToSceneUrl, {
            propertiesToUpdate: updates,
        });
    }

    public convertVideoLayersToDash$(
        projectId: string,
        sceneId: string,
        takeId: string,
        videoLayersIds: string | string[]
    ) {
        if (!projectId || !sceneId || !takeId || !videoLayersIds) {
            throw new MissingArgumentsError(
                `Could not convert video layers to dash because one of the arguments is null`
            );
        }
        if (!Array.isArray(videoLayersIds)) {
            videoLayersIds = [videoLayersIds];
        } else if (videoLayersIds.length === 0) {
            throw new IllegalArgumentException(`No video layers Ids to convert to dash`);
        }

        const takeVideoLayerToDashUrlPost = getTakeVideoLayerToDashUrlPOST(projectId, sceneId, takeId);
        const body: ITakeVideoLayersToDashBody = {
            videoLayerIds: videoLayersIds,
        };
        return this.http.post<boolean>(takeVideoLayerToDashUrlPost, body);
    }

    // /**
    //  *
    //  * @param recordingId
    //  * @param sceneId
    //  * @param takeId
    //  * @param take - Send only the part of the values that we want to update out of take object!
    //  */
    // updateSpecificScene$(
    //   recordingId: string,
    //   sceneId: string,
    //   takeId: string,
    //   take: ITakeOutDTO
    // ) {
    //   if (!recordingId || !sceneId || !takeId || !take) {
    //     console.error(
    //       `Could not update specific scene because one of the arguments is null`
    //     );
    //     throw new Error(`One of the arguments is null`);
    //   }
    //   const updateTakeUrl = getRecordingSceneSpecificTakeUrlPUT(
    //     recordingId,
    //     sceneId,
    //     takeId
    //   );

    //   return this.http.put<boolean>(updateTakeUrl, take);
    // }
}

function validatingVideoLayersExisted(take: ITake, videoLayersIds: string | string[]) {
    if (!take || !videoLayersIds) {
        throw new MissingArgumentsError(
            'Could not validate video layers exist in take because one of the arguments is null'
        );
    }

    if (!Array.isArray(videoLayersIds)) {
        videoLayersIds = [videoLayersIds];
    }

    const missingLayers = videoLayersIds.filter(
        (layerId) => !take.videoLayers.some((videoLayer) => videoLayer.id === layerId)
    );

    if (missingLayers.length > 0) {
        throw new MissingArgumentsError(
            `The following video layer IDs do not exist in the take: ${missingLayers.join(', ')}`
        );
    }

    return true;
}
