import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AnimationItem } from 'lottie-web';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { LoadingWithSwagComponent } from '../../../../../../components/minor/loading-with-swag/loading-with-swag.component';
import { CreativeAuthApiService } from '../../../../../../services/api/auth/creative-auth-api.service';
import { fadeInOnEnterAnimation } from 'angular-animations';
import {
    GoalType,
    ITagAndSuggestions,
    IVideoTag,
    ProjectRecordingTypeEnum,
    ProjectStatusEnum,
} from 'src/app/models/defines';
import { catchError, finalize, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject, Subscription, take, takeUntil, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WalkthroughPanelService } from 'src/app/services/walkthrough-panel.service';
import { TaskTypeEnum } from 'src/app/models/walkthrough';
import { AnalyticsNotifierService } from '../../../../../../services/utils/analytics-notifier.service';
import {
    INewProjectRequest,
    ProjectAuthApiService,
} from '../../../../../../services/api/auth/project-auth-api.service';
import { IBasicProjectData, IProjectInDTO } from 'src/app/models/project-model';
import { ProfileService } from 'src/app/services/show/profile.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IDesignGroup } from 'src/app/models/design.model';
import { ProjectGeneralRouterService } from '../../../../../../services/project/routes/project-general-router.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { WizardManagerService } from '../../../../../../services/state-management/wizard/wizard-manager.service';
import { FeatureService } from '../../../../../../services/feature-service.service';
import { FeatureGroupEnum } from '../../../../../../models/user';

interface Goal {
    type: GoalType;
    title: string;
    description: string;
    icon: string;
    animation?: AnimationItem;
}

@Component({
    selector: 'project-wizard',
    templateUrl: './project-wizard.component.html',
    styleUrls: ['./project-wizard.component.scss'],
    animations: [fadeInOnEnterAnimation({ duration: 500, delay: 100 })],
})
export class ProjectWizardComponent implements OnInit, OnDestroy {
    @ViewChild('loadingWithSwagFinal', { static: true })
    suggestions: string[] = null;
    isLoadingPromptSuggestions = true;
    onDestroy$ = new Subject<boolean>();
    loadingWithSwagFinal: LoadingWithSwagComponent;
    taskType: TaskTypeEnum = TaskTypeEnum.PRODUCTION;
    project: IProjectInDTO;
    baseCdnUrl: string;

    finalLoadingSteps = ['Generating AI Script 🤖', 'Customizing Your Storyline 🚀', 'Taking You To The Project 🎞️'];

    ///'Animating Scenes ðŸŽ¬',

    selectedDesignGroup: IDesignGroup;
    selectedRecordingType: ProjectRecordingTypeEnum;
    allPromptSuggestions: ITagAndSuggestions[];

    @ViewChild('finalLoading') finalLoading: MatStep;

    @ViewChild('stepper') stepper: MatStepper;
    userPrompt: string;

    subjectFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
    });

    isLinear = false;
    showSuggestions = false;

    type = 'hi';
    videoTags: IVideoTag[] = [];

    selectedVideoTag: IVideoTag = null;
    suggestedDesigns: IDesignGroup[] = [];
    privateDesigns: IDesignGroup[] = [];
    isLoadingDesigns = false;
    toDisplayVideoTypeStep: boolean;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private creativeApi: CreativeAuthApiService,
        private _snackBar: MatSnackBar,
        private walkthroughPanelService: WalkthroughPanelService,
        private gtmService: AnalyticsNotifierService,
        public projectAuthApiService: ProjectAuthApiService,
        public profileService: ProfileService,
        private config: ConfigurationService,
        private projectGeneralRouting: ProjectGeneralRouterService,
        private wizardManager: WizardManagerService,
        private featureService: FeatureService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    changeVideoTag = (selectedTag: IVideoTag) => {
        this.selectedVideoTag = selectedTag;
        this.suggestions = this.allPromptSuggestions.find((x) => {
            return x.tag.id === selectedTag.id;
        })?.suggestions;
        this.showSuggestions = true;
    };

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    recordingTypeSelected(recordingType: ProjectRecordingTypeEnum) {
        if (!recordingType) return;

        console.log(recordingType);
        this.selectedRecordingType = recordingType;
        this.nextStep();
    }

    formatSelected(format: IDesignGroup) {
        console.log(format);
        this.selectedDesignGroup = format;
        this.nextStep();
    }

    subscribeToWizardManager() {
        this.wizardManager.suggestedDesigns$.pipe(takeUntil(this.onDestroy$)).subscribe((suggestedDesigns) => {
            if (!suggestedDesigns) return;
            this.suggestedDesigns = suggestedDesigns;
        });
        this.wizardManager.privateDesigns$.pipe(takeUntil(this.onDestroy$)).subscribe((privateDesigns) => {
            if (!privateDesigns) return;
            this.privateDesigns = privateDesigns;
        });
        this.wizardManager.isLoadingDesigns$.pipe(takeUntil(this.onDestroy$)).subscribe((isLoadingDesigns) => {
            this.isLoadingDesigns = isLoadingDesigns;
        });
        this.wizardManager.allPromptSuggestions$.pipe(takeUntil(this.onDestroy$)).subscribe((promptSuggestions) => {
            if (!promptSuggestions) return;
            this.allPromptSuggestions = promptSuggestions;
        });
        this.wizardManager.videoTags$.pipe(takeUntil(this.onDestroy$)).subscribe((videoTags) => {
            if (!videoTags) return;
            this.videoTags = videoTags;
        });
        this.wizardManager.isLoadingPromptSuggestions$.pipe(takeUntil(this.onDestroy$)).subscribe((isLoadingPrompt) => {
            this.isLoadingPromptSuggestions = isLoadingPrompt;
        });
    }

    generateNewSuggestionDesigns(event: boolean) {
        if (event === true) {
            this.wizardManager.generateDesignsSuggestions();
        }
    }

    createLoadingPromise(loader, delayBetweenSteps) {
        return new Promise((resolve, reject) => {
            let interval = setInterval(() => {
                if (loader) {
                    if (!loader.jumpStep()) {
                        clearInterval(interval);
                        resolve(true);
                    }
                }
            }, delayBetweenSteps);
        });
    }

    async finalizeFormatAndCopy() {
        const delayForPromise = 2800;

        const loadingPromise = this.createLoadingPromise(this.loadingWithSwagFinal, delayForPromise);

        const designGroupId = this.selectedDesignGroup?._id;
        let projectCreationRequest: INewProjectRequest = {
            prompt: this.userPrompt,
            videoTagId: this.selectedVideoTag.id,
            designGroupId: designGroupId,
            recordingType: this.selectedRecordingType ? this.selectedRecordingType : null,
        };

        this.projectAuthApiService
            .createNewProject$(projectCreationRequest)
            .pipe(
                retry({
                    count: this.creativeApi.MAX_RETRY_ATTEMPTS,
                    delay: (error, retryCount) => this.creativeApi.shouldRetry(error, retryCount),
                }),
                takeUntil(this.onDestroy$),
                catchError((error) => {
                    // Handle errors or propagate them further
                    console.error('Error:', error);
                    return throwError(() => error);
                }),
                finalize(async () => {
                    let data = await Promise.all([loadingPromise]);
                })
            )
            .subscribe({
                next: async (newProject) => {
                    console.log('Created Project', newProject.id);
                    if (!newProject) {
                        return;
                    }
                    this.jumpToProject(newProject);
                },
                error: (error) => {
                    // This means that the default values will be applied to the scenes
                    console.error(`Could not generate topics, error: ${error}`);
                },
            });
    }

    jumpToProject(project: IBasicProjectData) {
        if (!project) {
            return;
        }
        this.projectGeneralRouting.goToAsync(ProjectStatusEnum.PLANNING, project);
    }

    ngOnInit(): void {
        // Check if feature flag to display the option to choose video type is turn on
        this.featureService
            .hasFeatureAccess('wizard_videotype_selection')
            .pipe(take(2))
            .subscribe({
                next: (hasAccess) => {
                    this.toDisplayVideoTypeStep = hasAccess;
                },
                error: (error) => {
                    console.warn(`NO FEATURE FLAG DATA`);
                },
            });
        this.subscribeToWizardManager();
    }

    stepChanged(event: StepperSelectionEvent) {
        if (event?.selectedStep === this.finalLoading) {
            console.log('final step');
            // this.generateIntroOneLiner();
            this.finalizeFormatAndCopy();
            this.gtmService.notifyEvent('Finished Wizard', {
                videoTag: this.selectedVideoTag?.title ?? '',
                prompt: this.userPrompt ?? '',
                videoType: this.selectedRecordingType ?? '',
                designGgroup: this.selectedDesignGroup?.name ?? '',
                designGroupId: this.selectedDesignGroup?._id,
            });
        }
    }

    backStep() {
        if (this.stepper.selectedIndex > 0) {
            this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
        }
    }

    nextStep(isLastStep?: boolean) {
        if (isLastStep && !this.walkthroughPanelService.checkIfTaskCompleted(this.taskType)) {
            this.walkthroughPanelService.specialTaskCompleted$.next(this.taskType);
        }
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
    }
}
