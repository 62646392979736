import { Component, Input, NgZone } from '@angular/core';

//
@Component({
    selector: 'app-actions-panel',
    templateUrl: './actions-panel.component.html',
    styleUrls: ['./actions-panel.component.scss'],
})
export class ActionsPanelComponent {
    //   @Input() production: IProject;
    //
    //   @Input() selectedScene: IScene;
    //   lottieAnimationItems = new Map<string, AnimationItem>();
    //   specificSceneComposeConfigs$ = new BehaviorSubject<IComposeAndPlayOrNot>(
    //     null
    //   );
    //   composedConfigsTracker = new Map<string, IComposedConfigsTracker>();
    //   playOrPause$ = new BehaviorSubject<IFakeDetection>({
    //     randomNumber: Math.random(),
    //     isPlaying: true,
    //   });
    //   newFrameOnRunningScene$ = new BehaviorSubject<number>(3);
    //   endTime$ = new BehaviorSubject<number>(15);
    //   baseWidth = 100;
    //   maxDuration: number;
    //   videoScenes: IEditVideoScene[] = [];
    //   startSlider: number;
    //   endSlider: number;
    //   takes: ITake[] = [];
    //   selectedTakeIndex: number;
    //
    //   constructor(
    //     private ngZone: NgZone,
    //     private editRoomHelper: EditRoomUtilityFunctions,
    //     private stylesService: ScenesStylesService,
    //     private lottiePlayer: LottiePlayerService
    //   ) {}
    //
    //   ngOnInit(): void {
    //     this.selectedTakeIndex = this.takes.length - 1;
    //     this.initSceneComposeConfigs();
    //   }
    //
    //   public initSceneComposeConfigs() {
    //     // this.production.scenes
    //     //   .find((demoScene) => demoScene.id === this.selectedScene.id)
    //     //   .takes.forEach((demoTake) => {
    //     //     const take: ITake = this.convertIDemoTakeToITake(demoTake);
    //     //     this.takes.push(take);
    //     //   });
    //
    //     // this.selectedScene.demoTakes.forEach((demoTake) => {
    //     //   if (demoTake) {
    //     //     const take: ITake = this.convertIDemoTakeToITake(demoTake);
    //     //     this.takes.push(take);
    //     //   }
    //     // });
    //
    //     const lastTake = this.takes[this.takes.length - 1];
    //
    //     if (!lastTake) return;
    //     const scene: IEditVideoScene = {
    //       take: lastTake,
    //       takeId: lastTake.id,
    //       trimEnd: 0,
    //       trimStart: 0,
    //     };
    //     this.videoScenes.push(scene);
    //
    //     const videoFrom: ISeekFromVideo = {
    //       scene: null,
    //       seekTo: 0,
    //     };
    //
    //     this.specificSceneComposeConfigs$.next({
    //       composeConfigs: this.createSceneConfigsAndPlayIfCan([videoFrom]),
    //       playOrNot: true,
    //     });
    //   }
    //
    //   public updateSceneFields() {
    //     const currentEditScene = this.videoScenes[0];
    //
    //     // currentEditScene.durationOnTrack =
    //     //    EditRoomUtilityFunctions.getVideoDurationOnTrack(null);
    //
    //     this.stylesService.adjustSceneWidth(null, this.baseWidth);
    //
    //     currentEditScene.trimEnd = this.endSlider;
    //     currentEditScene.trimStart = this.startSlider;
    //     const configs = this.specificSceneComposeConfigs$.value;
    //     // configs.composeConfigs.endTime =
    //     //   configs.composeConfigs.endTime - currentEditScene.trimEnd;
    //     // configs.composeConfigs.startTime =
    //     //   configs.composeConfigs.startTime + currentEditScene.trimStart;
    //
    //     const videoFrom: ISeekFromVideo = {
    //       scene: null,
    //       seekTo: 0,
    //     };
    //
    //     this.specificSceneComposeConfigs$.next({
    //       composeConfigs: this.createSceneConfigsAndPlayIfCan([videoFrom]),
    //       playOrNot: true,
    //     });
    //   }
    //
    //   public animationCreated(animationItem: AnimationItem, lottieId: string) {
    //     this.lottieAnimationItems.set(lottieId, animationItem);
    //     this.playLottieAnimation(lottieId);
    //   }
    //
    //   public playLottieAnimation(lottieId: string) {
    //     const animationItem = this.lottieAnimationItems.get(lottieId);
    //     this.lottiePlayer.lottieFindMarksAndPlay(animationItem, this.ngZone);
    //   }
    //
    //   public freezeAnimationCreated(
    //     animationItem: AnimationItem,
    //     lottieId: string
    //   ) {
    //     this.lottiePlayer.playAnimation(animationItem);
    //   }
    //
    //   onSelectTake() {
    //     this.composedConfigsTracker.clear();
    //
    //     const take = this.takes[this.selectedTakeIndex];
    //
    //     if (!take) return;
    //
    //     const newScene: IEditVideoScene = {
    //       take: take,
    //       takeId: take.id,
    //       trimEnd: 0,
    //       trimStart: 0,
    //     };
    //     // newScene.durationOnTrack =
    //     //   this.editRoomHelper.getVideoDurationOnTrack(newScene);
    //     // this.stylesService.adjustSceneWidth(newScene, this.baseWidth);
    //     const videoFrom: ISeekFromVideo = {
    //       scene: null,
    //       seekTo: 0,
    //     };
    //     this.specificSceneComposeConfigs$.next({
    //       composeConfigs: this.createSceneConfigsAndPlayIfCan([videoFrom]),
    //       playOrNot: true,
    //     });
    //   }
    //
    //   private convertIDemoTakeToITake(demoTake: ITakeInDTO) {
    //     // if (!demoTake) return;
    //     // const take: ITake = {
    //     //   id: demoTake.id,
    //     //   startTime: demoTake.startTime,
    //     //   endTime: demoTake.endTime,
    //     //   videoLayers: [],
    //     //   lottieLayers: [],
    //     // };
    //     // return take;
    //   }
    //
    //   private createSceneConfigsAndPlayIfCan(videoScenes: ISeekFromVideo[]) {
    //     if (!videoScenes || videoScenes.length === 0) {
    //       return;
    //     }
    //     /// Attention! change this? think about this ! when it will be relevant to multiple videos at different times
    //     const endTime = videoScenes.reduce((maxItem, currentItem) => {
    //       return currentItem.scene.take.endTime > maxItem.scene.take.endTime
    //         ? currentItem
    //         : maxItem;
    //     }, videoScenes[0])?.scene.take.endTime;
    //
    //     let relativeStartTime = videoScenes[0].scene.trimStart * 1000 ?? 0;
    //     const relativeEndTime =
    //       endTime -
    //       (videoScenes[0].scene.trimEnd * 1000 ?? 0) -
    //       videoScenes[0].scene.take.startTime;
    //
    //     if (
    //       !isNaN(videoScenes[0].seekTo) &&
    //       typeof videoScenes[0].seekTo === 'number'
    //     ) {
    //       if (videoScenes[0].seekTo > relativeStartTime) {
    //         relativeStartTime = videoScenes[0].seekTo + relativeStartTime;
    //       }
    //     }
    //
    //     const lottieVideoConfigs =
    //       EditRoomUtilityFunctions.createVideoLottieComposeConfigs(
    //         videoScenes.map((item) => item.scene)
    //       );
    //
    //     /// Setting new configs
    //     // const sceneConfigs: IComposedEditTakeConfigs = {
    //     //   id: uuidv4(),
    //     //   startTime: relativeStartTime / 1000, //It's not actually 0 in the db but we treat it as 0 in the other components
    //     //   endTime: relativeEndTime / 1000,
    //     //   videoLottieConfigs: lottieVideoConfigs,
    //     // };
    //     //
    //     // const videos = videoScenes.map((item) => item.scene);
    //     // const composedConfigsTracker: IComposedConfigsTracker = {
    //     //   composedConfigs: sceneConfigs,
    //     //   videoEditTakes: videos,
    //     //   audioScenes: null,
    //     // };
    //     // this.composedConfigsTracker.set(sceneConfigs.id, composedConfigsTracker);
    //     //
    //     // return sceneConfigs;
    //   }
}
