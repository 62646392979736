<div
    id="chat-wrapper"
    #scrollMe
    class="bottom-up"
    [class.full-height]="options.fullHeight"
    [class.overlayMode]="options.asOverlay">
    <!--  <div id="toolbar">ffgegeeg</div>-->
    <div id="chat">
        <div *ngFor="let message of chatService.messages" [@chat-messages]>
            <div
                class="chat-message"
                [class.left]="message.who !== 'me' || !options.twoSidesMode"
                [class.red]="message.who === 'me'"
                [ngClass.lt-sm]="{ left: options.toTheLeftMobile }"
                [class.slide-in-from-right]="options.animate">
                <div
                    class="chat-pic"
                    [ngStyle]="{
                        'background-image':
                            'url(' + message.peer.picture + '), url(\'assets/images/user-placeholder.png\')',
                    }">
                    <div class="platform-icon" *ngIf="message.platform?.toLowerCase() === 'youtube'"></div>
                </div>
                <div class="chat-bubble-wrapper">
                    <div class="chat-bubble">
                        <div class="chat-text">
                            <!-- <div class="private-label" *ngIf="message.pmToUser">
                <mat-icon>contact_mail</mat-icon>
                private
                {{message.who === 'me' && privateMessageData ? ' to ' + privateMessageData.userId : ''}}
              </div> -->
                            {{ message.message }}
                        </div>
                        <div class="chat-time">
                            <mat-icon class="clock">watch_later_outline</mat-icon>
                            <span class="align-middle">{{ message.recvTime | date: 'shortTime' }}</span>
                        </div>
                    </div>
                    <div class="user-name">{{ message.peer.displayName }}</div>
                </div>
                <div class="chat-more" [hidden]="message.who === 'me'">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Message menu"
                        class="more-menu-button">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="mode === chatModes.host">
                            <mat-icon class="material-icons-outlined">publish</mat-icon>
                            <span>Publish</span>
                        </button>
                        <button mat-menu-item (click)="preparePrivateMessage(message); focusOnInput()">
                            <mat-icon class="material-icons-outlined">contact_mail</mat-icon>
                            <span>Private message</span>
                        </button>
                        <button mat-menu-item *ngIf="mode === chatModes.host">
                            <mat-icon class="material-icons-outlined">block</mat-icon>
                            <span>Block</span>
                        </button>
                        <button mat-menu-item>
                            <mat-icon class="material-icons-outlined">report</mat-icon>
                            <span>Report</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>

<app-chat-input
    [hidden]="options && !options.showChatInput"
    (sendMessage)="sendMessage($event)"
    [privateMessageData]="privateMessageData"
    (privateMessageRemoveNotify)="this.privateMessageData = null"></app-chat-input>
