<div
    class="container"
    appFileDrop
    (fileDropped)="onFileDropped($event)"
    [class.image-mini]="minifiedImageUpload"
    [class.image-mode]="image"
    [matTooltip]="tooltipText ?? 'Choose File'">
    <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="fileBrowseHandler($event.target.files)"
        (mouseenter)="lottie && lottie.play()"
        (mouseleave)="lottie && lottie.stop()"
        [accept]="accepts"
        title=" " />

    <div class="image-wrapper transparent-grid" *ngIf="image">
        <div
            class="image"
            [ngStyle]="{
                'background-image': 'url(\'' + image + '\')',
            }"></div>
    </div>

    <ng-lottie
        class="upload-icon"
        containerClass="upload-animation"
        *ngIf="!image"
        [options]="{
            path: 'assets/animations/upload-file.json',
            autoplay: false,
        }"
        (animationCreated)="dropFileAnimationCreated($event)"></ng-lottie>
    <div class="text-browse" *ngIf="!text && !image">
        <span class="browse">Browse</span>
        <span> or Drop</span>
    </div>
    <div class="text-browse" *ngIf="text && !image">
        <span>{{ text }}</span>
    </div>
    <!--    <button mat-raised-button color="primary" (click)="uploadFilesSimulator(0)"> Upload </button>-->
</div>
<div class="files-list" *ngIf="!hideUpload">
    <div class="single-file" *ngFor="let file of files; let i = index">
        <mat-icon class="upload-simple-icon">upload</mat-icon>
        <div class="info">
            <h4 class="name">
                {{ file?.file.name }}
            </h4>
            <p class="size">
                {{ formatBytes(file?.file.size) }}
            </p>

            <mat-progress-bar [value]="file?.progress" mode="determinate"></mat-progress-bar>
        </div>
        <button mat-icon-button (click)="deleteFile(i)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
