import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AssetsAuthApiService } from '../../services/api/auth/assets-auth-api.service';
import { getFileCategoryAndSubType, IUploadedFileMetadata } from '../../models/files/file.model';
import { ConfigurationService } from '../../services/configuration.service';

interface ILocalFileObject {
    file: File;
    progress: number;
}

@Component({
    selector: 'app-file-drop',
    templateUrl: './file-drop.component.html',
    styleUrls: ['./file-drop.component.scss'],
})
export class FileDropComponent implements OnInit {
    @Input() accepts = '';
    @Input() text = null;
    @Input() tooltipText = null;
    @Input() hideUpload = false;
    @Input() minifiedImageUpload = false;
    @Input() image = null;

    @Input() public uploadAPI: Function = this.assetsService.upload;
    @Input() public extraData: {} = null;
    @Output() uploadFinished = new EventEmitter<IUploadedFileMetadata>();

    public myCustomMouseover = new EventEmitter<void>();
    baseCdn = this.configuration.baseCdnUrl;

    constructor(
        public assetsService: AssetsAuthApiService,
        private configuration: ConfigurationService
    ) {}

    ngOnInit() {}

    lottie: AnimationItem;

    dropFileAnimationCreated(animationItem: AnimationItem): void {
        // console.log('Cutout animation', animationItem);
        this.lottie = animationItem;

        // this.updateAnimationData()
    }

    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    files: ILocalFileObject[] = [];

    /**
     * on file drop handler
     */
    onFileDropped($event: FileList) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler($event: FileList) {
        this.prepareFilesList($event);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        if (this.files[index].progress < 100) {
            console.log('Upload in progress.');
            return;
        }
        this.files.splice(index, 1);
    }

    /**
     * Simulate the upload process
     */
    uploadFiles(localFileObject: ILocalFileObject) {
        if (!localFileObject) {
            return;
        }
        const { file } = localFileObject;
        const formData = new FormData();
        formData.append('file', file, file.name);
        const mimeTypeData = getFileCategoryAndSubType(file.type);

        this.uploadAPI(formData, this.extraData ?? {}).subscribe(
            (event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        // this.slimLoadingBarService.start();

                        console.log('Upload Started', event);

                        break;
                    case HttpEventType.Response:
                        console.log('Uploaded Successfully');

                        // eslint-disable-next-line no-case-declarations
                        const url = event.body?.uploaded?.uploadPath
                            ? this.baseCdn + event.body.uploaded.uploadPath
                            : '';

                        // eslint-disable-next-line no-case-declarations
                        const fileMetadta: IUploadedFileMetadata = {
                            name: file.name,
                            ...mimeTypeData,
                            uploadedUrl: url,
                        };
                        this.uploadFinished.next(fileMetadta);

                        console.log(`Response`, event.body.uploaded?.uploadPath);
                        /// ATTENTION - Will enable only one file upload
                        this.files = [];

                        // this.slimLoadingBarService.complete();
                        // this.message = "Uploaded Successfully";
                        // this.showMessage = true;
                        break;
                    case 1: {
                        if (
                            Math.round(localFileObject.progress) !==
                            Math.round((event['loaded'] / event['total']) * 100)
                        ) {
                            localFileObject.progress = (event['loaded'] / event['total']) * 100;
                        }
                        break;
                    }
                }
            },
            (error) => {
                console.log(error);
                // this.message = "Something went wrong";
                // this.showMessage = true;
                // this.slimLoadingBarService.reset();
            }
        );

        // setTimeout(() => {
        //   if (index === this.files.length) {
        //     return;
        //   } else {
        //     const progressInterval = setInterval(() => {
        //       if (this.files[index].progress === 100) {
        //         clearInterval(progressInterval);
        //         this.uploadFiles(index + 1);
        //       } else {
        //         this.files[index].progress += 5;
        //       }
        //     }, 200);
        //   }
        // }, 1000);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: FileList) {
        console.log('kind of files', files);
        const localFiles: ILocalFileObject[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            if (!file) {
                continue;
            }

            const localFile: ILocalFileObject = {
                file: file,
                progress: 0,
            };

            localFiles.push(localFile);
        }
        for (const localFile of localFiles) {
            this.files.push(localFile);
            this.uploadFiles(localFile);
        }
        // this.fileDropEl.nativeElement.value = "";
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
