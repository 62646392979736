import { IPathAndDash } from './defines';

export type ConfigStepsTypes = ITask | any;

export interface IConfigSteps {
    steps: ConfigStepsTypes[];
}

export interface IConfig {
    id: string;
    config: IConfigSteps;
}

export interface IWalkthorughConfigStep extends IConfigSteps {
    steps: ITask[];
}

export interface IWalkthorugh extends IConfig {
    config: IWalkthorughConfigStep;
}

export interface ITaskConfigs {
    btnText: string;
    route: string;
    panelRoute?: string;
    toPlay?: boolean;
    isCompleted: Boolean;
}
export enum SpecialTaskTypeEnum {}
export enum TaskTypeEnum {
    ACCOUNT = 'account',
    BRANDING = 'branding',
    SETUP_DEVICES = 'setup-devices',
    PRODUCTION = 'production',
    STUDIO = 'studio',
    EDITOR = 'editor',
}

export interface ITask {
    id: string;
    category: TaskTypeEnum;
    title: string;
    description?: string;
    videoSources?: IPathAndDash;
    configs: ITaskConfigs;
    isCompleted: boolean;
}

export const taskLinksMap = new Map<TaskTypeEnum, ITaskConfigs>([
    [
        TaskTypeEnum.BRANDING,
        {
            btnText: 'Go to branding',
            route: 'dashboard',
            panelRoute: 'branding',
            isCompleted: false,
        },
    ],
    [
        TaskTypeEnum.PRODUCTION,
        {
            btnText: 'Go to production',
            route: 'dashboard',
            panelRoute: 'production',
            isCompleted: false,
        },
    ],
    [
        TaskTypeEnum.SETUP_DEVICES,
        {
            btnText: 'Set up devices',
            route: 'host',
            isCompleted: false,
        },
    ],
    [
        TaskTypeEnum.STUDIO,
        {
            btnText: 'Start from production',
            route: 'dashboard',
            panelRoute: 'production',
            isCompleted: false,
        },
    ],
    [
        TaskTypeEnum.EDITOR,
        {
            btnText: 'Go to editor',
            route: 'dashboard',
            panelRoute: 'post',
            isCompleted: false,
        },
    ],
]);
