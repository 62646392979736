import { Injectable } from '@angular/core';

@Injectable()
export class MouseWheelHelper {
    betweenScrollsThreshold = 400; // milis
    sequenceCounter = 0;
    sequencePreviousTimestamp = 0;
    // Remember the first one won't count
    goal = 1;

    constructor() {}

    public wheel(event: Event) {
        // Don't allow goal to reach more then once
        console.log('mouse');

        if (this.sequenceCounter === this.goal) {
            console.log('counter: ' + this.sequenceCounter + '\ngoal: ' + this.goal);

            return false;
        }
        // Check if we are in the middle of a sequence
        console.log(
            'event time: ' +
                event.timeStamp +
                '\nprevious: ' +
                this.sequencePreviousTimestamp +
                '\ntime between: ' +
                this.betweenScrollsThreshold +
                '\ndiff: ' +
                ((event.timeStamp as number) - this.sequencePreviousTimestamp)
        );
        if (event.timeStamp < this.sequencePreviousTimestamp + this.betweenScrollsThreshold) {
            this.sequenceCounter++;
        } else {
            // It's a new sequence
            this.sequenceCounter = 0;
        }
        // Set the new timestamp
        this.sequencePreviousTimestamp = event.timeStamp;
        // check if goal reached
        return this.sequenceCounter === this.goal;
    }
}
