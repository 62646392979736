import { Injectable } from '@angular/core';
import { NavigatorPermissionsService } from '../navigator/navigator-permissions.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SnackBarService, SnackbarActionEnum } from '../utils/snack-bar.service';
import { DeviceInUseComponent } from 'src/app/components/dialogs/device-in-use/device-in-use.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class NavigatorDevicesService {
    private _cameraPermission$ = new BehaviorSubject<boolean>(false);
    public cameraPermission$ = this._cameraPermission$.asObservable();

    private _microphonePermission$ = new BehaviorSubject<boolean>(false);
    public microphonePermission$ = this._microphonePermission$.asObservable();

    private _devicesPermissions$ = new BehaviorSubject<boolean>(false);
    public devicesPermissions$ = this._devicesPermissions$.asObservable();

    private _deviceChanged$ = new BehaviorSubject<void>(null);
    public deviceChanged$ = this._deviceChanged$.asObservable();

    constructor(
        private navigatorPermissionsService: NavigatorPermissionsService,
        private snackBarService: SnackBarService,
        public dialog: MatDialog
    ) {
        this.initNavigatorStates();
        // Listen for device changes and re-initialize devices

        const permissions$ = combineLatest([this._cameraPermission$, this._microphonePermission$]);
        permissions$.subscribe(([cameraPermission, microphonePermission]) => {
            const generalPermissions = cameraPermission && microphonePermission;
            this._devicesPermissions$.next(generalPermissions);
        });

        navigator.mediaDevices.addEventListener('devicechange', () => {
            this._deviceChanged$.next();
        });
    }

    public cameraOrMicOff() {
        this._cameraPermission$.next(false);
    }

    public getDevicePermissionsValue() {
        return this._devicesPermissions$.value;
    }

    public initNavigatorStatesIfNotSet() {
        if (!this._cameraPermission$.value || !this._microphonePermission$.value) {
            this.requestCombinedPermissions();
        }
    }

    public initNavigatorStates() {
        this.requestCombinedPermissions();
    }

    private async requestCombinedPermissions() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
            this._cameraPermission$.next(true);
            this._microphonePermission$.next(true);
            // Stop all tracks to release the media devices
            stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                this._cameraPermission$.next(false);
                this._microphonePermission$.next(false);
                // this.informUserAboutPermissions();
            } else {
                this.dialog.open(DeviceInUseComponent, {
                    minWidth: '400px',
                    minHeight: '150px',
                });
                console.error('Error accessing media devices.', error);
            }
        }
    }

    private informUserAboutPermissions() {
        this.snackBarService.openMessage(
            `The application requires access to your camera and microphone. Please allow these permissions to use this feature.`,
            SnackbarActionEnum.Close,
            5000
        );
    }
}
