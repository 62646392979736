import { Injectable } from '@angular/core';
import { BehaviorSubject, NotFoundError } from 'rxjs';
import { IndexCacheService } from '../index-cache.service';
import { FunctionsHelperService } from '../functions-helper.service';

import { ITake } from 'src/app/models/project/take/take-model';
import { MimeTypeEnum } from 'src/app/models/defines';
import { IChunkIndexDBBlobObject } from '../../models/indexDB.model';
import { MediaRecorderNotFoundError } from '../../models/errors/project-errors/recording-errors';

interface IUploadQueueManager {
    uploadQueue: Array<() => Promise<void>>;
    isUploading: boolean;
    finishedUploadingAsync: Promise<void> | null;
    resolveFinishedUploading: (() => void) | null;
}

export interface IBlobObject {
    id: string;
    streamAsBlob: Blob;
}

interface IIndexDBBlobObject extends IBlobObject {
    arrayBuffer: ArrayBuffer; /// must have, we save the local recording in our indexdb as array buffer.
    position: number; /// the number of the chunk from the stream, 1 2 3 etc
}

export interface ILocalRecordingModel extends IBlobObject {
    streamAsUrl: string;
}

export interface IMediaRecorderState {
    mediaRecorder: MediaRecorder;
    isRecording: boolean; /// To know if it's stopped or not yet from producing available data
    recordingPromise: Promise<boolean>; /// to await until he is stopping to record
}

export interface IChunkCounterAndUploader {
    id: string;
    counter: number; /// how many in total we have
    uploads: number; /// how many we uploaded
    howMuchCompletedInPercentages: number;
}

@Injectable({
    providedIn: 'root',
})
export class LocalRecorderService {
    /**
     * Media recorders saved by a unique key.
     */
    private mediaRecorders = new Map<string, IMediaRecorderState>();
    /**
     * Only usefull if we want to stop the track. Currently not useable, consider to remove.
     */
    private mediaStreams = new Map<string, MediaStream>();

    /**
     * The chunks that we load to make the recording. Currently not useable, consider to remove.
     */
    private recordingChunksMap = new Map<string, Blob[]>();
    /**
     * The key is the unique recording id, the value is the chunks counter for that specific recording
     */
    private recordingChunksCounterMap = new Map<string, IChunkCounterAndUploader>();

    private recordingChunksCounterSubject = new BehaviorSubject<IChunkCounterAndUploader>(null);

    public recordingChunksCounter$ = this.recordingChunksCounterSubject.asObservable();

    private timestampsForMediaRecorder: number = 2000;

    private isUploadingSubject = new BehaviorSubject<boolean>(false);
    public isUploading$ = this.isUploadingSubject.asObservable();

    private activatedMediaRecorders = new Map<string, MediaRecorder>();

    private isAnyMediaRecorderActiveSubject = new BehaviorSubject<boolean>(false);
    public isAnyMediaRecorderActive$ = this.isAnyMediaRecorderActiveSubject.asObservable();

    constructor(
        private indexCacheService: IndexCacheService,
        private functionsHelper: FunctionsHelperService
    ) {}

    public setMediaRecorder(stream: MediaStream, recordUniqueId: string, take: ITake) {
        if (!stream || !recordUniqueId) {
            console.error(`Could not start recording because one of the arguments is null.`);
            return;
        }
        const mediaRecorder = take.setMediaRecorder(stream);

        const mediaRecorderPromise = new Promise<boolean>((recordingResolve, reject) => {
            mediaRecorder.onstop = async () => {
                const blob = new Blob(recordChunks, {
                    type: take.currentBaseMimetype ?? MimeTypeEnum.VideoWebm, /// In case it somehow fails to get the base mimetype from record manager
                });
                try {
                    const saveChunkPromise = this.saveChunkInIndexDBAsync(blob, take);

                    await uploadQueueManager.finishedUploadingAsync;
                    this.activatedMediaRecorders.delete(recordUniqueId);
                    try {
                        await saveChunkPromise;
                    } catch (indexError) {
                        console.error(`Could not save chunks in indexDB and update local path`, indexError);
                    }
                    this.emitActivatedRecordersValue();
                    // this.updateLocalRecordingsSubject(recordUniqueId, blob);
                    recordingResolve(true);
                } catch (error) {
                    console.error(`Error saving recording ${recordUniqueId}:`, error);
                    reject(error);
                }
            };

            mediaRecorder.onerror = (event) => {
                console.error(`Recording error for ${recordUniqueId}:`, event);
                reject(event);
            };
        });

        const mediaRecorderState: IMediaRecorderState = {
            mediaRecorder: mediaRecorder,
            isRecording: true,
            recordingPromise: mediaRecorderPromise,
        };
        this.mediaRecorders.set(recordUniqueId, mediaRecorderState);

        const recordChunks: Blob[] = [];

        this.recordingChunksMap.set(recordUniqueId, recordChunks);

        let numberOfTheChunk: number = 0;

        const chunksUpload: IChunkCounterAndUploader = {
            id: recordUniqueId,
            counter: 0,
            uploads: 0,
            howMuchCompletedInPercentages: 100,
        };

        this.recordingChunksCounterMap.set(recordUniqueId, chunksUpload);
        this.recordingChunksCounterSubject.next(chunksUpload);

        const uploadQueueManager: IUploadQueueManager = {
            uploadQueue: [],
            isUploading: false,
            finishedUploadingAsync: null,
            resolveFinishedUploading: null,
        };
        uploadQueueManager.finishedUploadingAsync = new Promise<void>((resolve) => {
            uploadQueueManager.resolveFinishedUploading = resolve;
        });
        mediaRecorder.ondataavailable = async (event: BlobEvent) => {
            const blob = event.data;
            if (blob.size <= 0) {
                return;
            }
            recordChunks.push(blob);
            numberOfTheChunk++;
            // Ensure the entry exists for the given recordUniqueId and update its counter
            this.setChunksCounter(recordUniqueId, numberOfTheChunk);
            // Capture current values to avoid closure issues (good practice)
            const currentChunkNumber = numberOfTheChunk;
            const currentBlob = blob;
            /**
             * Ensures that chunks are uploaded one by one in sequential order per recording.
             * This means that the second chunk will wait for the first chunk to be uploaded before starting,
             * the third chunk will wait for the second, and so on.
             * Even if multiple chunks are queued for upload, each chunk will wait for the previous one to finish uploading.
             */

            uploadQueueManager.uploadQueue.push(async () => {
                // 'formDataFields.shift()' retrieves the first chunk in the queue for upload.
                await this.uploadBlobToServerAsync(currentBlob, take, currentChunkNumber);
                this.setChunksUploader(recordUniqueId);
                if (!mediaRecorderState.isRecording && uploadQueueManager.uploadQueue.length === 0) {
                    // Resolve the promise when all chunks have been uploaded
                    uploadQueueManager.resolveFinishedUploading?.();
                }
            });
            // Start processing the queue if not already started
            this.processUploadQueue(uploadQueueManager);
        };

        this.activatedMediaRecorders.set(recordUniqueId, mediaRecorder);
        this.emitActivatedRecordersValue();

        return mediaRecorder;
    }

    startMediaRecorder(recordUniqueId: string) {
        const mediaRecorder = this.activatedMediaRecorders.get(recordUniqueId);
        if (!mediaRecorder) {
            throw new MediaRecorderNotFoundError(`Media recorder not found`);
        }
        mediaRecorder.start(this.timestampsForMediaRecorder);
    }
    private async saveChunkInIndexDBAsync(blob: Blob, take: ITake) {
        const arrayBuffer = await this.functionsHelper.blobToArrayBufferAsync(blob);
        const indexDBBlobObject: IChunkIndexDBBlobObject = {
            id: take.videoLayers[0].uinuqeId,
            position: 0,
            arrayBuffer: arrayBuffer,
        };
        const didAdd = await take.videoLayerDBController.addTableToProjectAsync(indexDBBlobObject);
        if (didAdd) {
            take.videoLayerDBController.setLocalUploadPath(take.videoLayers[0], arrayBuffer);
        }
    }

    private uploadBlobToServerAsync(chunk: Blob, take: ITake, numberOfTheChunk) {
        return new Promise<boolean>(async (uploadResolve, uploadReject) => {
            // try {

            //   await take.videoLayerDBController.updateLocalRecordingIndexDBAsync(
            //     recordUniqueId,
            //     formDataField.blob
            //   );
            // } catch (error) {
            //   console.error(
            //     `Could not save blob in indexdb while streaming. error: ${error}`
            //   );
            // }

            this.isUploadingSubject.next(true);
            try {
                await take.appendChunkToVideoLayerAsync(chunk);
                return uploadResolve(true);
            } catch (error) {
                ///TODO: handle
                console.error(`Error while trying to append chunk to video, error: ${error}`);
                return uploadReject(error);
            }
        });
    }

    private async processUploadQueue(uploadQueueManager: IUploadQueueManager) {
        if (uploadQueueManager.isUploading) {
            return; // If already uploading, do nothing
        }
        uploadQueueManager.isUploading = true;

        while (uploadQueueManager.uploadQueue.length > 0) {
            const uploadTaskAsync = uploadQueueManager.uploadQueue.shift(); // Get the first task in the queue
            if (uploadTaskAsync) {
                try {
                    await uploadTaskAsync(); // Await its completion
                } catch (error) {
                    console.error('Upload failed', error);
                    // Handle the error if needed
                }
            }
        }

        uploadQueueManager.isUploading = false;
    }

    private setChunksCounter(recordUniqueId: string, numberOfTheChunk: number) {
        const chunksCounterData = this.recordingChunksCounterMap.get(recordUniqueId);
        if (chunksCounterData) {
            // Only update the counter property, keeping the uploads as is
            chunksCounterData.counter = numberOfTheChunk;
        } else {
            // Handle the case where chunksCounterData is undefined or null
            // For example, initializing the entry if it's supposed to be created here
            this.recordingChunksCounterMap.set(recordUniqueId, {
                id: recordUniqueId,
                counter: numberOfTheChunk,
                uploads: 0,
                howMuchCompletedInPercentages: 0,
            });
        }
        this.setPercentagesAndSetChunksSubject(chunksCounterData);
    }

    private setChunksUploader(recordUniqueId: string) {
        const chunksCounterData = this.recordingChunksCounterMap.get(recordUniqueId);
        if (!chunksCounterData) {
            return;
        }

        chunksCounterData.uploads++;
        this.setPercentagesAndSetChunksSubject(chunksCounterData);
    }

    /**
     *
     * @param recordUniqueId
     * @param numberOfUploads If not provided, raising uploads counter by 1
     */
    private setChunksUploadsCounter(recordUniqueId: string, numberOfUploads?: number) {
        const chunksCounterData = this.recordingChunksCounterMap.get(recordUniqueId);
        if (chunksCounterData) {
            chunksCounterData.uploads = numberOfUploads ?? chunksCounterData.uploads + 1;
            // Only update the counter property, keeping the uploads as is
            // this.recordingChunksCounterMap.set(recordUniqueId, {
            //   ...chunksCounterData, // Spread operator to copy existing properties
            //   uploads: numberOfUploads ?? chunksCounterData.uploads + 1, // Update the counter with the new value
            // });
            this.setPercentagesAndSetChunksSubject(chunksCounterData);
        } else {
            /// Very weird if we get to here. we won't do anything ?
            console.error(
                `Could not set chunks upload counter because there is no chunks counter in map with id: ${recordUniqueId}`
            );
        }
    }

    private async saveIndexBlobObject(chunkUniqueId: string, blob: IIndexDBBlobObject): Promise<void> {
        // return this.localRecordingIndexDBManager.updateLocalRecordingIndexDBAsync({
        //   id: chunkUniqueId,
        //   content: blob,
        // })
        // return this.indexCacheService.updateRecordAsync(
        //   this.currentRecordingIndexDBRelated.currentRecordingDBName,
        //   this.currentRecordingIndexDBRelated.currentRecordingStoreManager,
        //   {
        //     id: chunkUniqueId,
        //     content: blob,
        //   }
        // );
    }

    // private updateLocalRecordingsSubject(recordId: string, blob: Blob) {
    //   const recordUrl = URL.createObjectURL(blob);
    //   const localRecording: ILocalRecordingModel = {
    //     id: recordId,
    //     streamAsBlob: blob,
    //     streamAsUrl: recordUrl,
    //   };
    //   const currentLocalRecords = this.localRecordFilesSubject.value;
    //   currentLocalRecords.push(localRecording);
    //   this.localRecordFilesSubject.next(currentLocalRecords);
    // }

    public stopRecordingAsync(recordUniqueId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const mediaRecorderIndexObject = this.mediaRecorders.get(recordUniqueId);
            if (!mediaRecorderIndexObject) {
                console.error(`Could not stop recording: MediaRecorder not found for ID ${recordUniqueId}`);
                /// Stopping existed media recorders because even though we couldn't find the media recorder we wanted,
                /// We don't want to keep record the user.
                this.mediaRecorders.forEach((mediaRecorder) => {
                    mediaRecorder.mediaRecorder.stop();
                });

                return reject(new MediaRecorderNotFoundError(`Id not found: ${recordUniqueId}`));
            }

            // Remove the recorder and chunks from the maps to clean up
            mediaRecorderIndexObject.isRecording = false;
            mediaRecorderIndexObject.mediaRecorder?.stop();
            if (mediaRecorderIndexObject.recordingPromise) {
                await mediaRecorderIndexObject.recordingPromise;
            }
            return resolve();
        });
    }

    public removeBlobObjectFromLocal(recordUniqueId: string) {
        this.mediaRecorders.delete(recordUniqueId);
        this.recordingChunksMap.delete(recordUniqueId);
    }

    public getBlobsFromLocal(recordUniqueId: string) {
        return this.recordingChunksMap.get(recordUniqueId);
    }

    private async removeFromIndexedDBAsync(dbName: string, storeName: string, recordId: string) {
        try {
            this.indexCacheService.deleteRecordAsync(dbName, storeName, recordId);
            console.log(`Recording ${recordId} removed from IndexedDB.`);
        } catch (error) {
            console.error(`Error removing recording ${recordId} from IndexedDB:`, error);
        }
    }

    // private removeLocalRecordingFromSubject(recordId: string) {
    //   const currentLocalRecords = this.localRecordFilesSubject.value;
    //   const index = currentLocalRecords.findIndex(
    //     (record) => record.id === recordId
    //   );
    //   if (index > -1) {
    //     currentLocalRecords.splice(index, 1);
    //     this.localRecordFilesSubject.next(currentLocalRecords);
    //   }
    // }

    private generateChunkUniqueId(recordId: string, chunkPosition: number) {
        if (!recordId || !chunkPosition) {
            console.error(`Could not generate chunk unique id because one of the arguments is null.`);

            return null;
        }

        return recordId + 'X' + chunkPosition;
    }

    private setPercentagesAndSetChunksSubject(chunksCounterData: IChunkCounterAndUploader) {
        chunksCounterData.howMuchCompletedInPercentages = this.calculatePercentageDifference(
            chunksCounterData.uploads,
            chunksCounterData.counter
        );
        // When updating the state or emitting values through observables, ensure it's done inside Angular's zone:
        //* It could not detect it without running it inside the ngZone,
        //* it may occurre due to an operation that Angular does not recognize
        //* third-party libraries or APIs that might operate outside Angular's zone
        this.recordingChunksCounterSubject.next(chunksCounterData);
    }

    private calculatePercentageDifference(currentUploads: number, totalUploads: number): number {
        if (totalUploads === 0) {
            throw new Error('Total uploads cannot be zero.');
        }
        // Calculate the percentage of uploads completed
        const percentageCompleted = (currentUploads / totalUploads) * 100;

        return percentageCompleted;
    }

    private emitActivatedRecordersValue() {
        this.isAnyMediaRecorderActiveSubject.next(this.checkIfMediaRecordersAreActivated());
    }

    private checkIfMediaRecordersAreActivated() {
        if (this.activatedMediaRecorders.size > 0) {
            return true;
        }

        return false;
    }
}

function checkCodecSupport(): { baseMimetype: string; codecs: string } {
    // Helper function to check codec support

    // Check if MediaRecorder API is supported in the browser
    if (!window.MediaRecorder) {
        throw new Error('MediaRecorder API is not supported in this browser.');
    }

    // Define MIME types for VP9 and VP8 codecs
    const baseWebmMimetype = 'video/webm';
    const vp9MimeType = `${baseWebmMimetype};codecs=vp9`;
    const vp9Supported = isCodecSupported(vp9MimeType);
    if (vp9Supported) {
        return { baseMimetype: baseWebmMimetype, codecs: 'vp9' };
    }

    const vp8MimeType = `${baseWebmMimetype};codecs=vp8`;
    const vp8Supported = isCodecSupported(vp8MimeType);
    if (vp8Supported) {
        return { baseMimetype: baseWebmMimetype, codecs: 'vp8' };
    }

    const webmMimeType = `${baseWebmMimetype}`;
    const webmSupported = isCodecSupported(webmMimeType);
    if (webmSupported) {
        return { baseMimetype: baseWebmMimetype, codecs: '' };
    }

    // Define MIME types for MP4 with H.264 and AAC codecs
    const baseMp4Mimetype = 'video/mp4';
    const mp4MimeType = `${baseMp4Mimetype};codecs="avc1.42E01E, mp4a.40.2"`;
    const mp4Supported = isCodecSupported(mp4MimeType);
    if (mp4Supported) {
        return { baseMimetype: baseMp4Mimetype, codecs: 'avc1.42E01E, mp4a.40.2' };
    }

    // Throw an error if none of the codecs are supported
    throw new Error('Neither VP9 nor VP8 codecs, nor WebM or MP4 formats are supported in this browser.');
}

function isCodecSupported(mimeType: string): boolean {
    return MediaRecorder.isTypeSupported(mimeType);
}
