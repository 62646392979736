import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
    selector: 'app-lottie-tester',
    templateUrl: './lottie-tester.component.html',
    styleUrls: ['./lottie-tester.component.scss'],
})
export class LottieTesterComponent {
    constructor() {}
}
