import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { VoiceRecognitionService } from '../../../services/voice-recognition.service';
import { ColorPalettesService } from '../../../services/utils/color-palettes.service';
import { MatDialog } from '@angular/material/dialog';
import { ITextPortion } from '../../../models/Teleprompter';
import { IPrompterSettings } from '../../../models/teleprompter/prompter-settings-model';
import { DirectorCommand } from '../../../models/defines';
import { CopywriterService } from '../../../services/show/copywriter.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrompterSyncronizerService } from '../../../services/prompter-syncronizer.service';
import { v4 as uuidv4 } from 'uuid';
import { ApplyPrompterTextComponent } from '../../dialogs/apply-prompter-text/apply-prompter-text.component';
import { VoiceRecognitionSupportedStateService } from '../../../services/voice-recognition-supported-state.service';
import { ProfileService } from '../../../services/show/profile.service';
import { UserService } from '../../../services/api/auth/user.service';

@Component({
    selector: 'app-teleprompter',
    templateUrl: './teleprompter.component.html',
    styleUrls: ['./teleprompter.component.scss'],
})
export class TeleprompterComponent implements OnInit, OnDestroy {
    displayNotes = false;
    prompterID = uuidv4();
    @Output('finished-walkthrough-step') finishedWalkthroughStep = new BehaviorSubject<boolean>(false);

    @ViewChild('scroller') scroller: ElementRef<HTMLTextAreaElement>;
    @ViewChild('scrollerWrap') scrollerWrap: ElementRef<HTMLTextAreaElement>;
    @Input() hosted = false;

    @Output('text-changed') textChanged = new EventEmitter<string>();
    @Output('on-edit-text') onEditText = new EventEmitter<boolean>();
    @Output('toggle-teleprompter-height') onTeleprompterHeightChange = new EventEmitter<boolean>();
    isOnEdit: boolean = false;
    notes = '';
    NOTES_LOCALSTORAGE_KEY = 'prompterNotes';
    @Input('backgroundColor')
    backgroundColor = '#222';
    manualText = '';
    previousText: string = '';
    @Input('textColor')
    textColor = '#fff';
    userBrowser: string = this.profileService.userBrowser;

    @Input('fontSize')
    fontSize = 30;
    speed = 7;
    margin = 0;
    isPlaying = false;
    scrollInterval;
    aiScrollInterval;
    isVoiceRecognitionSupported: boolean = true;
    @Input('aiMode')
    aiMode = true;
    masterUp = false;
    notificationOpen = false;
    private onDestroy$: Subject<boolean> = new Subject();
    teleprompterSettings: IPrompterSettings;
    promptSettingsLSKey = 'prompterSettings';
    isOnThreeSeconds: boolean = false;
    currentWordHtml: any = '';
    isRecording: boolean = false;
    isHovered: boolean = false;
    isShuffllUser = false;
    isBiggerHeight = false;
    settingsExample: IPrompterSettings = {
        margin: 0,
        speed: this.userBrowser === 'Safari' ? 6 : 3,
        fontSize: 24,
        textAlignment: { align: 'left', icon: 'format_align_left' },
        flippedX: false,
        flippedY: false,
        backgroundColor: '#222',
        textColor: '#fff',
        voiceRecognitionMode: true,
    };

    constructor(
        private cdRef: ChangeDetectorRef,
        public dialog: MatDialog,
        public copywriter: CopywriterService,
        private _snackBar: MatSnackBar,
        public prompterSync: PrompterSyncronizerService,
        public voiceRecognition: VoiceRecognitionService,
        public colorPalettesService: ColorPalettesService,
        private voiceRecognitionSupportedService: VoiceRecognitionSupportedStateService,
        private profileService: ProfileService,
        private userService: UserService
    ) {
        this.isShuffllUser = this.profileService.user$.value?.isShuffllUser;
    }

    // onRecordButtonClick() {
    //   this.playing = true;
    // }

    ngOnInit(): void {
        this.loadSettings();
        this.subscribeToPromptSync();

        this.voiceRecognition.notifyScrollTeleprompter
            .pipe(takeUntil(this.onDestroy$)) // Clean up the subscription on component destroy
            .subscribe((word) => {
                if (!word) {
                    return;
                } else {
                    const scrollIndex = word.index;
                    const scrollWord = document.getElementById(scrollIndex.toString());
                    if (scrollWord) {
                        this.currentWordHtml = scrollWord;
                    }
                }
            });
        this.prompterSync.recordButtonClicked
            .pipe(takeUntil(this.onDestroy$)) // Clean up the subscription on component destroy
            .subscribe((isPlaying) => {
                if (isPlaying) {
                    this.isOnThreeSeconds = true;
                    this.isRecording = true;
                }
                this.isPlaying = isPlaying;
            });

        // When scenes changes while in middle of playing;
        this.prompterSync.specificSceneClicked
            .pipe(takeUntil(this.onDestroy$)) // Clean up the subscription on component destroy
            .subscribe(() => {
                this.playPause(false);
            });
        /// Connect
        if (!this.hosted) {
            this.prompterSync.masterUp.subscribe((status) => {
                // console.log('Changing Master to  ' + status);
                this.masterUp = status;
                this.cdRef.detectChanges();
            });
            this.notifyUp();

            this.prompterSync.grabPrompterText.subscribe((messageData) => {
                if (messageData && !this.notificationOpen) {
                    this.notificationOpen = true;
                    const applyPrompterTextDialog = this.dialog.open(ApplyPrompterTextComponent, {
                        disableClose: false,
                        panelClass: 'padding-modal',
                    });

                    applyPrompterTextDialog.afterClosed().subscribe((approval) => {
                        this.notificationOpen = false;
                        // console.log('End Live Dialog result:', approval);
                        // If the user approved, end live
                        if (approval) {
                            if (messageData.version && messageData.text) {
                                this.manualText = messageData.text;
                            }
                        }
                    });
                }
            });
        } else {
            /// For the hosted prompter
            this.prompterSync.publishPrompterText.subscribe((message) => {
                if (message?.text) {
                    this.manualText = message.text;
                    this.copywriter.copy.next(message.text);
                }
            });
        }
        if (!this.voiceRecognitionSupportedService.getSpeechRecognitionSupported()) {
            this.isVoiceRecognitionSupported = false;
            this.teleprompterSettings.voiceRecognitionMode = false;
        }
        if (this.userBrowser === 'Safari') {
            this.teleprompterSettings.voiceRecognitionMode = false;
            this.saveSettings();
        }
    }

    publish() {
        this.prompterSync.publish(this.prompterID, 'Version1', this.manualText);
        this._snackBar.open('🥳 Script published to guests!', 'Dismiss', {
            duration: 3000,
        });

        this.setCopy(this.manualText, false);
    }

    notifyUp() {
        this.prompterSync.connectSlave(this.prompterID);
    }

    resetTeleprompterState() {
        this.rewindToStart('smooth');
        /// Only if we play we reset the button
        if (this.isPlaying) {
            this.playPause(false);
        }
    }

    saveSettings() {
        localStorage.setItem(this.promptSettingsLSKey, JSON.stringify(this.teleprompterSettings));
    }

    loadSettings() {
        const savedSettings = localStorage.getItem(this.promptSettingsLSKey);

        if (!savedSettings) {
            this.setDefaultSettings();
            return;
        }
        const settings: IPrompterSettings = JSON.parse(savedSettings);
        const isChanged = this.checkTeleprompterSettings(settings);
        this.teleprompterSettings = settings;
        if (isChanged) {
            this.saveSettings();
        }
    }

    checkTeleprompterSettings(settingsObj: IPrompterSettings): boolean {
        let isModified = false; // Flag to track if any modifications were made

        // Iterate through the keys of settingExample
        Object.keys(this.settingsExample).forEach((key) => {
            const settingKey = key;

            // Check if the key is missing or the value is null in settingsObj
            if (settingsObj[settingKey] === undefined || settingsObj[settingKey] === null) {
                // If the key is missing or the value is null, add the value from settingExample
                settingsObj[settingKey] = this.settingsExample[settingKey];
                isModified = true; // Set the flag to true since we modified settingsObj
            }
        });

        return isModified; // Return true if any modifications were made, false otherwise
    }

    setDefaultSettings() {
        this.teleprompterSettings = this.settingsExample;
        this.saveSettings();
    }

    onTextBlur(event: Event): void {
        const inputElement = event.target as HTMLTextAreaElement;
        const newText = inputElement.value;

        if (newText !== this.previousText) {
            this.manualText = newText;
            this.previousText = newText;
            // this.sendTextToServer(newText);
            this.textChanged.emit(this.previousText);
        }
    }

    saveNotes() {
        if (this.notes) {
            localStorage.setItem(this.NOTES_LOCALSTORAGE_KEY, this.notes);
        }
    }

    dummyTextChanged(newText: string) {
        this.textChanged.emit(newText);
    }

    aiTextChanged(newText: string) {
        this.textChanged.emit(newText);
    }

    aiEditMode(isEdit: boolean) {
        this.onEditText.emit(isEdit);
        this.isOnEdit = isEdit;
    }

    dummyEditMode(isEdit: boolean) {
        this.onEditText.emit(isEdit);
        this.isOnEdit = isEdit;
    }

    teleprompterHeightChange() {
        this.isBiggerHeight = !this.isBiggerHeight;
        this.onTeleprompterHeightChange.emit(this.isBiggerHeight);
    }

    onHoverStatusChange(isHover: boolean) {
        this.isHovered = isHover;
    }

    toggleTextAlign() {
        const textAlignObj = this.teleprompterSettings.textAlignment;
        if (textAlignObj.align === 'left') {
            textAlignObj.align = 'center';
            textAlignObj.icon = 'format_align_center';
        } else if (textAlignObj.align === 'center') {
            textAlignObj.align = 'right';
            textAlignObj.icon = 'format_align_right';
        } else if (textAlignObj.align === 'right') {
            textAlignObj.align = 'left';
            textAlignObj.icon = 'format_align_left';
        }
    }

    rewindToStart(behavior: ScrollBehavior) {
        if (this.scrollerWrap?.nativeElement) {
            this.scrollerWrap.nativeElement.scrollTo({ behavior: behavior, top: 0 });
        }
    }

    flip() {}

    setCopy(copy, showSnackbar = true) {
        this.copywriter.setCopy(copy);
        if (showSnackbar) {
            this._snackBar.open('Copy Saved', 'Dismiss', { duration: 2000 });
        }
    }

    displayNotesChange(event) {
        this.displayNotes = !this.displayNotes;
    }

    /**
     * Toggles play and pause states with different behaviors for AI and Manual modes.
     *
     * @param toPlayOrPause - Boolean indicating whether to play (true) or pause (false).
     */
    playPause(toPlayOrPause: boolean): void {
        this.isPlaying = toPlayOrPause;
        this.isRecording = toPlayOrPause;
        if (this.teleprompterSettings.voiceRecognitionMode) {
            this.handleAiMode();
        } else {
            this.handleManualMode();
        }
    }

    private handleAiMode(): void {
        if (this.isPlaying) {
            this.startVoiceRecognition();
            this.startAiScrolling();
        } else {
            clearInterval(this.aiScrollInterval);
            this.stopVoiceRecognition();
            this.rewindToStart('smooth');
        }
    }

    private startAiScrolling(): void {
        this.isOnThreeSeconds = false;
        this.rewindToStart('auto');
        this.stopManualScrolling();
        this.aiScrollInterval = setInterval(() => {
            this.scrollAiContent();
        }, 100);
    }

    private startVoiceRecognition(): void {
        this.voiceRecognition.start();
    }

    private stopVoiceRecognition(): void {
        this.voiceRecognition.stop();
    }

    /**
     * Handles the play/pause behavior in Manual mode.
     */
    private handleManualMode(): void {
        if (this.isPlaying) {
            this.startManualScrolling();
        } else {
            this.stopManualScrolling();
            this.rewindToStart('smooth');
        }
    }

    /**
     * Starts the manual scrolling process.
     */
    private startManualScrolling(): void {
        this.isOnThreeSeconds = false;
        this.rewindToStart('auto');
        this.stopManualScrolling();
        this.scrollInterval = setInterval(() => {
            this.scrollContent();
        }, 150);
    }

    /**
     * Stops the manual scrolling process.
     */
    private stopManualScrolling(): void {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }
    }

    /**
     * Scrolls the content smoothly based on the current speed.
     */
    private scrollContent(): void {
        const linesPerSecond = this.teleprompterSettings.speed / 4; // lines per second (already calculated or set)
        const fontSize = this.teleprompterSettings.fontSize; // font size (in pixels)
        // Pixels per second = lines per second * font size
        const pixelsPerSecond = linesPerSecond * fontSize;
        // Since the scroll interval is 100ms (1/10th of a second), we scroll 1/10th of the pixels per second

        // Less then 1 pixel and the scroll doesnt work, zero is for not moving at all
        let pixelsPerInterval = pixelsPerSecond > 0 ? Math.max(1.1, pixelsPerSecond / 10) : 0;

        // Get the zoom from the window to avoid moving one pixel but in zoom out (which result in not moving at all)
        const zoomFactorMultiplier = 1 / window.devicePixelRatio;

        pixelsPerInterval = pixelsPerInterval * zoomFactorMultiplier;

        // Now perform the scroll
        const scrollto = this.scrollerWrap.nativeElement.scrollTop + pixelsPerInterval;

        this.scrollerWrap.nativeElement.scrollTo({
            behavior: 'smooth',
            top: scrollto,
        });
    }

    private scrollAiContent(): void {
        if (!this.currentWordHtml) {
            return;
        }
        const wordRect = this.currentWordHtml.getBoundingClientRect();

        // Get the scroller-wrap element (the container you want to scroll inside)
        const scrollerWrap = this.scrollerWrap.nativeElement;

        // Get the bounding rectangle of the scroller-wrap
        const scrollerRect = scrollerWrap.getBoundingClientRect();

        // Calculate the position of the word relative to the scroller-wrap
        const wordTopRelativeToScroller = wordRect.top - scrollerRect.top;
        const wordBottomRelativeToScroller = wordRect.bottom - scrollerRect.bottom;
        if (wordTopRelativeToScroller > 40) {
            const linesPerSecond = this.teleprompterSettings.speed / 4; // lines per second (already calculated or set)
            const fontSize = this.teleprompterSettings.fontSize; // font size (in pixels)
            // Pixels per second = lines per second * font size
            const pixelsPerSecond = linesPerSecond * fontSize;
            // Since the scroll interval is 100ms (1/10th of a second), we scroll 1/10th of the pixels per second

            // Less then 1 pixel and the scroll doesnt work, zero is for not moving at all
            let pixelsPerInterval = pixelsPerSecond > 0 ? Math.max(1.1, pixelsPerSecond / 10) : 0;

            // Get the zoom from the window to avoid moving one pixel but in zoom out (which result in not moving at all)
            const zoomFactorMultiplier = 1 / window.devicePixelRatio;

            pixelsPerInterval = pixelsPerInterval * zoomFactorMultiplier;

            // Now perform the scroll
            const scrollTo = this.scrollerWrap.nativeElement.scrollTop + pixelsPerInterval;
            this.scrollerWrap.nativeElement.scrollTo({
                behavior: 'smooth',
                top: scrollTo,
            });
        }
    }

    removeEvent(text: ITextPortion, eventType: DirectorCommand = DirectorCommand.NEXT) {
        // this.voiceRecognition.addEventAfterWord(text, eventType)
        const word = this.voiceRecognition.splitText.get(text.uuid);

        if (word.events?.length) {
            word.events = word.events.filter((obj) => {
                return obj !== eventType;
            });
            text.events = word.events;
        }
    }

    createEvent(text: ITextPortion, eventType: DirectorCommand = DirectorCommand.NEXT) {
        // this.voiceRecognition.addEventAfterWord(text, eventType)
        let word = this.voiceRecognition.splitText.get(text.uuid);

        word.events = word.events ?? [];
        word.events.push(eventType);
        // To Keep thing tidy
        text.events = word.events;
        //
        // let foundInWordsList = this.voiceRecognition.splitTextOnlyWords.find(x => x.uuid === text.uuid);
        // if (foundInWordsList) {
        //   foundInWordsList.events = text.events;
        // }
        console.log(this.voiceRecognition.splitText.values());
    }

    openPrompterInANewWindow() {
        window.open('/prompter', 'newwindow', 'width=800,height=600');
    }

    private subscribeToPromptSync() {
        this.prompterSync.currentPrompterText$.pipe(takeUntil(this.onDestroy$)).subscribe((newText) => {
            if (this.manualText === newText) {
                return;
            }
            this.manualText = newText ?? '';
            this.previousText = this.manualText;
            this.copywriter.copy.next(this.manualText);
        });

        this.prompterSync.requestToStartTeleprompt.pipe(takeUntil(this.onDestroy$)).subscribe((toStart) => {
            this.playPause(toStart);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }
    }
}
