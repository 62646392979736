export class MissingArgumentsError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'MissingArgumentsError';
    }
}

export class LocalNotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'LocalNotFoundError';
    }
}

export class NotMatchError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NotMatchError';
    }
}

export class IllegalArgumentException extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'IllegalArgumentException';
    }
}
