<div class="full-width-height">
    <div
        mat-button
        (click)="openImageSelectorDialog()"
        *ngIf="imagePathSubject | async as imagePath"
        class="selected-image full-width-height"
        [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }">
        <mat-icon>edit</mat-icon>
    </div>
</div>
