import { Component, Input, OnInit } from '@angular/core';

import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';

@Component({
    selector: 'loading-with-swag',
    templateUrl: './loading-with-swag.component.html',
    styleUrls: ['./loading-with-swag.component.scss'],
    animations: [
        fadeInUpOnEnterAnimation({ delay: 500 }),
        fadeOutDownOnLeaveAnimation({ duration: 300 }),
        fadeOutUpOnLeaveAnimation({ duration: 300 }),
    ],
})
export class LoadingWithSwagComponent implements OnInit {
    @Input('steps') steps: string[] = ['Loading...'];
    currentStep = 0;

    constructor() {}

    ngOnInit(): void {}

    jumpStep() {
        if (this.currentStep < this.steps.length - 1) {
            this.currentStep++;
            return true;
        }
        return false;
    }
}

function style(arg0: { opacity: number }): import('@angular/animations').AnimationMetadata {
    throw new Error('Function not implemented.');
}
