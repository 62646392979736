import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorsHelper } from 'lottie-json-helper/lib/helpers/colors-helper';

@Component({
    selector: 'app-gradient-picker',
    templateUrl: './gradient-picker.component.html',
    styleUrls: ['./gradient-picker.component.scss'],
})
export class GradientPickerComponent implements OnInit {
    constructor() {}

    @Input() gradients: number[] | string;
    @Output() gradientStringChange = new EventEmitter<number[]>();
    colors: { color: string; position: number }[] = [{ color: 'rgb(255,255,255)', position: 0 }];

    ngOnInit() {
        if (this.gradients) {
            this.colors = ColorsHelper.colorArrayToGradientText(this.gradients as number[]);
        }
    }

    getGradientStyle(): string {
        return `linear-gradient(to right, ${this.colors.map((x) => x.color + ' ' + x.position + '%').join(', ')})`;
    }

    validatePosition(color: { color: string; position: number }) {
        if (color.position < 0) {
            color.position = 0;
        } else if (color.position > 100) {
            color.position = 100;
        }
        this.updateStringRepresentation();
    }

    onColorChange(event) {
        this.updateStringRepresentation();
    }

    removeColor(index: number): void {
        this.colors.splice(index, 1);
        this.updateStringRepresentation();
    }

    addColor(): void {
        this.colors.push({ color: '#FFFFFF', position: 100 });
        this.updateStringRepresentation();
    }

    updateStringRepresentation() {
        if (this.colors) {
            let stringRep = ColorsHelper.gradientToText(this.colors);
            if (stringRep) {
                this.gradientStringChange.next(stringRep);
            }
        }
        // console.log(this.gradientString);
    }

    onDrop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.colors, event.previousIndex, event.currentIndex);
    }

    trackByIndex(index: number, color: string): number {
        return index;
    }
}
