import { Component, Input, OnInit } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-video-toast',
    templateUrl: './video-toast.component.html',
    styleUrls: ['./video-toast.component.scss'],
    animations: [
        trigger('flyInOut', [
            state(
                'inactive',
                style({
                    opacity: 0,
                })
            ),
            transition(
                'inactive => active',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                            opacity: 0,
                        }),
                        style({
                            transform: 'skewX(20deg)',
                            opacity: 1,
                        }),
                        style({
                            transform: 'skewX(-5deg)',
                            opacity: 1,
                        }),
                        style({
                            transform: 'none',
                            opacity: 1,
                        }),
                    ])
                )
            ),
            transition(
                'active => removed',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            opacity: 1,
                        }),
                        style({
                            transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                            opacity: 0,
                        }),
                    ])
                )
            ),
        ]),
    ],
    preserveWhitespaces: false,
})
export class VideoToastComponent extends Toast implements OnInit {
    @Input() videoPath: any;

    // used for demo purposes
    undoString = 'undo';

    // constructor is only necessary when not using AoT
    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    ngOnInit(): void {}

    action(event: Event) {
        event.stopPropagation();
        this.undoString = 'undid';
        this.toastPackage.triggerAction();
        return false;
    }
}
