<h2 mat-dialog-title *ngIf="!fileMode">{{ isNew ? 'New Folder' : 'Rename Folder:' + oldName }}</h2>
<h2 mat-dialog-title *ngIf="fileMode">Rename file: {{ oldName }}</h2>
<div mat-dialog-content>
    <mat-form-field appearance="fill" class="field">
        <input
            matInput
            type="text"
            #newName="ngModel"
            [(ngModel)]="name"
            name="inviteLink"
            minlength="3"
            pattern="^[a-zA-Z0-9\s\-@.]+$"
            required
            placeholder="{{ isNew ? 'Input a name' : 'Input a new name' }}" />
        <mat-error *ngIf="newName.errors?.required || newName.errors?.minLength"
            >Name should have at least 3 letters
        </mat-error>
        <mat-error *ngIf="newName.errors?.pattern"
            >Name can only contain english characters, numbers, spaces and dashes
        </mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button
        mat-button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="{ newName: name, oldName: oldName, isNew: isNew }"
        cdkFocusInitial
        [disabled]="!newName.valid">
        Save
    </button>
</div>
