import { Component, Inject, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ProjectAuthApiService } from '../../../services/api/auth/project-auth-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'export-wait-dialog',
    templateUrl: './export-wait-dialog.component.html',
    styleUrls: ['./export-wait-dialog.component.scss'],
})
export class ExportWaitDialogComponent implements OnInit {
    congratsLottieAnimation = '/files/lottie-assets/congrats-animation.json';
    assetBasePath: string;
    isLoading$ = this.projectAuthApiService.isLoadingExportData$;

    constructor(
        private config: ConfigurationService,
        public projectAuthApiService: ProjectAuthApiService,
        private dialogRef: MatDialogRef<ExportWaitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { toEnableExport: boolean }
    ) {
        this.assetBasePath = this.config.baseCdnUrl;
    }

    ngOnInit(): void {}

    closeDialog(toExport: boolean) {
        this.dialogRef.close({ finishExport: toExport });
    }
}
