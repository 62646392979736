<div class="walkthrough-container" *ngIf="!toHide && (walkthroughData$ | async)" [@slideOut]>
    <div class="video-preview" *ngIf="selectedTask" [@fadeIn]>
        <mat-icon class="close-icon" (click)="onCloseVideo(selectedTask)">clear</mat-icon>
        <app-video-player
            *ngIf="selectedTask.configs.toPlay"
            [videoSources]="selectedTask.videoSources"
            [videoId]="'singleVideo'"
            [displayControls]="true"
            (vgApiEmitter)="onPlayerReady($event, selectedTask)"></app-video-player>
    </div>
    <mat-expansion-panel class="panel-container" [expanded]="true">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="header">
                <div class="primary-header">
                    <span>Get Started With Shuffll</span>
                    <img class="rocket-icon" src="\assets\images\icons\walkthrough\rocket.png" />
                    <mat-icon class="close-icon" (click)="onClickHide()">clear</mat-icon>
                </div>

                <span class="secondary-header"
                    >{{ walkthroughData?.config?.steps.length }} easy steps to get the most out of your videos!</span
                >
                <div class="progress-bar-container">
                    <mat-progress-bar
                        class="tasks-status-bar"
                        mode="determinate"
                        [value]="percentageCompletedTasks"></mat-progress-bar>
                    <span class="percentage-label">{{ percentageCompletedTasks.toFixed(0) }}%</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion>
            <mat-panel-description class="panel-body">
                <div class="task-container" *ngFor="let task of walkthroughData?.config?.steps; let i = index">
                    <mat-expansion-panel
                        class="task"
                        [disabled]="task.category === TaskTypeEnum.ACCOUNT"
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title
                                class="task-title"
                                [ngStyle]="{
                                    color: task.isCompleted ? 'gray' : 'none',
                                }">
                                <div class="number" *ngIf="!task.isCompleted">
                                    {{ i + 1 }}
                                </div>
                                <div class="number done" *ngIf="task.isCompleted">
                                    <mat-icon class="done-icon">done</mat-icon>
                                </div>
                                <span class="title"> {{ task.title }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-panel-description class="task-content">
                            <span class="task-description">{{ task.description }}</span>
                        </mat-panel-description>
                        <div class="task-actions">
                            <button
                                *ngIf="task.videoSources"
                                mat-stroked-button
                                class="play-btn"
                                (click)="playVideoTask(task)"
                                color="primary">
                                <mat-icon>play_arrow_outlined</mat-icon>
                                Learn More
                            </button>
                            <button
                                *ngIf="task.category !== TaskTypeEnum.STUDIO"
                                mat-flat-button
                                color="primary"
                                (click)="onClickGoToBtn(task)">
                                {{ task?.configs?.btnText }}
                            </button>
                            <div
                                *ngIf="task.category === TaskTypeEnum.STUDIO && toDisplayRecordStepBtn"
                                class="studio-btn">
                                <mat-icon class="icon">error</mat-icon>
                                <span>{{ task?.configs?.btnText }}</span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-panel-description>
        </mat-accordion>
    </mat-expansion-panel>
</div>
