<ng-container>
    <div mat-dialog-content class="dialog-body" ngClass.lt-sm="mobile">
        <h3 class="title align-center color-shuffll">Welcome to SHUFFLL!</h3>
        <h4 class="align-center">
            <span>You are minutes away from creating your first video.</span><br fxHide fxShow.gt-xs /><span>
                Watch our intro to get started! 💪</span
            >
        </h4>
        <vg-player class="welcome-video-player" *ngIf="finalVideoPath">
            <vg-overlay-play vgFor="welcome-video"></vg-overlay-play>

            <video
                #media
                [vgMedia]="media"
                #vgHls="vgHls"
                [vgHls]="finalVideoPath"
                id="welcome-video"
                [poster]="posterPath"
                disablePictureInPicture
                playsinline
                crossorigin="anonymous"
                (play)="onPlayWelcomeVideo()"></video>
        </vg-player>
    </div>
    <div mat-dialog-actions class="actions" ngClass.gt-xs="not-mobile">
        <!-- <button
      mat-flat-button
      class="payment"
      color="primary"
      (click)="next()"
      [featureFlag]="{
        features: ['AB_mustpay_free'],
        featureFlagLogic: 'AND',
        featureFlagMode: 'ngIf',
      }">
      Continue
    </button> -->
        <!-- <button
      mat-flat-button
      mat-dialog-close
      class="payment"
      color="primary"
      [featureFlag]="{
        features: ['AB_mustpay_free'],
        featureFlagLogic: 'AND',
        featureFlagMode: 'ngIf',
        negativeLogic: true,
      }"
      (click)="closeDialog()">
      Continue
    </button> -->
        <button mat-flat-button mat-dialog-close color="primary" (click)="closeDialog()">Continue</button>
    </div>
</ng-container>
<!-- <ng-template #pricing>
  <payment-dialog></payment-dialog>
</ng-template> -->
