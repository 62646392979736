import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/guards/authentication.service';
import { NavigationHelperService } from 'src/app/services/utils/navigation-helper.service';
import { NavListenersService } from '../../../services/nav-listeners.service';
import { ProfileService } from '../../../services/show/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AnalyticsNotifierService } from '../../../services/utils/analytics-notifier.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    @Input()
    showLiveToolbox = false;
    @Input()
    showDestinations = true;

    @Input()
    showGoToDashboard = false;

    @Input()
    colorful = false;

    @Input()
    showRightSide = true;

    constructor(
        private hostService: NavListenersService,
        public authentication: AuthenticationService,
        private navigationService: NavigationHelperService,
        public profile: ProfileService,
        public gtmService: AnalyticsNotifierService,
        public dialog: MatDialog,
        public config: ConfigurationService
    ) {}

    ngOnInit(): void {
        // console.log(this.authentication.getUser())
        // console.log(this.authentication.getUser())
        this.authentication.getUser();
    }

    toggleSideNav() {
        this.hostService.toggleSideNav();
    }

    jumpToHost() {
        this.navigationService.jumpToHost();
    }

    goBack() {
        this.navigationService.goBack();
    }
}
