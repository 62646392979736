<div>
    <mat-selection-list
        class="cards-list"
        #designCategoriesList
        [multiple]="false"
        [(ngModel)]="selectedDesigns"
        (selectionChange)="select($event)"
        *ngIf="suggestedDesigns">
        <mat-list-option
            class="cards-list-item-wrapper"
            *ngFor="let suggestedFesign of suggestedDesigns"
            [value]="suggestedFesign"
            [id]="suggestedFesign._id">
            <ng-container [ngTemplateOutlet]="designCard" [ngTemplateOutletContext]="{ design: suggestedFesign }">
            </ng-container>
        </mat-list-option>

        <div class="private-btn">
            <button mat-flat-button (click)="reveal = !reveal" *ngIf="privateDesigns?.length > 0">
                {{ reveal ? 'Show Less' : 'My Private Designs' }}
                <mat-icon>{{ reveal ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
        </div>
        <div class="generate-designs-btn-wrapper">
            <button
                class="generate-designs-btn"
                mat-stroked-button
                color="primary"
                (click)="generateNewSuggestionDesigns()">
                <mat-icon>auto_awesome</mat-icon>

                SHUFFLL Designs
            </button>
        </div>
        <mat-list-option
            class="cards-list-item-wrapper"
            *ngFor="let privateFesign of privateDesigns"
            [value]="privateFesign"
            [class.hidden]="privateDesigns?.length > 0 && !reveal"
            [hidden]="privateDesigns?.length > 0 && !reveal"
            [id]="privateFesign._id">
            <ng-container [ngTemplateOutlet]="designCard" [ngTemplateOutletContext]="{ design: privateFesign }">
            </ng-container>
        </mat-list-option>
    </mat-selection-list>

    <ng-template #designCard let-design="design" hidden>
        <div class="cards-list-item">
            <div
                class="poster-wrapper"
                (mouseenter)="playAnimation(design.iconLayout._id)"
                (mouseleave)="pauseAnimation(design.iconLayout._id)">
                <dynamic-lottie
                    [config]="{
                        layout: design.iconLayout,
                        dynamicLottieChanges: personalizedDynamics,
                        basePath: '',
                    }"
                    [lottieOptions]="{ autoplay: false }"
                    (animationCreated)="animationCreated($event, design.iconLayout._id)">
                </dynamic-lottie>
            </div>

            <div class="texts-area">
                <h5 class="design-name">{{ design.name }}</h5>
                <h5 class="design-description">{{ design.shortDescription }}</h5>
            </div>
        </div>
    </ng-template>
</div>
