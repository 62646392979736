<div class="dashboard-page padded">
    <div class="discover-main">
        <div class="top-section"></div>
        <h2 class="title">Discover: KPMG</h2>

        <div class="container">
            <h5>From Similar Companies</h5>
            <div class="cards-list">
                <div *ngFor="let video of competitorsVideos" class="cards-list-item-wrapper">
                    <!--          {{video.title}}-->
                    <div class="cards-list-item">
                        <div
                            class="poster-wrapper"
                            [ngStyle]="{ 'background-image': 'url(' + video.thumbnail + ')' }"></div>
                        <div class="card-body">
                            <!--        <img mat-paymentComponent-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">-->
                            <mat-card-header>
                                <!--          <div mat-paymentComponent-avatar class="example-header-image"></div>-->
                                <h4>
                                    <b>{{ video.title }}</b>
                                </h4>
                                <h5>{{ video.date }}</h5>
                            </mat-card-header>

                            <mat-card-actions>
                                <!--            <button mat-button>LIKE</button>-->
                            </mat-card-actions>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <h5>Trending by search volume</h5>
            <mat-chip-listbox aria-label="Trending Search Terms">
                <mat-chip-option>NFT's</mat-chip-option>
                <mat-chip-option>Corporate Restructuring</mat-chip-option>
                <mat-chip-option selected>Investment Strategies</mat-chip-option>
                <mat-chip-option>Debt Consolidation</mat-chip-option>
                <mat-chip-option>Personal Loans</mat-chip-option>
                <mat-chip-option>Financial Planning</mat-chip-option>
                <mat-chip-option>Tax Benefits</mat-chip-option>
                <mat-chip-option>Annual Tax</mat-chip-option>
            </mat-chip-listbox>
        </div>
        <div class="container">
            <h5>Based On Your Previous Videos</h5>
            <div class="cards-list">
                <div *ngFor="let video of previousVideos" class="cards-list-item-wrapper">
                    <!--          {{video.title}}-->
                    <div class="cards-list-item">
                        <div
                            class="poster-wrapper"
                            [ngStyle]="{ 'background-image': 'url(' + video.thumbnail + ')' }"></div>
                        <div class="card-body">
                            <!--        <img mat-paymentComponent-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">-->
                            <mat-card-header>
                                <!--          <div mat-paymentComponent-avatar class="example-header-image"></div>-->
                                <h4>
                                    <b>{{ video.title }}</b>
                                </h4>
                                <h5>{{ video.date }}</h5>
                            </mat-card-header>

                            <mat-card-actions>
                                <!--            <button mat-button>LIKE</button>-->
                            </mat-card-actions>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shuggestions-box">
        <h5>Content Suggestions</h5>
        <mat-progress-spinner *ngIf="suggetions.length === 0" color="primary" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
        <div class="suggestion" *ngFor="let suggestion of suggetions">
            <div class="text">
                {{ suggestion }}
            </div>
            <div class="icons">
                <button mat-icon-button>
                    <mat-icon>favorite_border</mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon>exit_to_app_icon</mat-icon>
                </button>
            </div>
        </div>

        <!--    <h6>-->
        <!--      Saved-->
        <!--    </h6>-->
        <div></div>
    </div>
</div>
