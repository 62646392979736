import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    emailAdress: any;
    vgApi: VgApiService;
    // userEmails = new Form({
    //   primaryEmail: new UntypedFormControl('', [
    //     Validators.required,
    //     Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]
    //   )
    // });

    constructor(private snackBar: MatSnackBar) {}

    openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 2000,
        });
    }

    ngOnInit(): void {}

    sendEmail() {
        // if (this.userEmails.get('primaryEmail').valid) {
        //   this.openSnackBar('Thank you!');
        //   this.emailAdress = '';
        //   console.log(this.userEmails.get('primaryEmail'));
        // }
    }
    onPlayerReady(api: VgApiService) {
        this.vgApi = api;
    }
}
