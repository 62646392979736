import { Pipe, PipeTransform } from '@angular/core';
import { IProject } from '../models/project-model';

@Pipe({
    name: 'thumbnail',
})
export class ThumbnailPipe implements PipeTransform {
    transform(project: IProject, args: string[]): string[] {
        const baseCdnUrl = args[0];

        if (!project || !baseCdnUrl) {
            return [];
        }

        let allThumbs = project.scenes.flatMap((take) => take.takes || []).slice(0, 5); // Assuming you want a maximum of 5 thumbnails

        return allThumbs.map((thumb) => `${baseCdnUrl}${thumb}`);
    }
}
