import { Directive, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appRendered]', // Note: Prefixing selectors with 'app' or a similar namespace is a good practice
})
export class RenderedDirective implements AfterViewInit {
    @Output() rendered: EventEmitter<any> = new EventEmitter();

    ngAfterViewInit() {
        this.rendered.emit(); // Emitting the event right after the view is initialized
    }
}
