import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { VgDashDirective } from '@videogular/ngx-videogular/streaming';
import { ConfigurationService } from '../services/configuration.service';
import { IPathAndDash } from '../models/defines';

@Directive({
    selector: '[dashUrlResolver]',
})
export class dashUrlResolver extends VgDashDirective implements OnInit {
    @Input() videoSource: IPathAndDash;
    @Output() sourceChanged = new EventEmitter<string>();

    constructor(
        private el: ElementRef,
        private api: VgApiService,
        private config: ConfigurationService,
        private cdr: ChangeDetectorRef
    ) {
        super(el, api); // call the parent class constructor
    }

    ngOnInit() {
        console.log('Video element:', this.el.nativeElement);
        console.log('DASH player:', this.dash);
        super.ngOnInit();
    }

    getSource() {
        let dashPath = this.isNotNullAndNotEmpty(this.videoSource.dashPath);
        let uploadPath = this.isNotNullAndNotEmpty(this.videoSource.uploadPath); // Todo: eliminate this
        const src = this.config.baseCdnUrl + (dashPath ?? uploadPath);
        return decodeURIComponent(src);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.videoSource && changes.videoSource.currentValue) {
            this.videoSource = changes.videoSource.currentValue;
            const source = this.getSource();
            this.vgDash = source;
            this.el.nativeElement.src = source;
            this.sourceChanged.emit(source);
        }
    }

    isNotNullAndNotEmpty(str: string | null): string | null {
        if (str && str.trim() !== '') {
            return str;
        } else {
            return null;
        }
    }
}
