export enum TextTypeEnum {
    Word = 'word',
    Space = 'space',
    Symbol = 'symbol',
}

export interface ITextPortion {
    text: string;
    type: TextTypeEnum;
    done: boolean;
    uuid: string;
    index: number;
    events?: string[];
    newLine: boolean;
}
