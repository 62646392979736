import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class VoiceRecognitionSupportedStateService {
    private isSpeechRecognitionSupported: boolean = false;
    private isSpeechGrammarListSupported: boolean = false;

    constructor() {
        this.isSpeechRecognitionSupported = 'webkitSpeechRecognition' in window;
        this.isSpeechGrammarListSupported = 'webkitSpeechGrammarList' in window;
    }

    getSpeechRecognitionSupported() {
        return this.isSpeechRecognitionSupported;
    }

    getSpeechGrammarListSupported() {
        return this.isSpeechGrammarListSupported;
    }
}
