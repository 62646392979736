<h2 mat-dialog-title>{{ title ?? 'Are you sure?' }}</h2>
<div mat-dialog-content>
    {{ message ?? '' }}
</div>
<div mat-dialog-actions>
    <div id="actions">
        <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">Confirm</button>
        <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </div>
</div>
