<div>
    <div>
        <button mat-raised-button (click)="voiceRecognition.start()">Let's go</button>
        <button mat-raised-button (click)="voiceRecognition.stop()">stop</button>
    </div>

    <span
        class="text-span"
        [class.word]="text.type === 'word'"
        [class.symbol]="text.type === 'symbol'"
        [class.space]="text.type === 'space'"
        [class.done]="voiceRecognition.lastFoundWord.value && text.index <= voiceRecognition.lastFoundWord.value.index"
        [id]="text.uuid"
        *ngFor="let text of getText()">
        {{ text.text }}
    </span>

    <!--  <div class="text-span" style="margin-top:40px">{{voiceRecognition.speechText}}</div>-->
</div>
