import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appFileDrop]',
})
export class FileDropDirective {
    @HostBinding('class.fileover') fileOver: boolean = false;
    @Output() fileDropped = new EventEmitter<FileList>();

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    // Drop listener
    @HostListener('drop', ['$event']) ondrop(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;

        const files: FileList = evt.dataTransfer?.files as FileList;
        if (files && files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
