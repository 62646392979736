import { animate, query, sequence, state, style, transition, trigger } from '@angular/animations';

export const Animations = [
    trigger('cues', [
        transition(':enter', [
            query(
                '.slide-in-from-right',
                [
                    style({ transform: 'translateX(100%)', opacity: 0 }),
                    animate('1s ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
                ],
                { optional: true }
            ),
            query(
                '.slide-in-from-left',
                [
                    style({ transform: 'translateX(-100%)', opacity: 0 }),
                    animate('1s ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
                ],
                { optional: true }
            ),
            query(
                '.choose-sides',
                [
                    style({ transform: 'translateY(100%)', opacity: 0 }),
                    animate('1s ease-out', style({ transform: 'translateY(0%)', opacity: 1 })),
                ],
                { optional: true }
            ),
            query(
                '.slide-in-bottom',
                [
                    style({ transform: 'translateY(100%)', opacity: 0 }),
                    animate('1s ease-out', style({ transform: 'translateY(0%)', opacity: 1 })),
                ],
                { optional: true }
            ),
            query('.fade-in', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))], {
                optional: true,
            }),
            query(
                '.wipe-in-left',
                [style({ transform: 'width(0%)' }), animate('1s ease-out', style({ transform: 'width(100%)' }))],
                { optional: true }
            ),
        ]),
        transition(':leave', [
            query(
                '.slide-in-from-right',
                [animate('1s ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))],
                { optional: true }
            ),
            query(
                '.slide-in-from-left',
                [animate('1s ease-out', style({ transform: 'translateX(-100%)', opacity: 0 }))],
                { optional: true }
            ),
            query(
                '.choose-sides',
                [
                    style({ transform: 'translateY(0%)', opacity: 1 }),
                    animate('1s ease-out', style({ transform: 'translateY(100%)', opacity: 0 })),
                ],
                { optional: true }
            ),
            query('.fade-in', [style({ opacity: 1 }), animate('1s ease-out', style({ opacity: 0 }))], {
                optional: true,
            }),
        ]),
    ]),
    trigger('chat-messages', [
        transition(':enter', [
            query(
                '.slide-in-from-right',
                [
                    style({ transform: 'translateX(100%)', opacity: 0 }),
                    animate('.5s ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
                ],
                { optional: true }
            ),
        ]),
        transition(':leave', [
            query(
                '.slide-in-from-right',
                [animate('.5s ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))],
                { optional: true }
            ),
        ]),
    ]),
    trigger('questionInOutAnimation', [
        transition(':enter', [
            style({ transform: 'translateY(100%)', opacity: 0 }),
            animate('1s ease-out', style({ transform: 'translateY(0%)', opacity: 1 })),
        ]),
        transition(':leave', [
            style({ transform: 'translateY(0%)' }),
            animate('1s ease-out', style({ transform: 'translateY(100%)' })),
        ]),
    ]),
    trigger('volumeBarAnimation', [
        transition(':enter', [
            style({ width: '0', opacity: '0' }),
            animate('.7s ease-out', style({ width: '100px', opacity: '1' })),
        ]),
        transition(':leave', [
            style({ width: '100px', opacity: '1' }),
            animate('.7s ease-out', style({ width: '0', opacity: '0' })),
        ]),
    ]),
    trigger('metaAnimation', [
        transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-out', style({ opacity: 1 }))]),
        transition(':leave', [style({ opacity: 1 }), animate('2s ease-out', style({ opacity: 0 }))]),
    ]),
    trigger('inactivity', [
        state('in', style({ opacity: 1 })),
        transition(':leave', [
            style({ height: '*', opacity: '1' }),
            sequence([
                animate('.25s ease', style({ height: '*', opacity: '.2' })),
                animate('.1s ease', style({ height: '0', opacity: 0 })),
            ]),
        ]),
        transition(':enter', [
            style({ height: '0', opacity: '0' }),
            sequence([
                animate('.1s ease', style({ height: '*', opacity: '.2' })),
                animate('.35s ease', style({ height: '*', opacity: 1 })),
            ]),
        ]),
    ]),
];
