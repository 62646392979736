import { Component, Input } from '@angular/core';
import { SflPeerAndSource } from 'src/app/models/defines';
import { ProfileService } from 'src/app/services/show/profile.service';

@Component({
    selector: 'screen-share',
    templateUrl: './screen-share.component.html',
    styleUrls: ['./screen-share.component.scss'],
})
export class ScreenShareComponent {
    @Input('participant')
    participant: SflPeerAndSource = null;
    constructor(public profile: ProfileService) {}
}
