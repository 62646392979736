import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { UserActivityService } from '../user-activity.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class BaseSocketService extends Socket {
    private reconnectInterval: any; // Holds the interval reference
    private reconnectDelay = 5000; // Reconnect attempt every 5 seconds
    private isFirstConnect = true;
    private _isSocketReConnected$ = new Subject<void>();
    public isSocketReConnected$ = this._isSocketReConnected$.asObservable(); // Public Observable for external use

    socketConfigs: SocketIoConfig;

    constructor(
        private userActivityService: UserActivityService,
        @Inject('CONFIG_TOKEN') config: SocketIoConfig
    ) {
        super(config);
        this.socketConfigs = config;
        this.on('connect', () => this.handleConnect());
        this.on('disconnect', () => this.handleDisconnect());

        this.userActivityService.visabilityChange$.subscribe((visibleStatus) => {
            if (!visibleStatus) {
                return;
            }
            this.handleVisibilityChange(visibleStatus);
        });
    }

    /**
     * Handles the socket connection event.
     * Clears reconnection intervals and updates reconnection status.
     */

    private handleConnect(): void {
        console.log('Socket Connected successfully.');
        this.clearReconnectInterval(); // Stop reconnection attempts when connected
        if (this.isFirstConnect) {
            this.isFirstConnect = false;
            return;
        }
        this._isSocketReConnected$.next();
    }

    /**
     * Handles the socket disconnection event.
     * Initiates a reconnection attempt if not already in progress.
     */

    private handleDisconnect(): void {
        if (this.reconnectInterval) {
            return;
        }
        this.startReconnectInterval();
    }

    /**
     * Handles visibility changes of the document/tab.
     * Initiates reconnection if the tab becomes visible and the socket is disconnected.
     * @param visibleStatus - The current visibility status of the document.
     */

    private handleVisibilityChange(visibleStatus: DocumentVisibilityState): void {
        if (visibleStatus !== 'visible') {
            return;
        }
        console.log('Tab is visible. Checking connection...');
        if (!this.ioSocket.connected && !this.reconnectInterval) {
            console.log('Starting socket reconnection interval.');
            this.handleDisconnect();
        }
    }

    /**
     * Starts the interval for reconnection attempts.
     * Tries to reconnect the socket periodically until successful.
     */

    private startReconnectInterval(): void {
        if (this.reconnectInterval) return;
        console.log('Starting reconnect interval...');
        this.reconnectInterval = setInterval(() => {
            console.log('Attempting to reconnect...');
            if (!this.ioSocket.connected) {
                this.connect();
                return; // Exit early if still disconnected
            }
            this.clearReconnectInterval(); // Stop interval if connected
        }, this.reconnectDelay);
    }

    /**
     * Clears the reconnection interval.
     * Stops reconnection attempts and releases resources.
     */

    private clearReconnectInterval(): void {
        if (!this.reconnectInterval) return;
        clearInterval(this.reconnectInterval);
        this.reconnectInterval = null;
        console.log('Reconnection interval cleared.');
    }
}
