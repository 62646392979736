import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

/**
 * The label for the snackbar action.
 */
export enum SnackbarActionEnum {
    Close = 'Close',
    AWESOME = 'Awesome!',
    Undo = 'Undo',
    Retry = 'Retry',
    Dismiss = 'Dismiss',
    Feedback = 'Feedback',
    Reload = 'Reload',
}

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {}

    /**
     *
     * @param message To display
     * @param action Check out the enum to know better .
     * @param duration duration in milliseconds, like 5000 (5 seconds). if argument is null, the default is 5 seconds.
     */
    public openMessage(
        message: string,
        action: SnackbarActionEnum,
        duration: number,
        extraParams: MatSnackBarConfig = {}
    ) {
        if (!message) {
            console.error(`Can't display snackbar because message is null.`);
            return;
        }
        return this.snackBar.open(message, action, {
            duration: duration ?? 5000,
            ...extraParams,
        });
    }
}
