import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../configuration.service';
import { FeatureGroupEnum, User } from '../../../models/user';
import { BehaviorSubject } from 'rxjs';
import { ILaunchFormQuestionConfigs, IOnBoardingQuestionConfigs } from 'src/app/models/defines';
import { IProjectInDTO } from '../../../models/project-model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    INVITE_KEY = 'InviteID';
    VP_KEY = 'vp';
    VP_HUBSPOT_KEY = 'marketing_value_proposition';
    googleApiEndpoint = '';
    path: string = '/auth/user';
    private userChangedBehaviorSubject = new BehaviorSubject<any>(null);
    userChangedObservable = this.userChangedBehaviorSubject.asObservable();
    private saveResponseBehaviourSubject = new BehaviorSubject<any>('');
    messageChangedObservable = this.saveResponseBehaviourSubject.asObservable();
    isIOS: boolean;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {
        this.googleApiEndpoint = config.googleFunction + '/update-db-and-convertkit';
        this.path = this.config.apiIP + this.path;
        this.isIOS = this.isUserFromIOS();
    }

    // Todo: save path in a variable and one location only -> /auth/user
    me(): Promise<User> {
        return this.http.post<User>(this.path + '/me', '').toPromise();
    }

    public registerToDBAndConvertkit(invite: string, email: string, name: string) {
        this.http.post<any>(this.googleApiEndpoint, { invite, email, name }).subscribe((reuslt) => {
            console.log('googleApiEndpoint', reuslt);
        });
    }

    finishOnboarding(onboardingResults: IOnBoardingQuestionConfigs[]) {
        return this.http.post<User>(
            this.path + '/onboarding',
            {
                onboardingResults: onboardingResults,
            },
            { withCredentials: true }
        );
    }

    finishLaunchForm(launchFormResults: ILaunchFormQuestionConfigs[], projectId: string) {
        return this.http.post<IProjectInDTO>(this.path + '/launchResults', {
            launchFormResults,
            projectId,
        });
    }

    async updateDBandConvertkit(email: string, name: string, invite: string = null) {
        if (invite === null) {
            invite = localStorage.getItem(this.INVITE_KEY);
        }
        // Hopfully we got it from the localstorage if not provided before
        if (invite !== null) {
            this.registerToDBAndConvertkit(invite, email, name);
        }
    }

    isMobileUser(): boolean {
        let isMobile;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            isMobile = true;
        } else {
            isMobile = false;
        }
        return isMobile;
    }

    isUserFromIOS() {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }
}
