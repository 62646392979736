export type FileCategory = 'image' | 'video' | 'audio' | 'document' | 'archive' | 'unknown';
type ImageSubType = 'jpeg' | 'png' | 'gif' | 'bmp' | 'svg+xml' | 'webp' | 'tiff' | 'unknown';
type VideoSubType = 'mp4' | 'mpeg' | 'ogg' | 'webm' | 'x-msvideo' | 'unknown';
type AudioSubType = 'mpeg' | 'wav' | 'ogg' | 'webm' | 'aac' | 'unknown';
type DocumentSubType = 'pdf' | 'json' | 'xml' | 'csv' | 'plain' | 'html' | 'css' | 'javascript' | 'unknown';
type ArchiveSubType = 'zip' | 'x-7z-compressed' | 'x-rar-compressed' | 'unknown';
export type FileSubType = ImageSubType | VideoSubType | AudioSubType | DocumentSubType | ArchiveSubType;

export interface IFileMetadata {
    name: string;
    category: FileCategory;
    subType: FileSubType;
}

export interface IUploadedFileMetadata extends IFileMetadata {
    uploadedUrl: string;
}

export function getFileCategoryAndSubType(type: string): {
    category: FileCategory;
    subType: FileSubType;
} {
    const [mainType, subType] = type.split('/');

    switch (mainType) {
        case 'image':
            return {
                category: 'image',
                subType: ['jpeg', 'png', 'gif', 'bmp', 'svg+xml', 'webp', 'tiff'].includes(subType)
                    ? (subType as ImageSubType)
                    : 'unknown',
            };
        case 'video':
            return {
                category: 'video',
                subType: ['mp4', 'mpeg', 'ogg', 'webm', 'x-msvideo'].includes(subType)
                    ? (subType as VideoSubType)
                    : 'unknown',
            };
        case 'audio':
            return {
                category: 'audio',
                subType: ['mpeg', 'wav', 'ogg', 'webm', 'aac'].includes(subType)
                    ? (subType as AudioSubType)
                    : 'unknown',
            };
        case 'application':
            if (['pdf', 'json', 'xml', 'csv'].includes(subType)) {
                return {
                    category: 'document',
                    subType: subType as DocumentSubType,
                };
            }
            if (['zip', 'x-7z-compressed', 'x-rar-compressed'].includes(subType)) {
                return {
                    category: 'archive',
                    subType: subType as ArchiveSubType,
                };
            }
            break;
        case 'text':
            if (['plain', 'html', 'css', 'javascript'].includes(subType)) {
                return {
                    category: 'document',
                    subType: subType as DocumentSubType,
                };
            }
            break;
    }

    return { category: 'unknown', subType: 'unknown' };
}
