<ng-container [ngSwitch]="fileMimetype" *ngIf="this.fileSrc">
    <div
        *fewSwitchCases="[MimetypeEnum.PNG, MimetypeEnum.JPG, MimetypeEnum.JPEG]"
        [style.background-image]="'url(' + this.baseCdnUrl + fileSrc + ')'"
        class="asset-file"></div>

    <div *ngSwitchCase="MimetypeEnum.VIDEO">
        <!-- <video controls class="video-file" preload="true" >
      <source [src]="fullFileUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video> -->

        <vg-player (onPlayerReady)="onPlayerReady($event)">
            <video
                [vgDash]="this.baseCdnUrl + fileSrc"
                #vgDash="vgDash"
                controls
                class="video-file"
                preload="metadata"
                #media
                [vgMedia]="media"
                id="singleVideo"
                crossorigin>
                Your browser does not support the video tag.
            </video>
        </vg-player>
    </div>
    <div *ngSwitchCase="MimetypeEnum.SVG">
        <img [src]="this.baseCdnUrl + fileSrc" width="100" height="100" />
    </div>
</ng-container>
