import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectAuthApiService } from '../../../../services/api/auth/project-auth-api.service';
import { ProjectStatusEnum } from '../../../../models/defines';
import { ProjectGeneralRouterService } from '../../../../services/project/routes/project-general-router.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { SnackbarActionEnum, SnackBarService } from '../../../../services/utils/snack-bar.service';
import { Subject, takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-launch-welcome',
    templateUrl: './launch-welcome.component.html',
    styleUrl: './launch-welcome.component.scss',
})
export class LaunchWelcomeComponent implements OnInit, OnDestroy {
    constructor(
        private projectApiService: ProjectAuthApiService,
        private projectGeneralRouting: ProjectGeneralRouterService,
        private snackBarService: SnackBarService,
        private config: ConfigurationService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    projectId: string;
    projectCode: number;
    baseCdnUrl: string;
    isLoading = false;
    onDestroy$ = new Subject<boolean>();
    ngOnInit(): void {}

    navigateToProject() {
        this.isLoading = true;
        this.projectApiService
            .getProjectByCode$(this.projectCode)
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe({
                next: (project) => {
                    this.projectGeneralRouting.goToAsync(ProjectStatusEnum.RECORDING, project);
                },
                error: (error) => {
                    this.snackBarService.openMessage('Project not found! Please try again', null, 5000, {
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center',
                        panelClass: ['custom-snackbar'],
                    });
                },
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
