import { Component, Input } from '@angular/core';
import { NavListenersService } from '../../../services/nav-listeners.service';

@Component({
    selector: 'app-sidenav-toggle',
    templateUrl: './sidenav-toggle.component.html',
    styleUrls: ['./sidenav-toggle.component.scss'],
})
export class SidenavToggleComponent {
    @Input('title') title: string;

    constructor(private navListenersService: NavListenersService) {}

    toggleMenu() {
        this.navListenersService.toggleSideNav();
    }
}
