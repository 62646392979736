import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserActivityService {
    private _visibilityChange$ = new Subject<DocumentVisibilityState>();
    private _focusChange$ = new Subject<'focus' | 'blur'>();

    public visabilityChange$ = this._visibilityChange$.asObservable();
    public focusChange$ = this._focusChange$.asObservable();

    constructor(private ngZone: NgZone) {
        this.listenToVisibilityChange();
        this.listenToFocusChange();
    }

    private listenToVisibilityChange(): void {
        document.addEventListener('visibilitychange', () => {
            this.ngZone.run(() => {
                const visibilityState = document.visibilityState;
                this._visibilityChange$.next(visibilityState);
            });
        });
    }

    private listenToFocusChange(): void {
        window.addEventListener('focus', () => {
            this.ngZone.run(() => {
                this._focusChange$.next('focus');
            });
        });

        window.addEventListener('blur', () => {
            this.ngZone.run(() => {
                this._focusChange$.next('blur');
            });
        });
    }
}
