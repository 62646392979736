import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) return '00:00';

        // Convert milliseconds to total seconds
        let totalSeconds = Math.floor(value / 1000);

        // Calculate minutes and seconds
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        // Format minutes and seconds to 'mm:ss'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        const secondsStr = seconds < 10 ? '0' + seconds : seconds;

        return `${minutesStr}:${secondsStr}`;
    }
}
