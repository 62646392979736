import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/dialog-wrapper.model';
import { PostProductionAuthApiService } from 'src/app/services/api/auth/post-production-auth-api.service';

export enum exportDialogStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    CANCELED = 'CANCELED',
}

@Component({
    selector: 'app-export-dialog',
    templateUrl: './export-dialog.component.html',
    styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
    message = null;
    title = null;
    exportJob = null;
    loading: boolean = false;
    requestEdit: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private postProductionApi: PostProductionAuthApiService,
        public dialogRef: MatDialogRef<ExportDialogComponent> // Inject MatDialogRef
    ) {
        // let data = data as unknown as {new: true, oldName: null};
        this.message = data['message'];
        this.title = data['title'];
        this.exportJob = data['exportJob'];
        this.requestEdit = data['requestEdit'];
    }
}
