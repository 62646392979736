import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavListenersService {
    isSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor() {}

    public toggleSideNav() {
        this.isSideNavOpen.next(!this.isSideNavOpen.value);
    }

    public openSideNav() {
        this.isSideNavOpen.next(true);
    }
}
