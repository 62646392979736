import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-right-pane',
    templateUrl: './right-pane.component.html',
    styleUrls: ['./right-pane.component.scss'],
})
export class RightPaneComponent implements OnInit {
    onPlayerReady($event: any) {
        throw new Error('Method not implemented.');
    }
    @Input() moments;
    @Input() videoSrc;
    @Input() requestsToJoin;
    @Output() allowToJoinEmmiter = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {}

    allowJoin(requestToJoin: any) {
        // console.log(requestToJoin.socketId);
        this.allowToJoinEmmiter.emit(requestToJoin.socketId);
    }
}
