import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { fadeInDownOnEnterAnimation, fadeInOnEnterAnimation } from 'angular-animations';

@Component({
    selector: 'backstage',
    templateUrl: './backstage.component.html',
    styleUrls: ['./backstage.component.scss'],
    animations: [fadeInDownOnEnterAnimation({ duration: 500 }), fadeInOnEnterAnimation({ duration: 250 })],
})
export class BackstageComponent implements OnInit {
    @Input('expanded') expanded = false;
    @Input('rowOrientation') rowOrientation = false;

    ngOnInit(): void {}

    // participantCast: SflPeerAndSource[] = [];
    // currentBitVideosPeers: SflVideo[] = [];
    // ROLE = ROLE;
    //
    // filteredRequests: SflPeer[] = []; // Those that are not in the call
    // userRole = ROLE;
    // currentSceneAndIndex$: Observable<{ scene: Scene; currentIndex?: number }>;
    // sflMediaSource = SflMediaSource;
    // baseAssetPath: string;
    // autoVideoAction: Action = null;
    // isVideoNeedsToBeAutoPlayed: boolean = false;
    //
    // constructor(
    //   private config: ConfigurationService,
    //   public showStageManagerService: ShowStageManagerService,
    //   public castingDirector: CastingDirector,
    //   private _snackBar: MatSnackBar,
    //   public dialog: MatDialog,
    //   public profileService: ProfileService,
    //   public formatManager: FormatManagerService,
    //   private peerService: PeerService,
    //   private cdr: ChangeDetectorRef,
    //   private eventBus: EventbusService,
    //   private actionControlNotifier: ActionsControlNotifierService
    // ) {
    //   this.currentSceneAndIndex$ =
    //     formatManager.notifySceneToComponents.asObservable();
    //
    //   this.currentSceneAndIndex$.subscribe((newScene) => {
    //     this.showStageManagerService.changeEveryoneLoadedTheVideoState(false);
    //   });
    //   /// thats apply also to when dynamic text is being saved (scene setup)
    //   this.eventBus.show$.subscribe((event: IEventType) => {
    //     if (event.type === EventType.bit_started_loading) {
    //       this.showStageManagerService.changeEveryoneLoadedTheVideoState(false);
    //     }
    //   });
    //
    //   this.formatManager.notifyDirectorCommand.subscribe((directCommand) => {
    //     if (directCommand === DirectorCommand.RESTART_CURRENT) {
    //       this.insertAutoVideoIfExisted();
    //     }
    //     // this.showStageManagerService.changeEveryoneLoadedTheVideoState(false);
    //   });
    //
    //   this.eventBus.syncStages$.asObservable().subscribe((event: IEventType) => {
    //     console.log('got new sync stage for videos', event);
    //     const { videoId } = event.data;
    //     const sflVideo = this.currentBitVideosPeers.find(
    //       (video) => video.peer.id === videoId
    //     );
    //
    //     if (!sflVideo) return;
    //     console.log('backstage current event on video: ', sflVideo);
    //
    //     console.log('what is the event type', event.type);
    //     if (event.type === EventType.video_playable) {
    //       if (sflVideo.canBePlayed === true) {
    //         return; /** in case someone leaves in the middle of a video, its already true */
    //       }
    //       /** auto play when loading the video and he is ready to be played ! */
    //       sflVideo.canBePlayed = true;
    //       if (sflVideo.stageOptions.hardPosition >= 0) {
    //         console.log(
    //           'video element ready to be played in backstage',
    //           sflVideo
    //         );
    //         this.currentSceneAndIndex$.subscribe((sceneAndIndex) => {
    //           if (!sceneAndIndex) return;
    //           console.log('got bit moving, wanting to play video', sceneAndIndex);
    //           const scene = sceneAndIndex.scene;
    //           const bitActions = scene?.actions?.filter(
    //             (action) => action.type === ActionType.SceneVideos
    //           );
    //           const action = bitActions?.find(
    //             (action) => action.setup.videoAsset._id === sflVideo.peer.id
    //           );
    //           if (action) {
    //             this.autoVideoAction = action;
    //             /// first time that the auto play video is ready to be played by everyone
    //             /// By doing that, the scene component will sync the video with the lottie
    //             /// And will play the video by himself when both are ready
    //             if (
    //               this.isVideoNeedsToBeAutoPlayed &&
    //               !this.showStageManagerService.didEveryoneLoadedTheVideo
    //             ) {
    //               this.showStageManagerService.changeEveryoneLoadedTheVideoState(
    //                 true
    //               );
    //               this.actionControlNotifier.notifyControlAction(
    //                 action,
    //                 ActionControl.PLAY
    //               );
    //             }
    //             /// every other time(that is not the autoplay video)
    //             /// of a video that is being ready to be played by everyone
    //             //else {
    //           }
    //           //  }
    //         });
    //       }
    //     } else if (event.type === EventType.video_notPlayable) {
    //       if (sflVideo.canBePlayed === false) {
    //         return;
    //       }
    //       console.log(
    //         'video element is not  ready to be played in backstage',
    //         sflVideo
    //       );
    //       sflVideo.canBePlayed = false;
    //     }
    //     this.currentBitVideosPeers.slice(0, 0);
    //     this.cdr.detectChanges();
    //   });
    //
    //   showStageManagerService.cast.onChange.subscribe((newCast) => {
    //     if (newCast) {
    //       console.log('new cast in backstage', newCast);
    //       this.participantCast = newCast.filter((participant) => {
    //         return (
    //           participant.peer.role === ROLE.AUDIENCE ||
    //           participant.peer.role === ROLE.HOST ||
    //           participant.peer.role === ROLE.PARTICIPANT
    //         );
    //       });
    //
    //       this.filterBackstageRequests();
    //       // this.showStageManager.organize();
    //     }
    //     castingDirector.requestsToJoin.subscribe((requests) => {
    //       this.filterBackstageRequests();
    //     });
    //   });
    // }
    //
    // activeAudoplay($event: Action) {
    //   console.log('starting auto play', $event);
    //   this.actionControlNotifier.notifyControlAction($event, ActionControl.PLAY);
    // }
    //
    // /***
    //  * set the requests to only these that are not in the call
    //  */
    // filterBackstageRequests() {
    //   if (this.castingDirector.requestsToJoin.value) {
    //     this.filteredRequests = this.castingDirector.requestsToJoin
    //       .getValue()
    //       .filter((x) => {
    //         return (
    //           x !== null &&
    //           !this.participantCast.some((y) => {
    //             return x.id === y.id;
    //           })
    //         );
    //       });
    //   }
    // }
    //
    // showShareLinksDialog() {
    //   const shareLinksDialog = this.dialog.open(ShareLinksComponent, {
    //     disableClose: false,
    //     panelClass: 'padding-modal',
    //   });
    // }
    //
    // async startCapture() {
    //   await this.peerService.startScreenShare();
    // }
    //
    // moveToPosition(participant, spotIndex) {
    //   let currentlyOccupiedBy =
    //     this.showStageManagerService.participantsOnSetSubject.value[spotIndex];
    //   if (currentlyOccupiedBy) {
    //     let dialog = this.dialog.open(ReplaceBackstagePositionComponent, {
    //       data: { currentlyOccupiedBy, spotIndex },
    //       disableClose: true,
    //       minWidth: '300px',
    //       panelClass: 'padding-modal',
    //     });
    //
    //     dialog.afterClosed().subscribe((replaceApproved) => {
    //       if (replaceApproved) {
    //         console.log('Moving to ', { participant, spotIndex });
    //         this.showStageManagerService.moveToPositionByHost(
    //           participant,
    //           spotIndex
    //         );
    //       }
    //     });
    //     /// Show are you sure
    //     //    this.showStageManagerService.moveToPosition(participant,spot,  i)
    //   } else {
    //     console.log('Noving to ', { participant, spotIndex });
    //     this.showStageManagerService.moveToPositionByHost(participant, spotIndex);
    //   }
    // }
    //
    // participantTrackBy(index: number) {
    //   return index;
    // }
    //
    // getFirstParticipants(count: number) {
    //   return this.showStageManagerService.participantsOnSetSubject.value.slice(
    //     0,
    //     count
    //   );
    // }
    //
    // addToBackstage(requestToJoin: SflPeer) {
    //   this._snackBar.open(
    //     `👍 ${requestToJoin.displayName} will join when ready`,
    //     null,
    //     { duration: 3000 }
    //   );
    //   requestToJoin.approvedByHost = true;
    //   this.castingDirector.respondToJoinReuqets(requestToJoin.id, true);
    // }
    //
    // ngOnInit(): void {
    //   const castChanged$ = this.showStageManagerService.cast.onChange;
    //   const newLayout$ = this.showStageManagerService.compositionChanged;
    //
    //   const combineSubjects = combineLatest([castChanged$, newLayout$]);
    //   combineSubjects
    //     .pipe(
    //       map(([castChanged, newLayout]) => {
    //         if (!castChanged || !newLayout) return;
    //
    //         this.currentBitVideosPeers = castChanged.filter((participant) => {
    //           return participant.peer.role === ROLE.VIDEO;
    //         }) as SflVideo[];
    //         if (this.currentBitVideosPeers.length === 0) {
    //           this.disableAutoplayVideoState();
    //         } else {
    //           this.currentBitVideosPeers.forEach(
    //             (videoPeer) => (videoPeer.canBePlayed = false)
    //           );
    //           this.insertAutoVideoIfExisted();
    //         }
    //       })
    //     )
    //     .subscribe();
    // }
    //
    // public openSetupDevicesDialog(): void {
    //   const dialogData: DialogData = {
    //     component: UserMediaSelectorComponent,
    //     title: 'setup devices dialog!!!',
    //   };
    //
    //   this.dialog.open(DialogWrapperComponent, {
    //     data: {
    //       ...dialogData,
    //     },
    //     width: '756px',
    //   });
    // }
    //
    // private insertAutoVideoIfExisted() {
    //   if (this.currentBitVideosPeers?.length > 0) {
    //     console.log('current bit videos peers', this.currentBitVideosPeers);
    //     const videoToAutoPlay = this.currentBitVideosPeers.find(
    //       (bitVideoPeer) => {
    //         if (bitVideoPeer.peer.videoAndAutoplay.autoPlay) return true;
    //       }
    //     );
    //     if (videoToAutoPlay) {
    //       this.isVideoNeedsToBeAutoPlayed = true;
    //       this.showStageManagerService.changeAutoVideoState(true);
    //       this.showStageManagerService.moveToPositionByHost(videoToAutoPlay, 0);
    //     } else {
    //       this.disableAutoplayVideoState();
    //     }
    //   }
    // }
    //
    // private disableAutoplayVideoState() {
    //   this.isVideoNeedsToBeAutoPlayed = false;
    //   this.showStageManagerService.changeAutoVideoState(false);
    // }
}
