import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Inject,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/dialog-wrapper.model';
import { UserMediaSelectorComponent } from '../../minor/user-media-selector/user-media-selector.component';

@Component({
    selector: 'app-dialog-wrapper',
    templateUrl: './dialog-wrapper.component.html',
    styleUrls: ['./dialog-wrapper.component.scss'],
})
export class DialogWrapperComponent implements AfterViewInit {
    @ViewChild('dialogContainer', { read: ViewContainerRef })
    dialogContainer: ViewContainerRef;
    componentToOpen: string = '';
    componentInstance: UserMediaSelectorComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private dialogRef: MatDialogRef<DialogWrapperComponent>,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        if (this.data && this.data.component) {
            const componentRef: ComponentRef<UserMediaSelectorComponent> = this.dialogContainer.createComponent(
                this.data.component
            );
            this.componentInstance = componentRef.instance;
            this.changeDetectorRef.detectChanges();
        }
    }

    public async save() {
        this.closeDialog();
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
