import { Injectable } from '@angular/core';
import { makeRandomString, RequestMethod, SerializedPeer, SflMessage } from '../../models/defines';
import { PeerService } from './peer.service';
import { SignalingService } from './signaling.service';
import { EventbusService, EventType, IEventType } from './eventbus.service';
import { ProfileService } from './profile.service';
import { LoggerService } from '../logger.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    public messages: SflMessage[] = [];
    public onNewMessage = new BehaviorSubject<SflMessage>(null);
    public toPeer = 'all';

    constructor(
        private peer: PeerService,
        private signaling: SignalingService,
        private eventbus: EventbusService,
        private profile: ProfileService,
        private logger: LoggerService
    ) {
        this.eventbus.chat$.subscribe((event: IEventType) => {
            // console.log('Got chat message1', event)

            if (event.type === EventType.chat_message) {
                // console.log('Got chat message2', event)

                const { from, to } = event.data;
                if (to !== 'all' && to !== this.profile.userPeer.id) {
                    return;
                }

                let chatMessage: SflMessage = event.data.chatMessage;
                if (!chatMessage) {
                    return;
                }

                // TODO: Unify both
                // TODO: this.peer.getPeerInfo(from) , we might just use the inner peer object inside the message itself
                /// Inner messages
                if (chatMessage.platform === 'Shuffll') {
                    const peerInfo: SerializedPeer = this.peer.getPeerInfo(from).getSerializedPeer();

                    const innerMessage = new SflMessage(
                        makeRandomString(8),
                        peerInfo,
                        'partner',
                        chatMessage.message,
                        new Date(),
                        'ok'
                    );

                    this.pushReceivedMessage(innerMessage);
                } else if (chatMessage.platform === 'Youtube') {
                    /// Check that same id doesnt exist
                    if (this.messages && !this.messages.some((x) => x.id === chatMessage.id)) {
                        // Messages sent through Shuffll will echo back from YT
                        /// We will scan the last 5 messages from the host (Made through shuffll) To make sure they are not an echo
                        const lastMessages = this.messages
                            .filter((x) => x.who === 'me' && x.platform === 'Shuffll')
                            .slice(Math.max(this.messages.length - 5, 0));
                        if (!lastMessages.some((x) => x.message === chatMessage.message)) {
                            // Simply use the object we get from the server
                            this.pushReceivedMessage(chatMessage);
                        }
                    }
                }
            }
        });
    }

    pushReceivedMessage(message: SflMessage) {
        this.messages = [...this.messages, message];
        this.onNewMessage.next(message);
    }

    send(chatText: string) {
        const chatMessage = new SflMessage(
            makeRandomString(8),
            this.profile.userPeer.getSerializedPeer(),
            'me',
            chatText,
            new Date(),
            'pending'
        );

        this.pushReceivedMessage(chatMessage);

        this.signaling
            .sendRequest(RequestMethod.chatMessage, {
                chatMessage,
                from: this.profile.userPeer.id,
                to: this.toPeer,
            })
            .then(() => {
                chatMessage.sendStatus = 'ok';
            })
            .catch(() => {
                chatMessage.sendStatus = 'failed';
            });
    }
}
