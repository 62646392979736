// src/app/services/socket-event-wrapper.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseSocketService } from './base-socket.service';
@Injectable()
export class SocketEventWrapperService<ClientToServerEvents, ServerToClientEvents> {
    public isSocketReConnectedInWrapper$ = this.socket.isSocketReConnected$;

    constructor(private socket: BaseSocketService) {}

    emit<K extends keyof ClientToServerEvents>(event: K, payload: ClientToServerEvents[K]) {
        this.socket.emit(event as string, payload); // Emit event via the correct socket service
    }

    fromEvent<K extends keyof ServerToClientEvents>(event: K): Observable<ServerToClientEvents[K]> {
        return this.socket.fromEvent<ServerToClientEvents[K]>(event as string); // Listen via the correct socket service
    }
    //// TODO: here get the base-socket service next. and notify to project main events
}
