import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../../services/guards/authentication.service';
import { PrivateMessageData } from '../chat.component';

export interface ChatInputData {
    minWidth?: boolean;
    enableVideoComments?: boolean;
    fullHeight?: boolean;
}

@Component({
    selector: 'app-chat-input',
    templateUrl: './chat-input.component.html',
    styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent implements OnInit {
    @Input()
    messageToSend: string;

    @Input()
    privateMessageData: PrivateMessageData;

    @Output()
    sendMessage: EventEmitter<string> = new EventEmitter();

    @Output()
    privateMessageRemoveNotify: EventEmitter<any> = new EventEmitter();

    @Input()
    chatComponent;

    @Input()
    options: ChatInputData;

    videoMode = false;

    constructor(public authentication: AuthenticationService) {
        if (this.chatComponent) {
            console.log('chatComponent', this.chatComponent);
        }

        this.options = { enableVideoComments: false, minWidth: false, fullHeight: false, ...this.options };
    }

    ngOnInit(): void {}

    goToLogin() {
        this.authentication.goToLogin();
    }

    send() {
        this.sendMessage.emit(this.messageToSend);
        this.messageToSend = '';
    }

    removePrivateMessage() {
        this.privateMessageData = null;
        this.privateMessageRemoveNotify.emit();
    }
}
