import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { injectStripe, StripeCardNumberComponent, StripeExpressCheckoutComponent, StripeService } from 'ngx-stripe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAppliedCoupon, ICouponResponse, PlansApiService } from 'src/app/services/api/plans-api.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import {
    StripeCardElementOptions,
    StripeElementsOptions,
    StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js';
import { ConfigurationService } from '../../../../services/configuration.service';
import Stripe from 'stripe';
import { AuthenticationService } from 'src/app/services/guards/authentication.service';
import { firstValueFrom } from 'rxjs';
import { SnackbarActionEnum, SnackBarService } from '../../../../services/utils/snack-bar.service';
import { AnalyticsNotifierService, KEY_EVENT_TYPE } from 'src/app/services/utils/analytics-notifier.service';
import { WELCOME_DIALOG_SHOWN_KEY_DRAFTTTTTTT } from 'src/app/services/guards/welcome.guard';
import { PlansService } from 'src/app/services/plans.service';
import { FeatureService } from '../../../../services/feature-service.service';
import { HttpErrorResponse } from '@angular/common/http';

export enum StripeErrorEnum {
    INCORRECT_CVC = 'incorrect_cvc',
    EXPIRED_CARD = 'expired_card',
    CARD_DECLINED = 'card_declined',
    PAYMENT_FAILED = 'payment_failed',
    REQUIRES_ACTION = 'requires_action',
    NOT_FOUND = 'not-found',
    INVALID_PROMO_CODE = 'invalid_promo_code',
}

type StripeErrorCode =
    | StripeErrorEnum.INCORRECT_CVC
    | StripeErrorEnum.EXPIRED_CARD
    | StripeErrorEnum.CARD_DECLINED
    | StripeErrorEnum.PAYMENT_FAILED
    | StripeErrorEnum.REQUIRES_ACTION
    | StripeErrorEnum.NOT_FOUND
    | StripeErrorEnum.INVALID_PROMO_CODE;

export interface IStripeError {
    code: StripeErrorCode;
    message: string;
}

@Component({
    selector: 'payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit, OnChanges {
    @ViewChild(StripeExpressCheckoutComponent)
    expressCheckout!: StripeExpressCheckoutComponent;
    @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
    @Input({ alias: 'price', required: true }) price: Stripe.Price;
    @Input({ alias: 'product', required: true }) product: Stripe.Product;
    @Input({ alias: 'couponResponse', required: true })
    couponResponse: ICouponResponse;
    @Input({ alias: 'finishedLoadingCoupon' })
    finishedLoadingCoupon: void;

    isLoadingCoupon = false;

    @Output() paymentSuccess = new EventEmitter<boolean>();
    processing = false;
    clientSecret: string = null;
    setupIntentId = null;
    subscriptionId: string = null;
    paymentForm: FormGroup;
    selectedPaymentMethod: string = 'card';
    stripe = null;

    elementsOptions: StripeElementsOptions = null;
    expressCheckoutElementOptions: StripeExpressCheckoutElementOptions = {
        buttonType: {
            applePay: 'buy',
            googlePay: 'buy',
        },
        layout: {
            maxColumns: 3,
            maxRows: 1,
        },
    };
    cardOptions: StripeCardElementOptions = {
        style: {
            empty: {
                iconColor: '#666EFE8',
                color: '#ffffff',
                fontWeight: '300',
            },
            base: {
                iconColor: '#666EE8',
                color: '#ffffff',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '16px',
                lineHeight: '24px',
                '::placeholder': {
                    color: '#8c8c8c',
                },
                ':-webkit-autofill': {
                    color: '#ffffff',
                },
            },
            complete: {
                backgroundColor: 'transparent',
            },
            invalid: {
                iconColor: '#E83151',
                color: '#E83151',
            },
        },
        iconStyle: 'solid',
    };

    constructor(
        private fb: FormBuilder,
        private stripeService: StripeService,
        private plansApiService$: PlansApiService,
        private plansService: PlansService,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<PaymentFormComponent>,
        private profileService: ProfileService,
        private config: ConfigurationService,
        private snackBarService: SnackBarService,
        private authenticationService: AuthenticationService,
        private analyticsNotfier: AnalyticsNotifierService,
        private featureService: FeatureService,
        @Inject(MAT_DIALOG_DATA) public data: { subscriptionFrom: string }
    ) {
        this.stripe = injectStripe(this.config.stripe.publishableKey);
        this.stripeService.stripe = this.stripe;

        this.paymentForm = this.fb.group({
            name: [this.profileService.user$.value?.name ?? '', [Validators.required]],
            promoCode: [this.couponResponse?.appliedCoupon?.promoCode ?? '', [Validators.max(40)]],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.finishedLoadingCoupon) {
            this.isLoadingCoupon = false;
        }
    }

    ngOnInit(): void {
        // this.product = this.data?.product;
        // this.price = this.data?.price;
        // console.log({ product: this.product, price: this.price });

        this.route.queryParams.subscribe((params) => {
            this.product = params['productId'] ?? this.product;
        });
        this.createPaymentIntent();
    }

    /***
     * Create payment intent for stripe.
     */
    createPaymentIntent(): void {
        const name = this.paymentForm.get('name').value;
        // console.log({ product: this.product.id, price: this.price.id });
        this.plansApiService$
            .createSetupIntent({
                productId: this.product.id,
                priceId: this.price.id,
                name: name,
            })
            .subscribe((response: any) => {
                if (response.clientSecret) {
                    // console.log(response);
                    // Save client secret and payment intent id
                    this.clientSecret = response.clientSecret;
                    this.setupIntentId = response.setupIntentId;

                    this.elementsOptions = {
                        clientSecret: this.clientSecret,
                        appearance: {
                            theme: 'stripe',
                            variables: {
                                iconColor: '#666EE8',
                                iconLoadingIndicatorColor: '#666EE8',
                            },
                        },
                        locale: 'auto',
                    };
                } else if (response.error) {
                    console.error(response.error.message);
                }
            });
    }

    paymentButtonClick(): void {
        try {
            this.processing = true;

            if (this.paymentForm.valid) {
                this.stripeService
                    .confirmCardSetup(this.clientSecret, {
                        payment_method: {
                            card: this.card.element,
                            billing_details: {
                                name: this.paymentForm.get('name').value,
                            },
                        },
                    })
                    .subscribe(
                        async (result) => {
                            console.log(`New result from confirm card setup`, result.setupIntent, result.error);
                            let setupIntent = result.setupIntent;
                            let counter = 0;
                            while (setupIntent?.status === 'processing' && counter < 5) {
                                await new Promise((resolve) => setTimeout(resolve, 3000));
                                try {
                                    const data = await firstValueFrom(
                                        this.stripeService.retrieveSetupIntent(this.clientSecret)
                                    );
                                    setupIntent = data?.setupIntent;
                                    counter++;
                                } catch (error) {
                                    setupIntent = null;
                                    console.error(`An error occurred retrieving setup inter. Error`, error);
                                }
                            }
                            if (setupIntent?.status === 'succeeded') {
                                const confiramtion = await this.createSubscription(this.data.subscriptionFrom);
                                if (!confiramtion) {
                                    localStorage.setItem(WELCOME_DIALOG_SHOWN_KEY_DRAFTTTTTTT, 'false');
                                    return;
                                }
                                const priceInUSD = (this.price?.unit_amount ?? 0) / 100;

                                this.showPaymentSuccess();

                                this.paymentSuccess.emit(true);
                                localStorage.setItem(WELCOME_DIALOG_SHOWN_KEY_DRAFTTTTTTT, 'true');
                                // Successful payment
                                this.plansService.paymentDone(confiramtion);
                            } else {
                                console.error('Payment confirmation error:', result.error);
                                this.showPaymentsDetailsAreIncorrect();
                            }
                            this.processing = false;
                        },
                        (error) => {
                            console.error('Payment confirmation error:', error);
                            this.showThereWasAProblem();
                            this.processing = false;
                        }
                    );
            } else {
                // console.log(this.paymentForm);
                this.showPaymentsDetailsAreIncorrect();
                this.processing = false;
            }
        } catch (e) {
            this.showThereWasAProblem();
            this.processing = false;
        }
    }

    showPaymentSuccess() {
        this.snackBarService.openMessage('🎉 Payment successful. Welcome Shuffller!', SnackbarActionEnum.Dismiss, 4000);
    }

    showThereWasAProblem() {
        this.snackBarService.openMessage(
            '🫣 There was a problem processing your payment',
            SnackbarActionEnum.Dismiss,
            4000
        );
    }

    showPaymentsDetailsAreIncorrect() {
        this.snackBarService.openMessage(
            '🫣 Please make sure your payment details are correct',
            SnackbarActionEnum.Dismiss,
            4000
        );
    }

    // expressCheckoutConfirmed(event: any) {
    //     this.processing = true;
    //
    //     const { elementType, expressPaymentType, paymentFailed } = event;
    //
    //     // You can only call `paymentFailed` before calling `confirmPayment` to signal
    //     // an error before payment confirmation.
    //
    //     this.stripe
    //         .confirmPayment({
    //             elements: this.expressCheckout.elements,
    //             clientSecret: this.elementsOptions.clientSecret,
    //         })
    //         .subscribe((result) => {
    //             // Handle result.error or result.paymentIntent
    //
    //             if (result.error) {
    //                 console.error(result.error);
    //             } else if (result.paymentIntent) {
    //                 this.createSubscription();
    //             }
    //         });
    // }

    async createSubscription(subscriptionFrom: string) {
        this.processing = true;
        try {
            const response = await firstValueFrom(
                this.plansApiService$.createSubscription({
                    setupIntentId: this.setupIntentId,
                    productId: this.product.id,
                    promoCode: this.plansService.promoCode,
                    subscriptionFrom,
                })
            );

            if (response) {
                // Update user details
                this.authenticationService.authorize(false);
                return true;
            }
        } catch (error) {
            this.handleStripeError(error);
            return false;
        } finally {
            this.processing = false;
        }
    }

    applyCoupon() {
        if (this.paymentForm.get('promoCode')?.value) {
            this.isLoadingCoupon = true;
            console.log(this.paymentForm.get('promoCode')?.value);
            this.plansService.getUnifiedPlan(this.paymentForm.get('promoCode')?.value);
        }
    }

    handleStripeError(error: any): void {
        let errorMessage = 'An error occurred with the payment';
        if (error instanceof HttpErrorResponse) {
            const stripeError: IStripeError = error?.error;

            if (this.isStripeError(stripeError)) {
                switch (stripeError.code) {
                    case StripeErrorEnum.EXPIRED_CARD:
                        errorMessage =
                            "There's an issue with your card's expiration date. Please verify the details and try again.";
                        break;

                    case StripeErrorEnum.INCORRECT_CVC:
                        errorMessage =
                            "There's an issue with your card's security code. Please verify the details and try again.";
                        break;

                    case StripeErrorEnum.INVALID_PROMO_CODE:
                        errorMessage = 'This coupon is not valid anymore 😢';
                        if (this.couponResponse?.appliedCoupon) {
                            /// The code is invalid, let's wipe it from everywhere
                            this.couponResponse.appliedCoupon = null;
                            this.plansService.wipePromoCodeFromLocalStorage();
                        }
                        break;

                    default:
                        errorMessage =
                            'Your card was declined. Please check with your card issuer or use a different payment method.';
                        break;
                }
            }
        }
        this.snackBarService.openMessage(errorMessage, SnackbarActionEnum.Dismiss, 4000);
    }

    /**
     *  Checks if the error is a Stripe error
     * @param error
     * @private
     */
    private isStripeError(error: any): error is IStripeError {
        return (
            error &&
            typeof error.code === 'string' &&
            Object.values(StripeErrorEnum).includes(error.code) &&
            typeof error.message === 'string'
        );
    }
}
