import { Injectable, input } from '@angular/core';
import { IConvertor } from '../../../../../models/project-model';
import {
    ISubtitlesConfig,
    ISubtitlesConfigInDTO,
    ISubtitlesConfigOutDTO,
} from '../../../../../models/project/edit/subtitles/subtitles-configs';
import { SubtitlesSettingsConverterService } from './subtitles-settings.converter.service';

@Injectable({
    providedIn: 'root',
})
export class EditSubtitlesConfigsConverterService
    implements IConvertor<ISubtitlesConfigInDTO, ISubtitlesConfig, ISubtitlesConfigOutDTO>
{
    constructor(private subtitlesSettingsConverter: SubtitlesSettingsConverterService) {}

    inToLocal(input: ISubtitlesConfigInDTO, ...args: any[]): ISubtitlesConfig {
        const localSubtitlesSettings =
            input.settings?.map((setting) => this.subtitlesSettingsConverter.localToOut(setting)) ?? [];
        const localSubtitlesConfig: ISubtitlesConfig = {
            settings: localSubtitlesSettings,
            strategy: input.strategy,
        };
        return localSubtitlesConfig;
    }
    localToOut(client: ISubtitlesConfig, ...args: any[]): ISubtitlesConfigOutDTO {
        const outSubtitleSettings =
            client?.settings?.map((setting) => this.subtitlesSettingsConverter.localToOut(setting)) ?? [];

        const outSubtitlesConfig: ISubtitlesConfigOutDTO = {
            settings: outSubtitleSettings,
            strategy: client.strategy,
        };
        return outSubtitlesConfig;
    }
}
