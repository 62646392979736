import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NavigatorDevicesService } from 'src/app/services/recording/navigator-devices.service';
import { SnackBarService, SnackbarActionEnum } from 'src/app/services/utils/snack-bar.service';
import { ProfileService } from '../../../services/show/profile.service';

enum DomErrorNamesEnum {
    ABORT_ERROR = 'AbortError',
    INVALID_STATE_ERROR = 'InvalidStateError',
    NOT_ALLOWED_ERROR = 'NotAllowedError',
    NOT_FOUND_ERROR = 'NotFoundError',
    NOT_READABLE_ERROR = 'NotReadableError',
    OVERCONSTRAINED_ERROR = 'OverconstrainedError',
    SECURITY_ERROR = 'SecurityError',
}

@Component({
    selector: 'app-devices-permissions',
    templateUrl: './devices-permissions.component.html',
    styleUrls: ['./devices-permissions.component.scss'],
})
export class DevicesPermissionsComponent implements OnInit, OnDestroy {
    private triggerInitalizeStream$ = new Subject<void>();
    private onDestroy$: Subject<boolean> = new Subject();

    private _microphonePermissions$ = this.navigatorDevicesService.microphonePermission$;
    private _cameraPermissions$ = this.navigatorDevicesService.cameraPermission$;

    public _devicesPermissions$ = this.navigatorDevicesService.devicesPermissions$;

    public baseCdnUrl: string;
    public devicePermissionsGif = '/files/platform/gifs/permissions-wide.gif';
    public lockLottiePath = '/files/platform/animations/lock.json';

    constructor(
        private snackBarService: SnackBarService,
        private navigatorDevicesService: NavigatorDevicesService,
        private config: ConfigurationService,
        public profileServce: ProfileService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    ngOnInit(): void {
        this.initializeMediaStream();
        this.triggerMediaPermissions();
    }

    public triggerMediaPermissions() {
        this.navigatorDevicesService.initNavigatorStatesIfNotSet();
        // this.triggerInitalizeStream$.next();
    }

    private initializeMediaStream(): void {
        combineLatest([this._cameraPermissions$, this._microphonePermissions$, this.triggerInitalizeStream$])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: async ([cameraPermission, microphonePermission]) => {
                    // No need to ask for permissions :)
                    if (cameraPermission && microphonePermission) {
                        return;
                    }

                    const constraints: MediaStreamConstraints = {
                        video: !cameraPermission,
                        audio: !microphonePermission,
                    };

                    await this.getUserMediaAsync(constraints);
                },
                error: (err) => console.error('Error in media permissions stream', err),
            });
    }

    private async getUserMediaAsync(constraints: MediaStreamConstraints) {
        try {
            /// get user media triggers the popup to enable the devices.
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.handleStream(stream);
            stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
            const domError = error as DOMException;
            const errorName = domError.name;
            if (errorName === 'NotAllowedError') {
                this.handleNotAllowedError(error);
                return;
            }

            if (errorName === 'NotReadableError') {
            }
        }
    }

    private handleStream(stream: MediaStream): void {
        // Handle the media stream, e.g., display it in a video element or process it
        console.log('Media stream acquired:', stream);
    }

    private handleNotAllowedError(error: DOMException): void {
        const message: string =
            this.messagesToDisplayMap.get(error.name as DomErrorNamesEnum) ??
            'An unknown error occurred while accessing the media devices.';
        // Handle errors, such as showing a user notification
        this.snackBarService.openMessage(message, SnackbarActionEnum.Close, 10000);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    private messagesToDisplayMap = new Map<DomErrorNamesEnum, string>([
        [DomErrorNamesEnum.ABORT_ERROR, 'An unknown error occurred that prevented the device from being used.'],
        [
            DomErrorNamesEnum.INVALID_STATE_ERROR,
            'The operation was attempted on an inactive tab. Please ensure the tab is active.',
        ],
        [DomErrorNamesEnum.NOT_ALLOWED_ERROR, "Opps! Seems like we don't have access to your camera and mic"],
        [DomErrorNamesEnum.NOT_FOUND_ERROR, "Couldn't find devices"],
        [DomErrorNamesEnum.NOT_READABLE_ERROR, 'The device is currently in use in another app or unavailable.'],
        [DomErrorNamesEnum.OVERCONSTRAINED_ERROR, 'The specified constraints could not be satisfied by any device. '],
        [
            DomErrorNamesEnum.SECURITY_ERROR,
            'Media support is disabled in this document or the context is insecure (non-HTTPS). Please enable media support or switch to a secure context.',
        ],
    ]);
}
