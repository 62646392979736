import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../services/guards/authentication.service';
import { Animations } from '../../../configurations/watchScreenAnimations';
import { ConfigurationService } from '../../../services/configuration.service';
import { ChatService } from '../../../services/show/chat.service';
import { SflMessage } from '../../../models/defines';
import { PathHelperService } from 'src/app/services/utils/path-helper.service';

export class PrivateMessageData {
    constructor(socketId, userName) {
        this.socketId = socketId;
        this.userId = userName;
    }

    socketId: string;
    userId: string;
}

interface ChatOptions {
    showChatInput?: boolean;
    animate?: boolean;
    twoSidesMode?: boolean;
    fullHeight?: boolean;
    toTheLeftMobile?: boolean;
    asOverlay?: boolean;
}

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    animations: Animations,
})
export class ChatComponent implements OnInit {
    @Input() mode: ChatModes;
    @Input() room: string;
    @Input() bottomUp: boolean;
    @Input() data;
    @Input() options: ChatOptions;
    @Output() privateMessageChange = new EventEmitter<PrivateMessageData>();
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @ViewChild('chatInput') private chatInput: ElementRef<HTMLInputElement>;

    privateMessageData: PrivateMessageData = null;
    chatModes = ChatModes;

    constructor(
        private authentication: AuthenticationService,
        private config: ConfigurationService,
        public chatService: ChatService,
        public pathHelperService: PathHelperService
    ) {
        // Set defaults
        this.options = {
            showChatInput: true,
            animate: true,
            twoSidesMode: false,
            fullHeight: false,
            toTheLeftMobile: false,
            ...this.options,
        };
    }

    public sendMessage(messageToSend) {
        console.log('Sending message', messageToSend);
        if (messageToSend?.trim() !== '') {
            const destination = this.privateMessageData ? this.privateMessageData.socketId : this.room;
            this.chatService.send(messageToSend);

            this.scrollToBottom();
        }
    }

    ngOnInit(): void {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        try {
            setTimeout(() => {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            }, 300);
        } catch (err) {}
    }

    getProfilePic(message: SflMessage) {
        if (message.who === 'me') {
            return this.pathHelperService.getUserProfileImage(this.authentication.getUser());
        } else if (message.peer.picture) {
            return message.peer.picture;
        }

        return 'assets/images/user-with-bg.svg';
    }

    preparePrivateMessage(message: SflMessage) {
        // this.privateMessageData = new PrivateMessageData(message.socketId, message.author.name);
        // this.privateMessageChange.emit(this.privateMessageData);
    }

    focusOnInput() {
        setTimeout(() => this.chatInput.nativeElement.focus(), 0);
    }
}

export enum ChatModes {
    host,
    participant,
    guest,
}
