import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigatorDevicesService } from './navigator-devices.service';
import * as hark from 'hark';
import {
    LOCAL_STORAGE_SELECTED_CAMERA_ID_KEY,
    LOCAL_STORAGE_SELECTED_MICROPHONE_ID_KEY,
} from '../../constants/shared/local-storage.constants';
import { MissingMediaDeviceIdError } from 'src/app/models/errors/media-devices.errors';

export enum DeviceEventTypeEnum {
    CameraRemoved = 'cameraRemoved',
    MicrophoneRemoved = 'microphoneRemoved',
}

export interface DeviceEvent {
    type: DeviceEventTypeEnum;
    deviceId: string;
}

export type CustomMediaDeviceInfo = MediaDeviceInfo & { cleanedLabel: string };

/**
 * Service for managing available cameras and microphones.
 */
@Injectable({
    providedIn: 'root',
})
export class MediaDevicesService {
    private _cameras$ = new BehaviorSubject<CustomMediaDeviceInfo[]>([]);
    private _microphones$ = new BehaviorSubject<CustomMediaDeviceInfo[]>([]);
    private _selectedCameraId$ = new BehaviorSubject<string>(null);
    private _selectedMicrophoneId$ = new BehaviorSubject<string>(null);
    // A BehaviorSubject instance to trigger device removal events
    private _deviceEvent$ = new BehaviorSubject<DeviceEvent>(null);

    // An Observable stream that components can subscribe to for device removal events
    public deviceEvent$ = this._deviceEvent$.asObservable();

    /**
     * Observable stream of available cameras.
     */
    public cameras$ = this._cameras$.asObservable();

    /**
     * Observable stream of available microphones.
     */
    public microphones$ = this._microphones$.asObservable();

    /**
     * Observable stream of the selected camera device ID.
     */
    public selectedCameraId$ = this._selectedCameraId$.asObservable();

    /**
     * Observable stream of the selected microphone device ID.
     */
    public selectedMicrophoneId$ = this._selectedMicrophoneId$.asObservable();

    /** Voice detection events */
    private speechEvents: hark.Harker;
    private isVoiceActiveSubject = new BehaviorSubject<boolean>(false);
    public isVoiceActive$ = this.isVoiceActiveSubject.asObservable();

    hasDevicePermissions: boolean;

    constructor(private navigatorDevicesService: NavigatorDevicesService) {
        this._selectedCameraId$.next(localStorage.getItem(LOCAL_STORAGE_SELECTED_CAMERA_ID_KEY));
        this._selectedMicrophoneId$.next(localStorage.getItem(LOCAL_STORAGE_SELECTED_MICROPHONE_ID_KEY));

        // Subscribe to device permissions changes
        this.navigatorDevicesService.devicesPermissions$.subscribe((hasPermission) => {
            this.hasDevicePermissions = hasPermission;
            if (hasPermission) {
                // Initialize devices when permissions are granted
                return this.initDevicesAsync();
            }
            // Clear device lists when permissions are revoked
            this._cameras$.next([]);
            this._microphones$.next([]);
            return;
        });

        this.navigatorDevicesService.deviceChanged$.subscribe(() => {
            this.initDevicesAsync(true);
        });
    }

    public setVoiceActive(isActive: boolean) {
        this.isVoiceActiveSubject.next(isActive);
    }

    /**
     * Selects the camera with the specified device ID.
     * @param deviceId The device ID of the camera to select.
     */
    selectCamera(deviceId: string) {
        const cameraExists = this._cameras$.value.some((camera) => camera.deviceId === deviceId);
        if (cameraExists) {
            this._selectedCameraId$.next(deviceId);
            localStorage.setItem(LOCAL_STORAGE_SELECTED_CAMERA_ID_KEY, deviceId);
        } else {
            console.warn(`Camera with device ID ${deviceId} not found.`);
        }
    }

    /**
     * Selects the microphone with the specified device ID.
     * @param deviceId The device ID of the microphone to select.
     */
    selectMicrophone(deviceId: string) {
        const microphoneExists = this._microphones$.value.some((microphone) => microphone.deviceId === deviceId);
        if (microphoneExists) {
            this._selectedMicrophoneId$.next(deviceId);
            localStorage.setItem(LOCAL_STORAGE_SELECTED_MICROPHONE_ID_KEY, deviceId);

            // Stop the current voice activity detection
            this.stopVoiceActivityDetection();

            // Start a new voice activity detection with the selected microphone
            this.initVoiceActivityDetectionAsync();
        } else {
            console.warn(`Microphone with device ID ${deviceId} not found.`);
        }
    }

    async initVoiceActivityDetectionAsync() {
        try {
            if (!this.hasDevicePermissions) {
                return;
            }
            const stream = await this.getMediaStreamAsync(true);
            if (!stream) {
                console.warn('Media stream is not available');
                return;
            }
            const tracks = stream.getTracks();
            if (tracks && tracks.length > 0) {
                tracks[0].addEventListener('ended', () => {
                    console.error(`Stream ended in the middle`);
                    this.navigatorDevicesService.initNavigatorStates();
                });
            }
            stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
            console.warn('Media stream is not available');
        }
    }

    stopVoiceActivityDetection() {
        if (this.speechEvents) {
            this.speechEvents.stop();
            this.speechEvents = null;
        }
        // Reset subject to ensure proper state on cleanup
        this.isVoiceActiveSubject.next(false);
    }

    /**
     * Checks if the specified camera is currently selected.
     * @param deviceId The device ID of the camera to check.
     * @returns An observable that emits true if the camera is selected, false otherwise.
     */
    public isCameraSelected(deviceId: string): Observable<boolean> {
        return this.selectedCameraId$.pipe(map((selectedCameraId) => selectedCameraId === deviceId));
    }

    /**
     * Checks if the specified microphone is currently selected.
     * @param deviceId The device ID of the microphone to check.
     * @returns An observable that emits true if the microphone is selected, false otherwise.
     */
    public isMicrophoneSelected(deviceId: string): Observable<boolean> {
        return this.selectedMicrophoneId$.pipe(map((selectedMicrophoneId) => selectedMicrophoneId === deviceId));
    }

    /**
     * cameras$  Observable that emits the list of cameras.
     * @param ommitSelectedCamera If true, the selected camera will not be included in the list.
     */
    public getCameras(ommitSelectedCamera = false) {
        if (ommitSelectedCamera) {
            return this._cameras$.value.filter((camera) => camera.deviceId !== this._selectedCameraId$.value);
        }
        return this._cameras$.value;
    }

    /**
     * Gets the media stream from the selected camera and microphone devices.
     * @returns An observable that emits the media stream, or null if no devices are selected.
     */
    public async getMediaStreamAsync(mic = false): Promise<MediaStream> {
        const selectedCameraId = this._selectedCameraId$.value;
        const selectedMicrophoneId = this._selectedMicrophoneId$.value;

        if (selectedCameraId === null && selectedMicrophoneId === null) {
            throw new MissingMediaDeviceIdError(`No media devices selected`);
        }

        const audioConstraints: MediaTrackConstraints = {
            deviceId: selectedMicrophoneId,
        };

        let videoConstraints: MediaTrackConstraints | undefined;
        if (!mic) {
            const selectedCameraResolution = await this.getBestCameraResolution(selectedCameraId);
            videoConstraints = {
                deviceId: selectedCameraId,
                width: { ideal: selectedCameraResolution?.width || 1920 },
                height: { ideal: selectedCameraResolution?.height || 1080 },
            };
        }

        const constraints: MediaStreamConstraints = {
            video: videoConstraints,
            audio: audioConstraints,
        };

        this.stopVoiceActivityDetection();
        // const stream = await navigator.mediaDevices.getUserMedia(constraints);
        // this.speechEvents = hark(stream, { threshold: -50, interval: 100 });
        //
        // this.speechEvents.on('speaking', () => {
        //   this.isVoiceActiveSubject.next(true);
        // });
        //
        // this.speechEvents.on('stopped_speaking', () => {
        //   this.isVoiceActiveSubject.next(false);
        // });
        return await navigator.mediaDevices.getUserMedia(constraints);
    }

    async getBestCameraResolution(selectedCameraId: string, maxWidth = 1920, maxHeight = 1080) {
        try {
            // Ensure that the selectedCameraId is valid
            if (!selectedCameraId) {
                throw new Error('No camera selected');
            }

            // Get the capabilities of the selected camera
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedCameraId },
            });
            const track = stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();

            // Stop the stream immediately to release resources
            stream.getTracks().forEach((track) => {
                track.stop();
            });

            // Find the best resolution within the device's capabilities and the max limits
            const bestWidth = Math.min(capabilities.width.max, maxWidth);
            const bestHeight = Math.min(capabilities.height.max, maxHeight);

            // Return the best resolution for the selected camera
            return { width: bestWidth, height: bestHeight };
        } catch (error) {
            console.error('Error getting best camera resolution:', error);
            return null;
        }
    }

    /**
     * Finds a camera by its ID.
     *
     * @param {string} cameraId - The ID of the camera to find.
     * @return {CustomMediaDeviceInfo} The found camera, or null if not found.
     */
    public findCameraById(cameraId: string = this._selectedCameraId$?.value): CustomMediaDeviceInfo {
        if (!cameraId) {
            return null;
        }
        return this._cameras$.value.find((camera) => camera.deviceId === cameraId);
    }

    public findMicrophoneById(microphoneId: string = this._selectedMicrophoneId$?.value) {
        if (!microphoneId) {
            return null;
        }
        return this._microphones$?.value.find((microphone) => microphone.deviceId === microphoneId);
    }

    /**
     * Cleans up the device label by removing any trailing "(xxxx:xxxx)" or similar patterns.
     * @param label The original device label.
     * @returns The cleaned device label.
     */
    private cleanDeviceLabel(label: string): string {
        // Remove any trailing "(xxxx:xxxx)" or similar patterns
        return label.replace(/\s*\([^)]+\)$/, '');
    }

    /**
     * Initializes the available media devices.
     * @returns An observable that emits when the devices are initialized.
     */
    private async initDevicesAsync(isDeviceChange = false) {
        if (!this.hasDevicePermissions) {
            return;
        }
        let devices: MediaDeviceInfo[];
        try {
            devices = await navigator.mediaDevices.enumerateDevices();
        } catch (error) {
            console.error('Error enumerating media devices:', error);
            return;
        }

        const currentSelectedCameraId =
            this._selectedCameraId$.value ?? localStorage.getItem(LOCAL_STORAGE_SELECTED_CAMERA_ID_KEY);
        const currentSelectedMicrophoneId =
            this._selectedMicrophoneId$.value ?? localStorage.getItem(LOCAL_STORAGE_SELECTED_MICROPHONE_ID_KEY);

        // Filter and update the list of available cameras
        const newCameras = devices
            .filter((device) => device.kind === 'videoinput')
            .map((camera) => {
                const customCamera: CustomMediaDeviceInfo = {
                    deviceId: camera.deviceId,
                    kind: camera.kind,
                    label: camera.label,
                    groupId: camera.groupId,
                    toJSON: camera.toJSON,
                    cleanedLabel: this.cleanDeviceLabel(camera.label),
                };
                return customCamera;
            });

        // Remove disconnected cameras from the list
        const allCameras = this._cameras$.value.filter((camera) =>
            newCameras.some((c) => c.deviceId === camera.deviceId)
        );
        allCameras.push(
            ...newCameras.filter((camera) => !this._cameras$.value.some((c) => c.deviceId === camera.deviceId))
        );
        this._cameras$.next(allCameras);

        // Filter and update the list of available microphones
        const newMicrophones = devices
            .filter((device) => device.kind === 'audioinput')
            .map((microphone) => {
                const customMicrophone: CustomMediaDeviceInfo = {
                    deviceId: microphone.deviceId,
                    kind: microphone.kind,
                    label: microphone.label,
                    groupId: microphone.groupId,
                    toJSON: microphone.toJSON,
                    cleanedLabel: this.cleanDeviceLabel(microphone.label),
                };
                return customMicrophone;
            });

        // Remove disconnected microphones from the list
        const allMicrophones = this._microphones$.value.filter((mic) =>
            newMicrophones.some((m) => m.deviceId === mic.deviceId)
        );
        allMicrophones.push(
            ...newMicrophones.filter((mic) => !this._microphones$.value.some((m) => m.deviceId === mic.deviceId))
        );
        this._microphones$.next(allMicrophones);
        // if (isDeviceChange) {
        // Restore the selected camera if it still exists in the updated list
        // If not cameras are available, not doing choosing anything.
        this.handleCameraSelection(allCameras, currentSelectedCameraId, isDeviceChange);

        // Restore the selected microphone if it still exists in the updated list
        // If not microphones are available, not doing choosing anything.
        this.handleMicrophoneSelection(allMicrophones, currentSelectedMicrophoneId);
        // }
    }

    private handleCameraSelection(
        allCameras: CustomMediaDeviceInfo[], // An array of available microphones
        selectedCameraId: string,
        isDeviceChange = false
    ): void {
        if (allCameras.length === 0) {
            return; // No microphones available, nothing to do
        }

        let validCameraId = selectedCameraId;

        if (!allCameras.some((mic) => mic.deviceId === selectedCameraId)) {
            // The currently selected microphone is not in the list of available microphones
            validCameraId = allCameras[0].deviceId;

            // Emit an event if the previously selected microphone was removed
            if (selectedCameraId !== validCameraId) {
                this._deviceEvent$.next({
                    type: DeviceEventTypeEnum.MicrophoneRemoved,
                    deviceId: selectedCameraId,
                });
            }
        }
        // if (isDeviceChange) {
        this.selectCamera(validCameraId);
        // }
    }

    private handleMicrophoneSelection(
        allMicrophones: CustomMediaDeviceInfo[], // An array of available microphones
        currentSelectedMicrophoneId: string // The currently selected microphone ID
    ): void {
        if (allMicrophones.length === 0) {
            return; // No microphones available, nothing to do
        }

        let validMicrophoneId = currentSelectedMicrophoneId;

        if (!allMicrophones.some((mic) => mic.deviceId === currentSelectedMicrophoneId)) {
            // The currently selected microphone is not in the list of available microphones
            validMicrophoneId = allMicrophones[0].deviceId;

            // Emit an event if the previously selected microphone was removed
            if (currentSelectedMicrophoneId !== validMicrophoneId) {
                this._deviceEvent$.next({
                    type: DeviceEventTypeEnum.MicrophoneRemoved,
                    deviceId: currentSelectedMicrophoneId,
                });
            }
        }

        this.selectMicrophone(validMicrophoneId);
    }
}
