import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

@Injectable({
    providedIn: 'root',
})
export class ConsoleToggleService {
    constructor(private configurationService: ConfigurationService) {}

    disableConsoleInProduction(): void {
        if (this.configurationService.isProduction) {
            console.log = function (): void {};
            console.debug = function (): void {};
            console.warn = function (): void {};
            console.info = function (): void {};
        }
    }
}
