import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-non-pc-error',
    templateUrl: './non-pc-error.component.html',
    styleUrls: ['./non-pc-error.component.scss'],
})
export class NonPcErrorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
