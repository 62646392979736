import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../configuration.service';
import { AssetType, IAsset } from '../../../models/defines';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AssetsAuthApiService {
    path = `${this.config.apiIP}/auth/assets`;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    upload = (formData: FormData, extraParams = {}) => {
        return this.http.post(`${this.path}`, formData, {
            reportProgress: true,
            observe: 'events',
            params: extraParams,
        });
    };

    getAssets(): Observable<IAsset[]> {
        return this.http.get<IAsset[]>(`${this.path}`);
    }

    getAssetsByType(filterByParams: AssetType): Observable<IAsset[]> {
        return this.http.get<IAsset[]>(`${this.path}/filter/` + filterByParams);
    }

    createFolder = (newName: string) => {
        return this.http.post(`${this.path}/folder`, { newName });
    };

    renameFile(fromFolder: string, oldName: string, newName: string): Observable<any> {
        return this.http.put(`${this.path}/file`, {
            fromFolder,
            oldName,
            newName,
        });
    }

    moveFile(displayName: string, fromFolder: string, toFolder: string): Observable<any> {
        return this.http.put(`${this.path}/move`, {
            fromFolder,
            toFolder,
            displayName,
        });
    }

    renameFolder(oldName: string, newName: string): Observable<any> {
        return this.http.put(`${this.path}/folder`, { oldName, newName });
    }

    deleteFile(displayName: string, folder: string): Observable<any> {
        return this.http.delete(`${this.path}/file`, {
            params: { displayName: displayName, folder: folder },
        });
    }

    deleteFolder(folderName: string): Observable<any> {
        return this.http.delete(`${this.path}/folder`, {
            params: { folderName: folderName },
        });
    }
}
