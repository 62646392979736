import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MimeTypeEnum } from 'src/app/models/defines';

@Injectable({
    providedIn: 'root',
})
export class FunctionsHelperService {
    constructor(private sanitizer: DomSanitizer) {}

    public blobToArrayBufferAsync(blob: Blob): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result instanceof ArrayBuffer) {
                    resolve(reader.result);
                } else {
                    reject(new Error('Failed to convert Blob to ArrayBuffer'));
                }
            };

            reader.onerror = () => {
                reject(reader.error);
            };

            reader.readAsArrayBuffer(blob);
        });
    }

    public transformLocalUrlToSafe(url: string): string {
        return this.sanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this.sanitizer.bypassSecurityTrustResourceUrl(url)
        );
    }

    public deepClone<T>(source: T): T {
        return JSON.parse(JSON.stringify(source));
    }

    // Assuming 'arrayBuffer' is your ArrayBuffer
    public arrayBufferToBlob(arrayBuffer: ArrayBuffer, type: 'video/mp4' | 'audio/mp3'): Blob {
        return new Blob([arrayBuffer], { type: type });
    }

    public timeAgo(date: number): string {
        const now = new Date();
        const past = new Date(date);
        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const daysAgo = Math.floor((now.getTime() - past.getTime()) / millisecondsPerDay);
        const weeksAgo = Math.floor(daysAgo / 7);
        const monthsAgo = Math.floor(daysAgo / 30); // Approximate number of days in a month
        const yearsAgo = Math.floor(daysAgo / 365); // Approximate number of days in a year

        // Handle different time spans
        if (daysAgo === 0) {
            return 'Today';
        } else if (daysAgo === 1) {
            return 'Yesterday';
        } else if (daysAgo < 7) {
            return `${daysAgo} days ago`;
        } else if (weeksAgo < 4) {
            return `${weeksAgo} weeks ago`;
        } else if (monthsAgo < 12) {
            return `${monthsAgo} months ago`;
        } else {
            return `${yearsAgo} years ago`;
        }
    }

    public getMimeTypeFromExtension(url: string): MimeTypeEnum {
        const extension = url.split('.').pop() || '';
        switch (extension.toLowerCase()) {
            case 'webm':
                return MimeTypeEnum.VideoWebm;
            case 'mp4':
                return MimeTypeEnum.VideoMp4;
            // Add more cases for other video formats if needed
            default:
                return MimeTypeEnum.OCTECT_STREAM; // Default MIME type
        }
    }

    isHLSSupported() {
        const video = document.createElement('video');

        // Check for native HLS support
        if (
            video.canPlayType('application/vnd.apple.mpegURL') !== '' ||
            video.canPlayType('application/x-mpegURL') !== ''
        ) {
            return true;
        }

        // Check for MSE support
        if (window.MediaSource) {
            return true;
        }

        return false;
    }
}
