import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'new-project',
    templateUrl: './new-project.component.html',
    styleUrls: ['./new-project.component.scss'],
    animations: [
        trigger('wizard', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-10px)' }),
                animate('350ms', style({ opacity: 1, transform: 'translateX(0px)' })),
            ]),
        ]),
    ],
})
export class NewProjectComponent {
    constructor(private router: Router) {}
}
