<vg-player class="welcome-video-player">
    <vg-overlay-play vgFor="welcome-video"></vg-overlay-play>

    <video
        #media
        #vgHls="vgHls"
        [vgMedia]="media"
        vgHls="https://content.shuffll.com/files/platform/welcome-video/index.m3u8"
        id="welcome-video"
        poster="https://content.shuffll.com/files/platform/welcome-video/welcome-poster.jpg"
        crossorigin
        controls
        disablePictureInPicture
        playsinline></video>
</vg-player>
