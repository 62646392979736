import { LocalNotFoundError } from '../general.errors';

export class ProjectNotFoundError extends LocalNotFoundError {
    constructor(message: string) {
        super(message);
        this.name = 'ProjectNotFoundError';
    }
}

export class ProjectNotMatchError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ProjectNotMatchError';
    }
}
