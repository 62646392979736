import { Injectable } from '@angular/core';
import { CreativeAuthApiService } from '../../api/auth/creative-auth-api.service';
import { BehaviorSubject, of } from 'rxjs';
import { IDesignGroup } from '../../../models/design.model';
import { catchError, finalize, retry } from 'rxjs/operators';
import { ITagAndSuggestions, IVideoTag } from '../../../models/defines';

@Injectable({
    providedIn: 'root',
})
export class WizardManagerService {
    private _suggestedDesigns$ = new BehaviorSubject<IDesignGroup[]>(null);
    private _privateDesigns$ = new BehaviorSubject<IDesignGroup[]>(null);
    private _isLoadingDesigns$ = new BehaviorSubject<boolean>(false);

    private _allPromptSuggestions$ = new BehaviorSubject<ITagAndSuggestions[]>(null);
    private _isLoadingPromptSuggestions$ = new BehaviorSubject<boolean>(false);
    private _videoTags$ = new BehaviorSubject<IVideoTag[]>(null);
    public videoTags$ = this._videoTags$.asObservable();

    public suggestedDesigns$ = this._suggestedDesigns$.asObservable();
    public privateDesigns$ = this._privateDesigns$.asObservable();
    public isLoadingDesigns$ = this._isLoadingDesigns$.asObservable();
    public allPromptSuggestions$ = this._allPromptSuggestions$.asObservable();
    public isLoadingPromptSuggestions$ = this._isLoadingPromptSuggestions$.asObservable();
    constructor(private creativeApi: CreativeAuthApiService) {
        this.generatePromptSuggestions();
        this.generateDesignsSuggestions();
    }
    generateDesignsSuggestions() {
        this._isLoadingDesigns$.next(true);
        this.creativeApi.getFormatSuggestion().subscribe((res) => {
            this._suggestedDesigns$.next(res.public);
            this._privateDesigns$.next(res.private);
            this._isLoadingDesigns$.next(false);
        });
    }
    generatePromptSuggestions() {
        this._isLoadingPromptSuggestions$.next(true);

        this.creativeApi
            .getPromptsSuggestions()
            .pipe(
                retry(2), // 3 attempts
                catchError((error) => {
                    console.error('Error occurred:', error);

                    return of(null);
                }),
                finalize(() => {
                    this._isLoadingPromptSuggestions$.next(false);
                })
            )
            .subscribe((prompts) => {
                if (prompts?.length > 0) {
                    this._allPromptSuggestions$.next(prompts);
                    /// Take the video tags from the prompts objects
                    this._videoTags$.next(
                        prompts.map((x) => {
                            return x.tag;
                        })
                    );
                }
            });
    }
}
