import { Component, EventEmitter, Output } from '@angular/core';
import { PlansService } from 'src/app/services/plans.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import { Stripe } from 'stripe';
import { MatDialog } from '@angular/material/dialog';
import { PaymentFormComponent } from '../payment-form/payment-form.component';

@Component({
    selector: 'plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss'],
})
export class PlansComponent {
    @Output() selectedPlan = new EventEmitter<Stripe.Product>();

    constructor(
        public profileService: ProfileService,
        public plansService: PlansService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.plansService.getPlans();
    }

    selectPlan(planAndPrice: { product: Stripe.Product; price: Stripe.Price }) {
        /// Open the payment form in a dialog
        this.dialog.open(PaymentFormComponent, {
            data: planAndPrice,
            minWidth: '500px',
            minHeight: '500px',
            maxHeight: '95vh',
            panelClass: 'padding-modal',
        });

        // this.selectedPlan.emit();
    }

    onPaymentSuccess(event: any): void {
        console.log('Payment success!!!!!:', event);
        // Make an API call to retrieve the updated user data
        // this.profileService.getUserData().subscribe(
        //     (userData) => {
        //         // Update the user's plan information in your application state
        //         // Example: this.profileService.updateUserPlan(userData.plan);
        //
        //         // Redirect the user to the appropriate page based on their new plan
        //         if (userData.plan === 'premium') {
        //             this.router.navigate(['/premium-dashboard']);
        //         } else {
        //             this.router.navigate(['/dashboard']);
        //         }
        //     },
        //     (error) => {
        //         console.error('Failed to retrieve user data:', error);
        //     }
        // );
    }
}
