import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/guards/authentication.service';
import { ProfileService } from '../../../services/show/profile.service';

@Component({
    selector: 'app-ask-to-participate',
    templateUrl: './ask-to-participate.component.html',
    styleUrls: ['./ask-to-participate.component.scss'],
})
export class AskToParticipateComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AskToParticipateComponent>,
        public profileService: ProfileService,
        public authentication: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public data: AskToJoinDialogData
    ) {}

    ngOnInit(): void {
        // console.log('auth', this.authentication.isAuthenticated());
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

export interface AskToJoinDialogData {
    // approved: boolean;
    host: string;
}
