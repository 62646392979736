import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSliderDragEvent } from '@angular/material/slider';
import { IPlayable, VgApiService } from '@videogular/ngx-videogular/core';

@Component({
    selector: 'app-scrub-bar',
    templateUrl: './scrub-bar.component.html',
    styleUrls: ['./scrub-bar.component.scss'],
})
export class ScrubBarComponent implements OnInit {
    @Input()
    vgApiId: string;

    @Output()
    newSeekInMillisEmitter = new EventEmitter<number>();
    @Output()
    dragStartedEmitter = new EventEmitter<void>();
    @Output()
    dragEndedEmitter = new EventEmitter<void>();

    @Input()
    duration: number;

    // Sent only from edit room
    @Input()
    currentTime: number;

    @Input()
    navigateFromEditRoom: boolean;

    currentTimeFromVideo: number = 0;
    vgApiMedia: IPlayable;

    constructor(private vgApi: VgApiService) {}

    ngOnInit(): void {
        if (this.vgApiId) {
            /// I dont know why but he adds 0 to the media's id
            this.vgApiMedia = this.vgApi.getMediaById(this.vgApiId + '0');

            if (this.vgApiMedia) {
                this.vgApiMedia.subscriptions.timeUpdate.subscribe((newTime) => {
                    // console.log(`DURATION!!! ${this.vgApiMedia.duration}`);
                    // console.log('new time!', newTime.timeStamp);
                    this.currentTimeFromVideo = this.vgApiMedia.currentTime;
                });
            }
        }

        // if (this.current !== null) {
        //     this.currentPosition = this.current;
        // }
    }

    onDragging(timeInMilliseconds: number) {
        this.newSeekInMillisEmitter.emit(timeInMilliseconds);
    }

    dragStarted(event: MatSliderDragEvent) {
        this.dragStartedEmitter.emit();
    }

    dragEnded(event: MatSliderDragEvent) {
        this.dragEndedEmitter.emit();
    }

    onCurrentPositionChange(newPosition: number): void {
        // Do something with the new position
        console.log('Current Position changed:', newPosition);
    }
}
