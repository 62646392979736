<div>
    <span class="title-wrapper">
        <h2>What would you like to record?</h2>
        <h6>Step 2/2</h6>
    </span>

    <div class="row-centered card-buttons">
        <button
            class="card-button"
            mat-raised-button
            *ngFor="let buttonSetup of buttons; let i = index"
            (mouseenter)="startAnimation(i)"
            (mouseleave)="stopAnimation(i)"
            (click)="select(buttonSetup)"
            [class.selected]="buttonSetup.selected">
            <div class="most-popular highlighted-shimmer" *ngIf="buttonSetup.mostPopular">Most Popular</div>
            <mat-icon class="check-icon smaller-icon" *ngIf="buttonSetup.selected">check</mat-icon>

            <div class="icon-img">
                <ng-lottie
                    [options]="buttonSetup.animationOptions"
                    class="recording-type-icon"
                    containerClass="recording-type-icon-container"
                    (animationCreated)="animationCreated($event, i)">
                </ng-lottie>
            </div>
            <div>
                {{ buttonSetup.label }}
            </div>
        </button>
    </div>
</div>
