<div>
    <div *ngIf="!isEditMode" class="dummy-scroller" (mouseenter)="onHoverStart()" (mouseleave)="onHoverEnd()">
        <ng-container *ngFor="let word of dummyText">
            <span
                *ngIf="!word.newLine"
                class="text-span"
                [class.word]="word.type === 'word'"
                [class.symbol]="word.type === 'symbol'"
                [class.space]="word.type === 'space'"
                [class.done]="
                    voiceRecognition.lastFoundWord.value && word.index <= voiceRecognition.lastFoundWord.value.index
                "
                [id]="word.index"
                matBadge="1"
                matBadgeOverlap="false"
                matBadgePosition="after"
                [matBadgeHidden]="word.type !== 'word' || !word.events || word.events.length === 0"
                matBadgeColor="primary"
                >{{ word.text }}</span
            >
            <br *ngIf="word.newLine" />
        </ng-container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
    <div>
        <textarea
            *ngIf="isEditMode"
            (blur)="onTextareaBlur()"
            [(ngModel)]="manualText"
            (ngModelChange)="onTextChange()"
            class="text-area"
            [style.color]="teleprompterSettings.textColor"
            [style.font-size]="teleprompterSettings.fontSize + 'px'"
            [style.line-height]="teleprompterSettings.fontSize * 1.5 + 'px'"
            [style.text-align]="teleprompterSettings.textAlignment.align"
            [style.padding-left]="teleprompterSettings.margin + '%'"
            [style.padding-right]="teleprompterSettings.margin + '%'"></textarea>
    </div>
</div>
