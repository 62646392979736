<div id="chat-input-wrapper" [class.minwidth]="options && options.minWidth">
    <!-- <mat-form-field id="chat-input" appearance="fill" [floatLabel]="false" style="width: 100%;"
                  *ngIf="authentication.isAuthenticated$ | async">

    <mat-chip-grid #chipList>
      <mat-chip-row removable (removed)="removePrivateMessage()" *ngIf="privateMessageData">
        {{privateMessageData.userId}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>

      <input [matChipInputFor]="chipList" #chatInput matInput [type]="'text'" placeholder="Message" autocomplete="off"
             [(ngModel)]="messageToSend" (keydown.enter)="send()">
      <button mat-icon-button matSuffix *ngIf="options && options.enableVideoComments" (click)="videoMode =!videoMode">
        <mat-icon>videocam</mat-icon>
      </button>
      <button mat-icon-button matSuffix (click)="send()">
        <mat-icon>send</mat-icon>
      </button>


    </mat-chip-grid>

  </mat-form-field> -->
    <div id="login-to-comment" *ngIf="!(authentication.isAuthenticated$ | async)">
        <div>You must be logged in to comment</div>
        <button mat-raised-button color="primary" (click)="goToLogin()">Login</button>
    </div>
</div>
