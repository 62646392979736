import { Injectable } from '@angular/core';
import { IBasicScene } from '../../../models/project/scene-model';
import { ProjectStoreService } from './project-store.service';
import { IProject } from '../../../models/project-model';

@Injectable()
export class ProjectSessionService {
    private lastEditedSceneFromPlanningId: string;
    private lastRecordedSceneId: string;

    private lastTouchedSceneId: string;
    private relevantSceneDryData: {
        id: string;
        index: number;
    } = {
        id: null,
        index: 0,
    };

    private project: IProject;

    constructor(private projectStoreService: ProjectStoreService) {
        this.projectStoreService.projectSource$.subscribe((project) => {
            if (!project) {
                return;
            }
            this.project = project;

            this.initializeRelevantSceneDryData(null);
        });
    }

    set lastEditedScene(sceneId: string) {
        this.lastEditedSceneFromPlanningId = sceneId;
        this.lastTouchedScene = sceneId;
        localStorage.setItem(`last-edited-scene`, sceneId);
    }

    get lastEditedScene() {
        return this.lastEditedSceneFromPlanningId ?? localStorage.getItem(`last-edited-scene`);
    }

    set lastRecordedScene(sceneId: string) {
        this.lastRecordedSceneId = sceneId;
        localStorage.setItem(`last-recorded-scene`, sceneId);
        this.lastTouchedScene = sceneId;
    }

    get lastRecordedScene() {
        return this.lastRecordedSceneId ?? localStorage.getItem(`last-recorded-scene`);
    }

    get relevantSceneData() {
        return this.relevantSceneDryData;
    }

    set lastTouchedScene(sceneId: string) {
        this.lastTouchedSceneId = sceneId;
        localStorage.setItem(`last-touched-scene`, sceneId);
        this.initializeRelevantSceneDryData(sceneId);
    }

    get lastTouchedScene() {
        return this.lastTouchedSceneId ?? localStorage.getItem(`last-touched-scene`);
    }

    private initializeRelevantSceneDryData(sceneId: string | null) {
        const scenes = this.project.scenes;
        let sceneIndex: number;
        sceneIndex = scenes.findIndex((scene) => scene.id === sceneId);

        if (sceneIndex === -1) {
            sceneIndex = this.findRelevantSceneIndex(scenes);
        }
        if (sceneIndex === -1) {
            this.relevantSceneDryData = {
                id: null,
                index: 0,
            };
            return;
        }

        this.relevantSceneDryData = {
            id: scenes[sceneIndex].id,
            index: sceneIndex,
        };
    }

    private findRelevantSceneIndex(scenes: IBasicScene[]) {
        const lastTouchedSceneId = this.lastTouchedScene;
        let index = scenes.findIndex((scene) => scene.id === lastTouchedSceneId);

        if (index === -1) {
            // Try to find the index of the last edited scene
            const lastEditedSceneId = this.lastEditedScene;
            index = scenes.findIndex((scene) => scene.id === lastEditedSceneId);
        }

        // If not found, try to find the index of the last recorded scene
        if (index === -1) {
            const lastRecordedScene = this.lastRecordedScene;
            index = scenes.findIndex((scene) => scene.id === lastRecordedScene);
        }

        // Default to the first scene if none found
        return index;
    }
}
