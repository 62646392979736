import { Component, Input } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { IPathAndDash } from 'src/app/models/defines';

@Component({
    selector: 'video-for-backstage',
    templateUrl: './video-for-backstage.component.html',
    styleUrls: ['./video-for-backstage.component.scss'],
})
export class VideoForBackstageComponent {
    @Input()
    videoSource: IPathAndDash;
    vgApi: VgApiService;

    onPlayerReady(api: VgApiService) {
        console.log('video source', this.videoSource);
        this.vgApi = api;
        this.vgApi.getDefaultMedia().subscriptions.loadedMetadata.subscribe(() => {
            this.vgApi.seekTime(0.5);
        });
    }
}
