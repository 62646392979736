import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-video-chat-comment',
    templateUrl: './video-chat-comment.component.html',
    styleUrls: ['./video-chat-comment.component.scss'],
})
export class VideoChatCommentComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
