import { Injectable } from '@angular/core';
import { SocketEventWrapperService } from '../../socket-event-wrapper.service';
import {
    ITakeClientToServerEvents,
    ITakeEventData,
    ITakeServerToClientEvents,
} from '../../../../models/socket-events/project/take/socket-take-events';

@Injectable()
export class TakeEventsService {
    constructor(
        private socketEventWrapper: SocketEventWrapperService<ITakeClientToServerEvents, ITakeServerToClientEvents>
    ) {}

    onAudioCleanPath() {
        return this.socketEventWrapper.fromEvent('audioCleanPath');
    }

    onSegemntedPath() {
        return this.socketEventWrapper.fromEvent('segmentedPath');
    }

    onSubtitles() {
        return this.socketEventWrapper.fromEvent('subtitles');
    }

    onAddedTake() {
        return this.socketEventWrapper.fromEvent('addedTake');
    }

    notifyEndedTake(data: ITakeEventData) {
        return this.socketEventWrapper.emit('newTakeFinished', data);
    }
}
