import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-stop-recording-alert-dialog',
    templateUrl: './stop-recording-alert-dialog.component.html',
    styleUrls: ['./stop-recording-alert-dialog.component.scss'],
})
export class StopRecordingAlertDialogComponent {
    constructor(public dialogRef: MatDialogRef<StopRecordingAlertDialogComponent>) {}

    public closeDialog(toKeepRecording: boolean) {
        this.dialogRef.close({
            keepRecording: toKeepRecording,
        });
    }
}
