import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-locked-feature',
    templateUrl: './locked-feature.component.html',
    styleUrls: ['./locked-feature.component.scss'],
})
export class LockedFeatureComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
