import { Component, Input, OnInit } from '@angular/core';
import { SflPeerAndSource } from '../../../../../models/defines';
import { ProfileService } from '../../../../../services/show/profile.service';

@Component({
    selector: 'team-user-pic',
    templateUrl: './team-user-pic.component.html',
    styleUrls: ['./team-user-pic.component.scss'],
})
export class TeamUserPicComponent implements OnInit {
    @Input('participant')
    participant: SflPeerAndSource = null;
    constructor(public profile: ProfileService) {}

    ngOnInit(): void {}
}
