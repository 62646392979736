import { Injectable } from '@angular/core';
import * as socketIO from 'socket.io-client';
import { ConfigurationService } from '../../services/configuration.service';
import { ProfileService } from './profile.service';
import { LoggerService } from '../logger.service';
import { EventbusService, EventType } from './eventbus.service';
import {
    DynamicValuesChangeMessage,
    PositionUpdate,
    RequestMethod,
    SflMessage,
    StageOptions,
} from '../../models/defines';
import { types as mediaTypes } from 'mediasoup-client';

const pRequestMap = new Map<string, string>();
const pNotificationMap = new Map<string, string>();

const Request = (ref: string = null) => {
    console;
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        if (ref) {
            pRequestMap.set(ref, propertyKey);
        } else {
            pRequestMap.set(propertyKey, propertyKey);
        }
    };
};

const Notification = (ref: string = null) => {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        if (ref) {
            pNotificationMap.set(ref, propertyKey);
        } else {
            pNotificationMap.set(propertyKey, propertyKey);
        }
    };
};

@Injectable({
    providedIn: 'root',
})
export class SignalingService {
    private socket: socketIO.Socket;

    constructor(
        private profile: ProfileService,
        private config: ConfigurationService,
        private logger: LoggerService,
        private eventbus: EventbusService
    ) {}

    connect() {
        // console.log("Connecting to signaling", this.profile.roomId);
        const socketUrl = `${this.config.mediasoup.address}/?roomId=${this.profile.roomId}&peerId=${this.profile.userPeer.id}`;

        this.logger.debug('Connecting to Room ID: %s', this.profile.roomId);

        this.socket = socketIO.io(socketUrl, {
            timeout: 3000,
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelayMax: 2000,
            transports: ['websocket'],
        });

        this.setupEventHandler(this.socket);
        this.setupNotificationHandler();
        this.setupRequestHandler();
    }

    private setupEventHandler(socket: socketIO.Socket) {
        socket.on('connect', () => {
            this.logger.debug('Socket connected !');

            this.eventbus.socket$.next({
                type: EventType.socket_connected,
            });
        });

        socket.on('connect_error', () => {
            this.logger.warn('reconnect_failed !');
        });

        socket.on('connect_timeout', () => {});

        socket.on('disconnect', (reason) => {
            this.logger.error('Socket disconnect, reason: %s', reason);

            this.eventbus.socket$.next({
                type: EventType.socket_disconnected,
            });
        });

        socket.on('reconnect', (attemptNumber) => {
            this.logger.debug('"reconnect" event [attempts:"%s"]', attemptNumber);
        });

        socket.on('reconnect_failed', () => {
            this.logger.warn('reconnect_failed !');
        });
    }

    private setupRequestHandler() {
        const socket = this.socket;
        socket.on('request', (request, cb) => {
            this.logger.debug('request event, method: %s,data: %o', request.method, request.data);

            const regiMethod = pRequestMap.get(request.method);
            if (!regiMethod) {
                this.logger.warn('request method: %s, do not register!', request.method);
                return;
            }

            this[regiMethod](request.data, cb);
        });
    }

    private setupNotificationHandler() {
        const socket = this.socket;
        socket.on('notification', async (notification) => {
            // do not want log activeSpeaker, too noise
            if (notification.method !== 'activeSpeaker' && notification.method !== 'consumerScore') {
                this.logger.debug('notification event, method: %s,data: %o', notification.method, notification.data);
            }

            const regiMethod = pNotificationMap.get(notification.method);
            if (!regiMethod) {
                this.logger.debug('notification method: %s, do not register!', notification.method);
                return;
            }
            this[regiMethod](notification.data);
        });
    }

    @Notification()
    private syncDocInfo(data) {
        // const { peerId, info } = data;
        this.eventbus.document$.next({
            type: EventType.document_syncDocInfo,
            data,
        });
    }

    @Notification()
    private activeSpeaker(data: any) {}

    @Notification()
    private consumerScore(data: any) {
        const { consumerId, score } = data;
        this.eventbus.media$.next({
            type: EventType.media_consumerScore,
            data,
        });
    }

    @Notification()
    private iCanPlayVideo(data: any) {
        this.eventbus.syncStages$.next({
            type: EventType.video_iCanPlay,
            data,
        });
    }

    @Notification()
    private iCantPlayVideo(data: any) {
        this.eventbus.syncStages$.next({
            type: EventType.video_iCantPlay,
            data,
        });
    }

    @Notification()
    private videoPlayable(data: any) {
        this.eventbus.syncStages$.next({
            type: EventType.video_playable,
            data,
        });
    }

    @Notification()
    private videoNotPlayable(data: any) {
        this.eventbus.syncStages$.next({
            type: EventType.video_notPlayable,
            data,
        });
    }

    // data: {id, displayName, picture}
    @Notification()
    private newPeer(data: any) {
        this.eventbus.media$.next({
            type: EventType.media_newPeer,
            data,
        });
    }

    // data: { peerId: ... }
    @Notification()
    private peerClosed(data) {
        this.eventbus.media$.next({
            type: EventType.media_peerClose,
            data,
        });
    }

    @Notification()
    private consumerClosed(data) {
        this.eventbus.media$.next({
            type: EventType.media_consumerClosed,
            data,
        });
    }

    @Notification()
    private consumerPaused(data) {
        this.eventbus.media$.next({
            type: EventType.media_consumerPaused,
            data,
        });
    }

    @Notification()
    private consumerResumed(data) {
        this.eventbus.media$.next({
            type: EventType.media_consumerResumed,
            data,
        });
    }

    // data: { peerId, chatMessage }
    @Notification()
    private chatMessage(data: any) {
        this.eventbus.chat$.next({
            type: EventType.chat_message,
            data,
        });
    }

    // data: { peerId, prompterUpdate }
    @Notification()
    private prompterUpdate(data: any) {
        console.log(data);
        this.eventbus.show$.next({
            type: EventType.prompter_update,
            data,
        });
    }

    // data: {}
    @Notification()
    private askToParticipate(data: any) {
        this.eventbus.show$.next({
            type: EventType.ask_to_participate,
            data,
        });
    }

    // data: {approve:boolean}
    @Notification()
    private respondToParticipationRequest(data) {
        this.eventbus.show$.next({
            type: EventType.response_to_participation_request,
            data,
        });
    }

    @Notification()
    private async showStart(data) {
        this.eventbus.show$.next({
            type: EventType.show_start,
        });
    }

    @Notification()
    private showStop(data) {
        console.log('showwwwww stoppppppp');
        this.eventbus.show$.next({
            type: EventType.show_stop,
        });
    }

    @Notification()
    private notifyTake2(data) {
        console.log('theres gonna be take 2');
        this.eventbus.show$.next({
            type: EventType.show_take2,
        });
    }

    @Notification()
    private endScene(data) {
        console.log('End scene');
        this.eventbus.show$.next({
            type: EventType.end_scene,
        });
    }

    @Notification()
    private connectVideo(data) {
        this.eventbus.show$.next({
            type: EventType.show_connectVideo,
            data,
        });
    }

    @Notification()
    private connectApproval(data) {
        this.eventbus.show$.next({
            type: EventType.show_connectApproval,
            data,
        });
    }

    @Notification()
    private changeRole(data) {
        this.eventbus.peer$.next({
            type: EventType.peer_changeRole,
            data,
        });
    }

    @Notification()
    private disconnectVideo(data) {
        this.eventbus.show$.next({
            type: EventType.show_disconnectVideo,
            data,
        });
    }

    @Notification()
    private switchComponent(data) {
        this.eventbus.main$.next({
            type: EventType.main_switchComponent,
            data,
        });
    }

    @Notification()
    private muted(data) {
        this.eventbus.show$.next({
            type: EventType.show_muted,
            data,
        });
    }

    @Notification()
    private unmuted(data) {
        this.eventbus.show$.next({
            type: EventType.show_unmuted,
            data,
        });
    }

    @Notification()
    private invokePositionUpdate(data: PositionUpdate) {
        this.eventbus.show$.next({
            type: EventType.show_invokePositionUpdate,
            data,
        });
    }

    @Notification()
    private dynamicValuesChanged(data: DynamicValuesChangeMessage) {
        this.eventbus.show$.next({
            type: EventType.show_dynamicValuesChanged,
            data,
        });
    }

    @Notification()
    private invokePositionStageOptionUpdate(data: StageOptions) {
        this.eventbus.show$.next({
            type: EventType.show_invokePositionStageOptionUpdate,
            data,
        });
    }

    @Notification()
    private peerProducersClosed(data: any) {
        this.eventbus.media$.next({
            type: EventType.media_peerProducersClosed,
            data,
        });
    }

    @Notification()
    private bitStartedLoading(data: any) {
        this.eventbus.show$.next({
            type: EventType.bit_started_loading,
            data,
        });
    }

    @Notification()
    private bitFinishedLoading(data: any) {
        this.eventbus.show$.next({
            type: EventType.bit_finished_loading,
            data,
        });
    }

    @Request()
    private newConsumer(data: any, cb: any) {
        console.log('Im here in signalingService');
        this.eventbus.media$.next({
            type: EventType.media_newConsumer,
            data,
        });
        cb();
    }

    sendRequest(method, data = null) {
        return new Promise((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                reject('No socket connection.');
            } else {
                // console.log(
                //   'signal in socket emit: emiting the new data',
                //   method,
                //   data
                // );
                this.socket.emit(
                    'request',
                    { method, data },
                    this.timeoutCallback((err, response) => {
                        if (err) {
                            this.logger.error('sendRequest %s timeout! socket: %o', method, this.socket);
                            reject(err);
                        } else {
                            resolve(response);
                        }
                    })
                );
            }
        });
    }

    timeoutCallback(callback) {
        let called = false;

        const interval = setTimeout(() => {
            if (called) {
                return;
            }
            called = true;
            callback(new Error('Request timeout.'));
        }, this.config.mediasoup.requestTimeout);

        return (...args) => {
            if (called) {
                return;
            }
            called = true;
            clearTimeout(interval);

            callback(...args);
        };
    }

    async getRouterRtpCapabilities() {
        const callRes = await this.sendRequest(RequestMethod.getRouterRtpCapabilities);

        return callRes as mediaTypes.RtpCapabilities;
    }

    async createWebRtcTransport(params: any) {
        const callRes = await this.sendRequest(RequestMethod.createWebRtcTransport, params);

        return callRes as mediaTypes.TransportOptions;
    }

    async join(params: any) {
        const callRes = await this.sendRequest(RequestMethod.join, params);

        return callRes as { peers: any; joined: boolean };
    }

    async connectWebRtcTransport(params: any) {
        const callRes = await this.sendRequest(RequestMethod.connectWebRtcTransport, params);

        return callRes as any;
    }

    sendSyncDocInfo(info) {
        return this.sendRequest(RequestMethod.syncDocInfo, { info });
    }

    sendShowStart() {
        return this.sendRequest(RequestMethod.showStart, {
            roomId: this.profile.roomId,
        });
    }

    /**
     * Will ask to join
     * If a token exists, the host will check it and in case it is valid -
     * the join request will be automatically approved
     * @param participationToken
     */
    sendAskToParticipateRequest() {
        // console.log('sending request', participationToken)
        return this.sendRequest(RequestMethod.askToParticipate, {
            askedBy: this.profile.userPeer,
        });
    }

    sendRespondToParticipationRequest(data: { to: string; approved: boolean }) {
        return this.sendRequest(RequestMethod.respondToParticipationRequest, data);
    }

    notifyTake2Planned() {
        console.log('notifyTake2Planned');
        return this.sendRequest(RequestMethod.notifyTake2, {
            roomId: this.profile.roomId,
            host: {
                displayName: this.profile.userPeer.displayName,
                picture: this.profile.userPeer.picture,
                role: this.profile.userPeer.role,
            },
        });
    }

    sendCloseRoomAndStopShow() {
        console.log('sendShowStop');
        return this.sendRequest(RequestMethod.showStop, {
            roomId: this.profile.roomId,
            host: {
                displayName: this.profile.userPeer.displayName,
                picture: this.profile.userPeer.picture,
                role: this.profile.userPeer.role,
            },
        });
    }

    sendEndScene() {
        console.log('sendEndScene');
        return this.sendRequest(RequestMethod.endScene, {
            roomId: this.profile.roomId,
            host: {
                displayName: this.profile.userPeer.displayName,
                picture: this.profile.userPeer.picture,
                role: this.profile.userPeer.role,
            },
        });
    }

    sendChangeRole(role) {
        return this.sendRequest(RequestMethod.changeRole, {
            peerId: this.profile.userPeer.id,
            role,
        });
    }

    sendIceRestart(transportId: string) {
        return this.sendRequest(RequestMethod.restartIce, {
            transportId,
        });
    }

    getShowroomInfo() {
        return this.sendRequest(RequestMethod.roomInfo, {
            roomId: this.profile.roomId,
        });
    }

    sendConnectVideoRequest() {
        return this.sendRequest(RequestMethod.connectVideo, {
            roomId: this.profile.roomId,
        });
    }

    sendConnectApproval(toPeer, approval) {
        return this.sendRequest(RequestMethod.connectApproval, {
            toPeer,
            approval,
        });
    }

    sendDisconnectVideo(toPeer) {
        return this.sendRequest(RequestMethod.disconnectVideo, {
            toPeer,
        });
    }

    sendChatMessage(chatMessage: SflMessage) {
        return this.sendRequest(RequestMethod.chatMessage, chatMessage);
    }

    sendPrompterUpdate(updateMessage: { version: string; text: string }) {
        return this.sendRequest(RequestMethod.prompterUpdate, updateMessage);
    }

    // sendClosePeer(stopClass) {
    //   return this.sendRequest(
    //     RequestMethod.closePeer,
    //     {
    //       stopClass
    //     }
    //   );
    // }

    sendClosePeerProducers(peerId: string) {
        return this.sendRequest(RequestMethod.closePeerProducers, {
            peerId,
        });
    }

    sendSwitchComponent(component) {
        return this.sendRequest(RequestMethod.switchComponent, {
            component,
        });
    }

    sendMuted(to, kind) {
        return this.sendRequest(RequestMethod.muted, { to, kind });
    }

    sendUnmuted(to, kind) {
        return this.sendRequest(RequestMethod.unmuted, { to, kind });
    }

    sendICanPlayVideo(peerId: string, videoId: string) {
        console.log('signal in signal service: i can play video signal request');
        return this.sendRequest(RequestMethod.iCanPlayVideo, { peerId, videoId });
    }

    sendICantPlayVideo(peerId: string, videoId: string) {
        return this.sendRequest(RequestMethod.iCantPlayVideo, { peerId, videoId });
    }

    sendVideoPlayable(videoId: string) {
        return this.sendRequest(RequestMethod.videoPlayable, { videoId });
    }

    sendVideoNotPlayable(videoId: string) {
        return this.sendRequest(RequestMethod.videoNotPlayable, { videoId });
    }

    sendInvokePositionUpdate(position: PositionUpdate) {
        return this.sendRequest(RequestMethod.invokePositionUpdate, position);
    }

    sendInvokePositionStageOptionUpdate(newOptions: StageOptions) {
        return this.sendRequest(RequestMethod.invokePositionStageOptionUpdate, newOptions);
    }

    sendDynamicValuesChangeMessage(dynamicValuesChangeMessage: DynamicValuesChangeMessage) {
        console.log('sendDynamicValuesChangeMessage');
        return this.sendRequest(RequestMethod.dynamicValuesChanged, dynamicValuesChangeMessage);
    }

    async notifyFinishedLoadingBit(bitId: string, startTime: Date) {
        // console.log(bitId)
        this.bitFinishedLoading({ bitId, startTime });
    }

    async notifyStartedLoadingBit(bitId: string, endTime: Date) {
        // console.log(bitId)
        this.bitStartedLoading({ bitId, endTime });
    }
}
