import { Injectable } from '@angular/core';
import { AuthenticationService } from '../guards/authentication.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class NavigationHelperService {
    constructor(
        public router: Router,
        public authentication: AuthenticationService,
        private location: Location
    ) {}

    jumpToHost() {
        // Jump to sign-in screen if not logged in
        this.router.navigate(['/dashboard']);
    }

    goBack() {
        this.location.back();
    }
}
