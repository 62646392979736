import { Component } from '@angular/core';
import { PathHelperService } from 'src/app/services/utils/path-helper.service';
import { AuthenticationService } from '../../../services/guards/authentication.service';

@Component({
    selector: 'current-user-img',
    templateUrl: './current-user-img.component.html',
    styleUrls: ['./current-user-img.component.scss'],
})
export class CurrentUserImgComponent {
    constructor(
        public authentication: AuthenticationService,
        public pathHelperService: PathHelperService
    ) {}
}
