import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../configuration.service';
import { IBackgroundMusic } from '../../../models/defines';
import { Observable } from 'rxjs';
import { IEditJob } from 'src/app/models/job/edit-job-schema';
import {
    POST_PRODUCTION_GET_EDIT_JOB_URL,
    POST_PRODUCTION_POST_EDIT_JOB_URL,
} from 'src/app/constants/private/urls/post-production.urls';

export interface IEditJobAndStream {
    editJob: IEditJob;
}

@Injectable({
    providedIn: 'root',
})
export class PostProductionAuthApiService {
    path = '/auth/post-production';

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {
        this.path = this.config.apiIP + this.path;
    }

    transcribe(streamId: string) {
        const body = { streamId: streamId };
        return this.http.post<any>(`${this.path}/transcribe`, body).toPromise();
    }

    getAllBackgroundMusicTracks() {
        return this.http.get<IBackgroundMusic[]>(`${this.path}/music-library`).toPromise();
    }

    postEditJob(editJob: IEditJob, requestEdit: boolean = true) {
        const body = { editJob: editJob, requestEdit: requestEdit };
        return this.http.post<any>(POST_PRODUCTION_POST_EDIT_JOB_URL, body);
    }

    getEditJobAndStream(streamId: string, editJobId: string = null): Observable<IEditJobAndStream> {
        if (!streamId) {
            console.error(`No stream id or job id was provided to get edit job from server`);
        }
        let params = { streamId: streamId };
        if (editJobId) {
            params['editJobId'] = editJobId;
        }
        return this.http.get<IEditJobAndStream>(POST_PRODUCTION_GET_EDIT_JOB_URL, {
            params,
        });
    }
}
