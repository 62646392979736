<vg-player (onPlayerReady)="onPlayerReady($event)">
    <!-- <vg-buffering></vg-buffering> -->

    <!-- <vg-controls> -->
    <!-- Add other controls as needed -->
    <!-- </vg-controls> -->

    <!-- [id]="videoId ?? 'singleVideo'" -->
    <video
        #media
        *ngIf="sourceAndType"
        [vgMedia]="media"
        [vgDash]="sourceAndType.source"
        #vgDash="vgDash"
        [id]="videoId"
        class="video"
        preload="auto"
        crossorigin
        playsInline
        [controls]="displayControls">
        Your browser does not support the video tag.
    </video>
</vg-player>
