import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, AfterViewInit } from '@angular/core';
import { UserService } from '../services/api/auth/user.service';
import { FunctionsHelperService } from '../services/functions-helper.service';
import { IVideoAndMime } from '../models/project/take/layers/video-model';
import { MimeTypeEnum } from '../models/defines';
// [appVideoSource]="{
//   hlsUrl:
//     videoLayer.localUploadPath ??
//     this.baseCdnUrl + videoLayer.uploadPath,
//   dashUrl: videoLayer.dashPath ? this.baseCdnUrl + videoLayer.dashPath : null,
//   uploadUrl: this.baseCdnUrl + videoLayer.uploadPath,
// }"
interface IStreamUrls {
    localUrl: IVideoAndMime;
    uploadUrl: string;
}

@Directive({
    selector: '[appVideoSource]',
})
export class VideoSourceDirective implements OnChanges, AfterViewInit {
    @Input('appVideoSource') streamUrls!: IStreamUrls;

    private readonly isIOS: boolean = false;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private userService: UserService,
        private functionsHelper: FunctionsHelperService
    ) {
        this.isIOS = this.userService.isIOS;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['streamUrls'] && this.streamUrls) {
            this.setupStream();
        }
    }

    ngAfterViewInit(): void {
        // this.setupStream();
    }

    private setupStream(): void {
        const videoElement = this.el.nativeElement as HTMLVideoElement;

        const url = this.setDefaultSource(videoElement);

        videoElement.src = url;
        videoElement.load();
    }

    private clearAttributes(element: HTMLElement): void {
        ['src'].forEach((attr) => this.renderer.removeAttribute(element, attr));
    }

    private setDefaultSource(element: HTMLElement): string {
        let url: string;
        let type: MimeTypeEnum;
        // if (false) {
        if (this.streamUrls.localUrl && this.streamUrls.localUrl.url) {
            url = this.streamUrls.localUrl.url;
            type = this.streamUrls.localUrl.type;
        } else {
            url = this.streamUrls.uploadUrl;
            type = this.functionsHelper.getMimeTypeFromExtension(url);
        }
        // this.renderer.setAttribute(element, 'type', type);
        this.renderer.setAttribute(element, 'src', url);
        this.renderer.removeAttribute(element, 'vgDash');
        this.renderer.removeAttribute(element, 'vgHls');
        return url;
    }
}
